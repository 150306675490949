<div class="outline-drop-down-picker-container">
  <label class="hide-on-print">{{ title }}</label>
  <mat-form-field appearance="outline" class="outline-drop-down-picker">
    <mat-select [formControl]="selection" [multiple]="multiple" [compareWith]="compareFunc" [disabled]="disabled" (selectionChange)="onChanges()" [placeholder]="placeholderLabel">
      <mat-select-trigger *ngIf="multiple">{{ selection_label }}</mat-select-trigger>
      <mat-select-trigger *ngIf="!multiple">{{ selectedOptionName }}</mat-select-trigger>

      <mat-option *ngIf="addOption" (click)="$event.stopPropagation(); $event.stopImmediatePropagation(); $event.preventDefault(); onAddOption()" [value]="selection.value">
        {{ addOptionLabel }}
      </mat-option>

      <mat-option *ngIf="showInvalidOptionLabel" class="invalid-selection-option" [value]="selection.value"></mat-option>
      <mat-option *ngIf="allOption" #selectAllOption (click)="toggleAllOptions()" value="all">{{ all_option_label }}</mat-option>
      <mat-option *ngIf="noneOption" (click)="tossleOneOption()" value="none">{{ deselectOptionLabel }}</mat-option>

      <ng-container *ngIf="nestedOptionKeys?.length">
        <mat-optgroup *ngFor="let option of options | childrenLengthFilterPipe : [nestedOptionKeys, 1]" [label]="nestedOptionName(option)">
          <mat-option *ngFor="let nested_option of nestedOption(option)" (click)="tossleOneOption()" [value]="value_key ? nested_option[value_key] : nested_option" [disabled]="(multiple && option_disabled && !is_selected(option)) || is_disabled(nested_option)">
            <ng-container *ngTemplateOutlet="optionContainer; context: { option: nested_option }"></ng-container>
          </mat-option>
        </mat-optgroup>
      </ng-container>

      <ng-container *ngIf="!nestedOptionKeys?.length">
        <mat-option *ngFor="let option of options" (click)="tossleOneOption()" [value]="value_key ? option[value_key] : option" [disabled]="(multiple && option_disabled && !is_selected(option)) || is_disabled(option)">
          <ng-container *ngTemplateOutlet="optionContainer; context: { option: option }"></ng-container>
        </mat-option>
      </ng-container>
    </mat-select>

    <mat-error *ngIf="selection?.hasError('required')" i18n> Et valg mangler </mat-error>
    <mat-error *ngIf="selection?.hasError('invalid')" i18n> Ugyldigt valg </mat-error>
  </mat-form-field>
</div>

<ng-template #optionContainer let-option="option">
  <div class="option-container">
    <div>{{ option_name(option) }}</div>
    <div *ngIf="editOption || deleteOption" class="icons-container">
      <mat-icon *ngIf="editOption" (click)="$event.stopPropagation(); onEditOption(option)">edit</mat-icon>
      <mat-icon *ngIf="deleteOption" (click)="$event.stopPropagation(); onDeleteOption(option)">delete</mat-icon>
    </div>
  </div>
</ng-template>
