import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ESLGateway, INJECTION_DATA } from 'foodop-lib'
import { catchError } from 'rxjs'

@Component({
  selector: 'esl-gateway',
  templateUrl: './esl-gateway.component.html',
  host: {
    class: 'relative w-full h-full bg-white l-box-shadow rounded p-4 flex flex-col gap-4'
  }
})
export class ESLGatewayComponent {
  @Output() closeView = new EventEmitter()
  public ESLGateway: ESLGateway

  constructor(@Inject(INJECTION_DATA) public initData: any, private _snackBar: MatSnackBar) {
    this.ESLGateway = initData.ESLGateway
  }

  public onCloseView(): void {
    this.closeView.emit()
  }

  public onSaveESLGateway(): void {
    this.ESLGateway.save()
      .pipe(
        catchError(() => {
          this.ESLGateway.loading = false
          this._snackBar.open('An error occured while registering the ESL Gateway', 'Luk', { duration: 5000 })
          return []
        })
      )
      .subscribe()
  }
}
