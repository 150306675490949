import { Directive, ElementRef, AfterViewInit, Renderer2 } from '@angular/core'

/**
 * @directive StickyColumnsDirective
 * @description This directive is used to fix table columns to the left or right.
 * It calculates and applies appropriate `left` or `right` styles based on column widths.
 * It also considers `colspan` to correctly align columns.
 *
 * @usage
 * ```html
 * <section class="group">
 *   <table stickyColumns>
 *      <tr>
 *        <td stickToLeft>Fixed Left</td>
 *        <td stickToLeft class="table-cell-invisible no-scroll-padding" (click)="scrollPortions(-1)">
            <mat-icon class="absolute right-0 bottom-2 invisible group-[.scrolled-left]:visible group-[.scrolled-left]:cursor-pointer">keyboard_arrow_left</mat-icon>
          </td>
 *         <td>Normal Column</td>
 *        <td stickToRight class="table-cell-invisible no-scroll-padding" (click)="scrollPortions(1)">
            <mat-icon class="absolute left-0 bottom-2 invisible group-[.scrolled-right]:visible group-[.scrolled-right]:cursor-pointer">keyboard_arrow_right</mat-icon>
          </td>
 *        <td stickToRight>Fixed Right</td>
 *      </tr>
 *   </table>
 * </section>
 * ```
 *
 * @selector [stickyColumns]
 * @exportedAs StickyColumnsDirective
 */

@Directive({
  selector: '[stickyColumns]'
})
export class StickyColumnsDirective implements AfterViewInit {
  private resizeObserver!: ResizeObserver
  private mutationObserver!: MutationObserver

  constructor(private el: ElementRef, private _renderer: Renderer2) {}

  ngAfterViewInit() {
    this._setupObservers()
    setTimeout(() => {
      this._fixColumnPositions()
      this._handleScroll()
    }, 0)
  }

  ngOnDestroy() {
    if (this.resizeObserver) this.resizeObserver.disconnect()
    if (this.mutationObserver) this.mutationObserver.disconnect()
  }

  private _setupObservers() {
    const table = this.el.nativeElement as HTMLTableElement

    // 1. ResizeObserver - Detects column width changes
    this.resizeObserver = new ResizeObserver(() => {
      this._fixColumnPositions()
      this._handleScroll()
    })
    this.resizeObserver.observe(table)

    // 2. MutationObserver - Detects added/removed columns
    this.mutationObserver = new MutationObserver(() => {
      this._fixColumnPositions()
      this._handleScroll()
    })
    this.mutationObserver.observe(table, { childList: true, subtree: true })

    // 3. Window resize event
    window.addEventListener('resize', () => {
      this._fixColumnPositions()
      this._handleScroll()
    })
    this.el.nativeElement.parentElement.addEventListener('scroll', () => this._handleScroll())
  }

  private _fixColumnPositions() {
    const table: HTMLTableElement = this.el.nativeElement
    const leftFixedIndexes = new Set<number>() // Stores indexes of #stickToLeft columns
    const rightFixedIndexes = new Set<number>() // Stores indexes of #stickToRight columns
    const leftColumnWidths: number[] = []
    const rightColumnWidths: number[] = []

    // Step 1: Identify fixed columns & store their widths
    for (const row of Array.from(table.rows)) {
      let colIndex = 0 // Logical column tracking

      for (const cell of Array.from(row.cells)) {
        // Identify #stickToLeft columns
        if (cell.hasAttribute('stickToLeft')) {
          for (let i = 0; i < cell.colSpan; i++) {
            leftFixedIndexes.add(colIndex + i)
          }
        }

        // Identify #stickToRight columns
        if (cell.hasAttribute('stickToRight')) {
          for (let i = 0; i < cell.colSpan; i++) {
            rightFixedIndexes.add(colIndex + i)
          }
        }

        // Store width if it's a fixed column & hasn't been recorded yet
        if (cell.colSpan === 1) {
          if (leftFixedIndexes.has(colIndex) && leftColumnWidths[colIndex] === undefined) {
            leftColumnWidths[colIndex] = cell.getBoundingClientRect().width
          }
          if (rightFixedIndexes.has(colIndex) && rightColumnWidths[colIndex] === undefined) {
            rightColumnWidths[colIndex] = cell.getBoundingClientRect().width
          }
        }

        colIndex += cell.colSpan // Move forward considering colspan
      }
    }

    // Step 2: Compute cumulative offsets
    let leftOffset = 0
    for (const index of [...leftFixedIndexes].sort((a, b) => a - b)) {
      const width = leftColumnWidths[index] || 0
      this.setColumnStyle(table, index, 'left', leftOffset, 'fixed-left')
      leftOffset += width
    }

    let rightOffset = 0
    for (const index of [...rightFixedIndexes].sort((a, b) => b - a)) {
      const width = rightColumnWidths[index] || 0
      this.setColumnStyle(table, index, 'right', rightOffset, 'fixed-right')
      rightOffset += width
    }
  }

  private setColumnStyle(table: HTMLTableElement, colIndex: number, position: 'left' | 'right', value: number, className: string) {
    for (const row of Array.from(table.rows)) {
      const tableWidth =
        Array.from(row.cells)
          .map((cell) => cell.colSpan)
          .reduce((a, b) => a + b, 0) - 1
      let colCounter = position == 'left' ? 0 : tableWidth
      let cells = position == 'left' ? Array.from(row.cells) : Array.from(row.cells).reverse()
      for (const cell of cells) {
        if (colCounter === colIndex && (cell.hasAttribute('stickToLeft') || cell.hasAttribute('stickToRight'))) {
          this._renderer.setStyle(cell, position, `${value}px`)
          this._renderer.addClass(cell, 'sticky')
          this._renderer.addClass(cell, 'z-20')
        }
        colCounter += position == 'left' ? cell.colSpan : -cell.colSpan
      }
    }
  }

  private _handleScroll() {
    const containerWidth = this.el.nativeElement.parentElement.clientWidth
    const tableWidth = this.el.nativeElement.clientWidth
    const isScrolledTFromStart = this.el.nativeElement.parentElement.scrollLeft
    const innerScrollWidth = this.el.nativeElement.parentElement.scrollWidth - containerWidth
    const isScrolledToEnd = this.el.nativeElement.parentElement.scrollLeft >= innerScrollWidth - 10

    if (containerWidth < tableWidth) this._renderer.addClass(this.el.nativeElement.parentElement, 'scrolled-right')
    if (isScrolledTFromStart) {
      this._renderer.addClass(this.el.nativeElement.parentElement, 'scrolled-left')
    } else {
      this._renderer.removeClass(this.el.nativeElement.parentElement, 'scrolled-left')
    }

    if (isScrolledToEnd) {
      this._renderer.removeClass(this.el.nativeElement.parentElement, 'scrolled-right')
    } else {
      this._renderer.addClass(this.el.nativeElement.parentElement, 'scrolled-right')
    }
  }
}
