import { FormControl } from '@angular/forms'
import moment from 'moment'
import { Observable, of } from 'rxjs'
import { FoodopLibModule } from '../../foodop-lib.module'
import { BowlSetup } from '../bowls/bowl-setup/bowl-setup.model'
import { DisplayTemplate } from '../display-template/display-template.model'
import { ScaleDish } from '../scale-dish/scale-dish.model'
import { ScaleGroup } from '../scale-group/scale-group.model'
import { Scale } from '../scale/scale.model'
import { Menu } from '../menu/menu.model'
import { MenuDish } from '../menu-dish/menu-dish.model'
import { TrashTemplate } from '../trash-template/trash-template.model'
import { DisplayTemplatesService } from '../display-template/display-templates.service'
import { MenusService } from '../menu/menus.service'
import * as uuid from 'uuid'
import { startWith } from 'rxjs/operators'
import { IScaleDish, ITracking, ITrackingTemplate } from '../../global.models'
import { TrackingTemplatesService } from '../tracking-templates/tracking-templates.service'
import { TrackingTemplate } from '../tracking-templates/tracking-template.model'
import { TrackingsService } from './trackings.service'
import { SubsidiaryService } from '../subsidiary/subsidiary.service'
import { MenuSection } from '../menu-section/menu-section.model'
import { DisplayDish } from './display-dish.model'
import { ESLLabel } from '../esl-models/esl-labels/esl-label.model'

export class Tracking {
  public id: string

  public trackingGroupId: string
  public trackingTemplateId: string
  public locationId: string
  public menuId: string
  public displayTemplateId: FormControl

  public servingTime: FormControl
  public downloadTime: FormControl
  public language: FormControl

  public scaleDishes: ScaleDish[] = []
  public displayDishes: DisplayDish[] = []

  public saving: FormControl = new FormControl(false)
  public savedTracking: any
  public error: any

  private _trackingTemplatesService: TrackingTemplatesService
  private _trackingsService: TrackingsService
  private _menusService: MenusService
  private _displayTemplatesService: DisplayTemplatesService
  private _subsidiaryService: SubsidiaryService

  constructor(tracking?: ITracking) {
    this._trackingsService = FoodopLibModule.injector.get(TrackingsService)
    this._trackingTemplatesService = FoodopLibModule.injector.get(TrackingTemplatesService)
    this._menusService = FoodopLibModule.injector.get(MenusService)
    this._displayTemplatesService = FoodopLibModule.injector.get(DisplayTemplatesService)
    this._subsidiaryService = FoodopLibModule.injector.get(SubsidiaryService)

    this.id = tracking?.id
    this.trackingTemplateId = tracking?.tracking_template_id
    this.trackingGroupId = tracking?.tracking_group_id
    this.menuId = tracking?.menu_id

    this.servingTime = new FormControl(this.findValidTrackingTime(this.menu, tracking, this.trackingTemplate?.asDict))
    this.downloadTime = new FormControl(this.findValidDownloadTime(this.menu, tracking, this.trackingTemplate?.asDict))

    this.language = new FormControl({
      value: tracking?.language || this.trackingTemplate?.language?.value || 'da',
      disabled: this.isEnded
    })

    this.displayTemplateId = new FormControl({
      value: tracking?.display_template_id || this.trackingTemplate?.displayTemplateId?.value,
      disabled: this.isEnded
    })
    this.displayTemplate?.setDisplayFormatSize(this.trackingTemplate.type.value)

    this._setScaleDishes(tracking)
    this._setDisplayDishes(tracking)

    this.savedTracking = JSON.stringify(this.asDict)
  }

  public get trackingTemplate(): TrackingTemplate {
    return this._trackingTemplatesService.trackingTemplateWithId(this.trackingTemplateId)
  }
  public get menu(): Menu {
    return this._menusService.menuWithId(this.menuId)
  }
  public get displayTemplate(): DisplayTemplate {
    return this._displayTemplatesService.display_template_with_id(this.displayTemplateId.value)
  }
  public get isPlanned(): boolean {
    return this.id ? true : false
  }
  public get isEnded(): boolean {
    return this.downloadTime?.value?.isBefore(moment())
  }
  public get isActive(): boolean {
    return this.servingTime?.value?.isBefore(moment()) && this.downloadTime?.value.isAfter(moment())
  }
  public get isToday(): boolean {
    return this.servingTime?.value?.format('YYYY-MM-DD') == moment().format('YYYY-MM-DD')
  }
  public get isScheduleValid(): boolean {
    return this.servingTime.value.isBefore(this.downloadTime.value)
  }
  get overlapsOtherTracking(): boolean {
    return (
      this._trackingsService.trackings
        .filter(
          (tracking) =>
            tracking.id != this.id &&
            this.trackingTemplate.locationId.value == tracking.trackingTemplate.locationId.value &&
            ((this.trackingTemplate.type.value != 'esl_display' && tracking.scaleDishes.length) || (this.trackingTemplate.type.value == 'esl_display' && tracking.displayDishes.length))
        )
        .find((tracking) => tracking.servingTime.value.isBefore(this.downloadTime.value) && tracking.downloadTime.value.isAfter(this.servingTime.value)) != undefined
    )
  }
  public get areScaleDishesValid(): boolean {
    return !this.scaleDishes.find((scaleDish) => !scaleDish.valid)
  }
  public get areScaleDishBowlSetupsValid(): boolean {
    return !this.scaleDishes.find((scaleDish) => !scaleDish.is_bowl_selection_valid)
  }
  public get areDisplayDishesValid(): boolean {
    return !this.displayDishes.find((displayDish) => !displayDish.valid)
  }
  public get isValid(): boolean {
    return (
      !this.isEnded &&
      this.isScheduleValid &&
      ((this.trackingTemplate.type.value != 'esl_display' && this.scaleDishes.length > 0 && this.areScaleDishesValid) || (this.trackingTemplate.type.value == 'esl_display' && this.displayDishes.length > 0 && this.areDisplayDishesValid)) &&
      !this.overlapsOtherTracking
    )
  }

  public get invalidError(): 'ended' | 'invalid_schedule' | 'overlaps' | 'no_scale_dishes' | 'invalid_scale_dishes' | 'invalid_bowl_setups' | 'no_display_dishes' | 'invalid_display_dishes' {
    if (this.isEnded) return 'ended'
    else if (!this.isScheduleValid) return 'invalid_schedule'
    else if (this.overlapsOtherTracking) return 'overlaps'
    else if (this.trackingTemplate.type.value != 'esl_display' && !this.scaleDishes.length) return 'no_scale_dishes'
    else if (this.trackingTemplate.type.value == 'esl_display' && !this.displayDishes.length) return 'no_display_dishes'
    else if (this.trackingTemplate.type.value != 'esl_display' && !this.areScaleDishBowlSetupsValid) return 'invalid_bowl_setups'
    else if (this.trackingTemplate.type.value != 'esl_display' && !this.areScaleDishesValid) return 'invalid_scale_dishes'
    else if (this.trackingTemplate.type.value == 'esl_display' && !this.areDisplayDishesValid) return 'invalid_display_dishes'
    else return
  }

  public get isChanged(): boolean {
    return JSON.stringify(this.asDict) != this.savedTracking
  }
  public get scaleDishesSorted(): ScaleDish[] {
    return this.scaleDishes.sort((a, b) => (a.scale_index > b.scale_index ? 1 : -1))
  }

  public get unallocatedESLDisplays(): ESLLabel[] {
    return this.trackingTemplate.location.ESLLabels.filter((ESLLabel) => !this.displayDishes.find((displayDish) => displayDish.ESLLabel.labelCode.value == ESLLabel.labelCode.value))
  }

  public get displayDishesSorted(): DisplayDish[] {
    return this.displayDishes.sort((a, b) => (a.ESLLabel.index.value > b.ESLLabel.index.value ? 1 : -1))
  }
  public get descriptionLabel(): string {
    if (this.isEnded) return ''
    else if (this.isActive) return $localize`Serveringen afsluttes kl. ` + this.downloadTime.value.format('HH:mm')
    else if (this.isToday) return $localize`Serveringen starter kl. ` + this.servingTime.value.format('HH:mm')
    else if (moment(this.servingTime.value).endOf('week') == moment().endOf('week')) $localize`Serveringen starter kl. ` + this.servingTime.value.format('HH:mm') + $localize` på ` + this.servingTime.value.format('dddd')
    else return $localize`Serveringen starter ` + this.servingTime.value.format('dddd, MMM Do HH:mm')
  }
  public get asDict(): ITracking {
    return {
      id: this.id,
      tracking_template_id: this.trackingTemplateId,
      tracking_group_id: this.trackingGroupId,
      menu_id: this.menuId,
      location_id: this.trackingTemplate?.locationId?.value,
      serving_time: moment.utc(this.servingTime.value).format('YYYY-MM-DD HH:mm:ss'),
      download_time: moment.utc(this.downloadTime.value).format('YYYY-MM-DD HH:mm:ss'),
      display_template_id: this.displayTemplateId.value,
      scale_dishes: this.trackingTemplate.type.value != 'esl_display' ? this.scaleDishesSorted?.map((scaleDish) => scaleDish.asDict) : undefined,
      display_dishes: this.trackingTemplate.type.value == 'esl_display' ? this.displayDishesSorted?.map((displayDish) => displayDish.asDict) : undefined,
      language: this.language.value
    }
  }

  public scaleDishesForMenuDishesInScaleGroup(dishes: MenuDish[], scaleGroup: ScaleGroup): ScaleDish[] {
    return this.scaleDishes.filter((scaleDish) => dishes.map((dish) => dish.id).includes(scaleDish.menu_dish_id) && scaleGroup.scales.map((scale) => scale.macc).includes(scaleDish.scale_macc.value)).sort((a, b) => (a.scale_index > b.scale_index ? 1 : -1))
  }

  public scaleDishesForMenuDishesInScaleGroups(dishes: MenuDish[], scaleGroups: ScaleGroup[]): ScaleDish[] {
    return this.scaleDishes
      .filter(
        (scaleDish) =>
          dishes.map((dish) => dish.id).includes(scaleDish.menu_dish_id) &&
          [].concat
            .apply(scaleGroups.map((scaleGroup) => scaleGroup.scales.map((scale) => scale.macc)))
            .flat()
            .includes(scaleDish.scale_macc.value)
      )
      .sort((a, b) => (a.scale_index > b.scale_index ? 1 : -1))
  }

  public scaleDishesForMenuDishInScaleGroups(dish: MenuDish, scaleGroups: ScaleGroup[]): ScaleDish[] {
    return this.scaleDishes
      .filter(
        (scaleDish) =>
          scaleDish.menu_dish_id == dish.id &&
          [].concat
            .apply(scaleGroups.map((scaleGroup) => scaleGroup.scales.map((scale) => scale.macc)))
            .flat()
            .includes(scaleDish.scale_macc.value)
      )
      .sort((a, b) => (a.scale_index > b.scale_index ? 1 : -1))
  }
  public scaleDishesForMenuDishInScaleGroup(menuDish: MenuDish, scaleGroup: ScaleGroup): ScaleDish[] {
    return this.scaleDishes.filter((scaleDish) => scaleDish.menu_dish_id == menuDish.id && scaleGroup.scales.map((scale) => scale.macc).includes(scaleDish.scale_macc.value)).sort((a, b) => (a.scale_index > b.scale_index ? 1 : -1))
  }
  public scaleDishesForMenuDish(menuDish: MenuDish): ScaleDish[] {
    return this.scaleDishes.filter((scaleDish) => scaleDish.menu_dish_id == menuDish.id).sort((a, b) => (a.scale_index > b.scale_index ? 1 : -1))
  }
  public selectedScaleDishesValid(scaleDishes: ScaleDish[]): boolean {
    return scaleDishes.find((scaleDish) => scaleDish.bowlSetups.length == 0 || scaleDish.bowlSetups.find((bowl_setup) => bowl_setup.default.value) == undefined) ? false : true
  }

  private _scalesForMenuSectionAllocationRules(menuSection: MenuSection): Scale[] {
    return this.trackingTemplate
      .trackingSectionTemplateForMenuSectionTemplate(menuSection.section_template)
      .allocationRules.map((allocationRule) => allocationRule.scales || [])
      .flat()
  }

  public unallocatedScalesForMenuSectionAllocationRules(menuSection: MenuSection): Scale[] {
    return this._scalesForMenuSectionAllocationRules(menuSection).filter((scale) => !menuSection.menu_dishes.find((menuDish) => menuDish.id == scale.scale_dish_for_serving(this)?.menu_dish_id))
  }

  public allocatedScalesForMenuSectionAllocationRules(menuSection: MenuSection): Scale[] {
    return this._scalesForMenuSectionAllocationRules(menuSection).filter((scale) => menuSection.menu_dishes.find((menuDish) => menuDish.id == scale.scale_dish_for_serving(this)?.menu_dish_id))
  }

  public unallocatedScalesForMenuSection(menuSection: MenuSection): ScaleDish[] {
    return this.scaleDishes.filter((scaleDish) => !menuSection.menu_dishes.find((menuDish) => menuDish.id == scaleDish.menu_dish_id))
  }

  public allocatedScalesForMenuSection(menuSection: MenuSection): ScaleDish[] {
    return this.scaleDishes.filter((scaleDish) => menuSection.menu_dishes.find((menuDish) => menuDish.id == scaleDish.menu_dish_id))
  }

  public displayDishesForMenuSection(menuSection: MenuSection): DisplayDish[] {
    return this.displayDishes.filter((displayDish) => menuSection.menu_dishes.find((menuDish) => menuDish.id == displayDish.menuDish.id))
  }

  public patchValue(tracking: ITracking): void {
    if (tracking.id) this.id = tracking.id
    if (tracking.tracking_template_id) this.trackingTemplateId = tracking.tracking_template_id
    if (tracking.tracking_group_id) this.trackingGroupId = tracking.tracking_group_id
    if (tracking.menu_id) this.menuId = tracking.menu_id
    if (tracking.serving_time) this.servingTime.patchValue(moment(moment.utc(tracking?.serving_time).format()))
    if (tracking.download_time) this.downloadTime.patchValue(moment(moment.utc(tracking?.download_time).format()))
    if (tracking.language) this.language.patchValue(tracking.language)
    if (tracking.display_template_id) this.displayTemplateId.patchValue(tracking.display_template_id)
    if (tracking.scale_dishes) this._setScaleDishes(tracking)
    if (tracking.display_dishes) this._setDisplayDishes(tracking)

    this.savedTracking = JSON.stringify(this.asDict)
  }

  public findValidTrackingTime(menu: Menu, tracking: ITracking, trackingTemplate?: ITrackingTemplate): moment.Moment {
    if (tracking?.serving_time) return moment(moment.utc(tracking?.serving_time).format())
    else if (trackingTemplate?.serving_time) {
      const template_start: moment.Moment = moment(moment(menu?.date?.value + ' ' + trackingTemplate?.serving_time, 'YYYY-MM-DD HH:mm:ss').format())
      const future_tracking: boolean = template_start.isAfter(moment())
      const remainder = 10 - (moment().minute() % 10)
      const next_valid_start_time = moment().add(remainder, 'minutes')
      if (future_tracking) return template_start
      else return next_valid_start_time
    } else {
      const remainder = 10 - (moment().minute() % 10)
      const next_valid_start_time = moment().add(remainder, 'minutes')
      return next_valid_start_time
    }
  }

  public findValidDownloadTime(menu: Menu, tracking: ITracking, trackingTemplate?: ITrackingTemplate): moment.Moment {
    if (tracking?.download_time) return moment(moment.utc(tracking?.download_time).format())
    else if (trackingTemplate?.download_time) {
      const template_end: moment.Moment = moment(moment(menu?.date?.value + ' ' + trackingTemplate?.download_time, 'YYYY-MM-DD HH:mm:ss').format())
      const after_servingTime: boolean = template_end.isAfter(this.servingTime.value)
      const next_end_time = moment(this.servingTime.value).add(3, 'hours')
      const is_before_midnight: boolean = next_end_time.isBefore(moment(this.servingTime.value).endOf('day'))
      if (after_servingTime) return template_end
      else if (is_before_midnight) return next_end_time
      else return moment(this.servingTime.value).endOf('day')
    } else {
      const next_end_time = moment(this.servingTime.value).add(3, 'hours')
      const is_before_midnight: boolean = next_end_time.isBefore(moment(this.servingTime.value).endOf('day'))
      if (is_before_midnight) return next_end_time
      else return moment(this.servingTime.value).endOf('day')
    }
  }

  public addScaleDish(scaleDish?: IScaleDish, scale?: Scale, menu_dish?: MenuDish, trash_template?: TrashTemplate, bowl_setups?: BowlSetup[], scaleDish_type?: string) {
    const type = scaleDish?.type || scaleDish_type || 'dish'
    const new_scaleDish: ScaleDish = new ScaleDish(this, scaleDish, type, scale, menu_dish, trash_template, bowl_setups)
    this.scaleDishes.push(new_scaleDish)
  }
  public removeScaleDish(scaleDish: ScaleDish) {
    this.scaleDishes.splice(
      this.scaleDishes.findIndex((existing_scaleDish) => existing_scaleDish.scale_macc.value == scaleDish.scale_macc.value),
      1
    )
  }

  public replaceScaleDish(old_scaleDish: ScaleDish, scale?: Scale, menu_dish?: MenuDish, trash_template?: TrashTemplate, bowl_setups?: BowlSetup[]) {
    this.removeScaleDish(old_scaleDish)
    this.addScaleDish(null, scale, menu_dish, trash_template, bowl_setups, null)
  }
  public displayDishesForMenuDish(menuDish: MenuDish): DisplayDish[] {
    return this.displayDishes.filter((displayDish) => displayDish.menuDish.id == menuDish.id).sort((a, b) => (a.ESLLabel.index && b.ESLLabel.index && a.ESLLabel.index > b.ESLLabel.index ? 1 : a.ESLLabel.labelCode.value > b.ESLLabel.labelCode.value ? 1 : -1))
  }
  public displayDishForESLLabel(ESLLabel: ESLLabel): DisplayDish {
    return this.displayDishes.find((displayDish) => displayDish.ESLLabel.labelCode.value == ESLLabel.labelCode.value)
  }
  public addDisplayDish(displayDish?: DisplayDish): void {
    if (this.displayDishForESLLabel(displayDish.ESLLabel)) this.removeDisplayDishForESLLabel(displayDish.ESLLabel)
    this.displayDishes.push(displayDish)
  }
  public removeDisplayDishForESLLabel(ESLLabel: ESLLabel): void {
    this.displayDishes.splice(
      this.displayDishes.findIndex((displayDish) => displayDish.ESLLabel.labelCode.value == ESLLabel.labelCode.value),
      1
    )
  }

  public setDefaultTimeBasedOnPlannedTrackings(planned_trackings: Tracking[]) {
    const newTrackingTime = this._findAvailableTrackingTime(planned_trackings)
    this.servingTime.setValue(newTrackingTime.servingTime)
    this.downloadTime.setValue(newTrackingTime.downloadTime)
  }

  public listenForDisplayTemplateChanges(): void {
    this.displayTemplateId.valueChanges.pipe(startWith('')).subscribe(() => {
      this.displayTemplate?.setDisplayFormatSize(this.trackingTemplate.type.value)
      this.updateDisplays().subscribe()
    })
  }

  public updateDisplays(): Observable<any> {
    return this.trackingTemplate.type.value == 'esl_display' ? this._updateESLLabels() : this._updateScaleDishDisplays()
  }

  private _updateScaleDishDisplays(): Observable<any> {
    return new Observable((subscriber) => {
      const interval = setInterval(() => {
        if (this.displayTemplate) {
          clearInterval(interval)
          this.displayTemplate.reset_display_element_templates_shown_font_size()
          this.scaleDishes.forEach((scaleDish) => {
            scaleDish.display.reDraw(
              this.menu?.dish_with_id(scaleDish.menu_dish_id)?.recipe,
              null,
              this.trackingTemplate.location?.scales?.find((scale) => scale.macc == scaleDish.scale_macc.value)
            )
            scaleDish.id = uuid.v1()
          })
          subscriber.complete()
        }
      }, 500)
    })
  }

  private _updateESLLabels(): Observable<any> {
    return new Observable((subscriber) => {
      const interval = setInterval(() => {
        if (this.displayTemplate && !this.displayDishes.find((displayDish) => !displayDish.ESLLabel.DOMSizeFactor)) {
          clearInterval(interval)
          this.displayTemplate.reset_display_element_templates_shown_font_size()
          this.displayDishes.forEach((displayDish) => {
            displayDish.display.reDraw(displayDish.menuDish.recipe, null, null)
          })
          this.unallocatedESLDisplays.forEach((ESLLabel) => {
            ESLLabel.defaultDisplay.reDraw(null, null, null, ESLLabel)
          })
          subscriber.complete()
        }
      }, 500)
    })
  }

  public save(): Observable<Tracking> {
    this.saving.setValue(true)
    const params = {
      fields: 'scaleDishes,bowl_setups'
    }
    if (this.trackingTemplate.type.value == 'esl_display') {
      if (!this.isPlanned) {
        return this._trackingsService.createESLTracking(this)
      } else {
        return this._trackingsService.updateESLTracking(this)
      }
    } else {
      if (!this.isPlanned) {
        return this._trackingsService.createTracking(this, params)
      } else {
        return this._trackingsService.updateTracking(this, params)
      }
    }
  }

  public delete(): Observable<any> {
    if (this.trackingTemplate.type.value == 'esl_display') return this._trackingsService.deleteESLTracking(this)
    else {
      const params = {
        subsidiary_id: this._subsidiaryService.subsidiary.id
      }
      return this._trackingsService.deleteTracking(this, params)
    }
  }

  private _setScaleDishes(tracking: ITracking): void {
    this.scaleDishes = []
    ;(tracking?.scale_dishes || []).forEach((scaleDish) => {
      const scaleForScaleDish: Scale = null
      const menuDishForScaleDish: MenuDish = this.menu?.dish_with_id(scaleDish.menu_dish_id)
      const trashTemplateForScaleDish: TrashTemplate = null
      const bowlSetups: BowlSetup[] = scaleDish.bowl_setups.map((bowl_setup) => new BowlSetup(bowl_setup))
      return this.addScaleDish(scaleDish, scaleForScaleDish, menuDishForScaleDish, trashTemplateForScaleDish, bowlSetups, null)
    })
  }

  private _setDisplayDishes(tracking: ITracking): void {
    this.displayDishes = []
    ;(tracking?.display_dishes || []).forEach((displayDish) => {
      const eslLabelForDisplayDish: ESLLabel = this.trackingTemplate.location.ESLLabels.find((ESLLabel) => ESLLabel.labelCode.value == displayDish.esl_label_code)
      const menuDishForDisplayDish: MenuDish = this.menu?.dish_with_id(displayDish.menu_dish_id)
      return this.addDisplayDish(new DisplayDish(displayDish, eslLabelForDisplayDish, this, menuDishForDisplayDish))
    })
  }

  private _findAvailableTrackingTime(planned_trackings: Tracking[]): any {
    let trackingTemplate_servingTime,
      new_servingTime: moment.Moment = this.trackingTemplate.servingTime.value
    let trackingTemplate_downloadTime,
      new_downloadTime: moment.Moment = this.trackingTemplate.downloadTime.value
    let conflicting_tracking = planned_trackings.find(
      (planned_tracking) =>
        trackingTemplate_servingTime.isBetween(moment(planned_tracking.servingTime.value).subtract(30, 'minutes'), moment(planned_tracking.downloadTime.value).add(30, 'minutes')) ||
        trackingTemplate_downloadTime.isBetween(moment(planned_tracking.servingTime.value).subtract(30, 'minutes'), moment(planned_tracking.downloadTime.value).add(30, 'minutes'))
    )
    let hasReachedEndOfDay = trackingTemplate_downloadTime.isBefore(moment().endOf('day'))
    let hasReachedBeginningOfDay = trackingTemplate_servingTime.isAfter(moment().startOf('day'))
    let direction = 'forward'
    let tracking_length = 3

    while (conflicting_tracking) {
      if (direction == 'forward') {
        new_servingTime = conflicting_tracking.downloadTime.value.add(30, 'minutes')
        new_downloadTime = new_servingTime.add(tracking_length, 'hours')
        hasReachedEndOfDay = new_downloadTime.isAfter(moment().endOf('day'))
        if (hasReachedEndOfDay) direction = 'backwards'
        else {
          conflicting_tracking = planned_trackings.find(
            (planned_tracking) =>
              new_servingTime.isBetween(moment(planned_tracking.servingTime.value).subtract(30, 'minutes'), moment(planned_tracking.downloadTime.value).add(30, 'minutes')) ||
              new_downloadTime.isBetween(moment(planned_tracking.servingTime.value).subtract(30, 'minutes'), moment(planned_tracking.downloadTime.value).add(30, 'minutes'))
          )
        }
      }
      if (direction == 'backwards') {
        new_servingTime = conflicting_tracking.servingTime.value.subtract(tracking_length * 60 + 30, 'minutes')
        new_downloadTime = new_servingTime.add(tracking_length, 'hours')
        hasReachedBeginningOfDay = new_servingTime.isBefore(moment().startOf('day'))
        if (hasReachedBeginningOfDay && hasReachedEndOfDay && tracking_length > 1) {
          tracking_length -= 1
          direction = 'forward'
          conflicting_tracking = planned_trackings.find(
            (planned_tracking) =>
              trackingTemplate_servingTime.isBetween(moment(planned_tracking.servingTime.value).subtract(30, 'minutes'), moment(planned_tracking.downloadTime.value).add(30, 'minutes')) ||
              trackingTemplate_downloadTime.isBetween(moment(planned_tracking.servingTime.value).subtract(30, 'minutes'), moment(planned_tracking.downloadTime.value).add(30, 'minutes'))
          )
          hasReachedEndOfDay = trackingTemplate_downloadTime.isBefore(moment().endOf('day'))
          hasReachedBeginningOfDay = trackingTemplate_servingTime.isAfter(moment().startOf('day'))
        } else if (hasReachedBeginningOfDay && hasReachedEndOfDay && tracking_length > 0) {
          tracking_length -= 0.1
          direction = 'forward'
          conflicting_tracking = planned_trackings.find(
            (planned_tracking) =>
              trackingTemplate_servingTime.isBetween(moment(planned_tracking.servingTime.value).subtract(30, 'minutes'), moment(planned_tracking.downloadTime.value).add(30, 'minutes')) ||
              trackingTemplate_downloadTime.isBetween(moment(planned_tracking.servingTime.value).subtract(30, 'minutes'), moment(planned_tracking.downloadTime.value).add(30, 'minutes'))
          )
          hasReachedEndOfDay = trackingTemplate_downloadTime.isBefore(moment().endOf('day'))
          hasReachedBeginningOfDay = trackingTemplate_servingTime.isAfter(moment().startOf('day'))
        } else {
          conflicting_tracking = planned_trackings.find(
            (planned_tracking) =>
              new_servingTime.isBetween(moment(planned_tracking.servingTime.value).subtract(30, 'minutes'), moment(planned_tracking.downloadTime.value).add(30, 'minutes')) ||
              new_downloadTime.isBetween(moment(planned_tracking.servingTime.value).subtract(30, 'minutes'), moment(planned_tracking.downloadTime.value).add(30, 'minutes'))
          )
        }
      }
    }

    return {
      servingTime: new_servingTime,
      downloadTime: new_downloadTime
    }
  }
}
