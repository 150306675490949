import { FormControl } from '@angular/forms'
import { FoodopLibModule } from '../../../foodop-lib.module'
import { IESLGateway } from '../../../global.models'
import { ESLService } from '../esl.service'
import { Observable, tap } from 'rxjs'

export class ESLGateway {
  public code: FormControl
  public locationId: FormControl
  public loading: boolean = false
  public isRegistered: boolean = false

  private _savedESLGateway: string

  private _ESLService: ESLService

  constructor(ESLGateway: IESLGateway) {
    this._ESLService = FoodopLibModule.injector.get(ESLService)

    this.code = new FormControl(ESLGateway?.code)
    this.locationId = new FormControl(ESLGateway?.location_id)
    if (this.code.value) this.isRegistered = true

    this._savedESLGateway = JSON.stringify(this.asDict)
  }

  public get changed(): boolean {
    return this._savedESLGateway !== JSON.stringify(this.asDict)
  }

  public get asDict(): IESLGateway {
    return {
      code: this.code.value,
      location_id: this.locationId.value
    }
  }

  public save(): Observable<any> {
    this.loading = true
    return this._ESLService.createGateway(this).pipe(
      tap(() => {
        this._savedESLGateway = JSON.stringify(this.asDict)
        this.loading = false
        this.isRegistered = true
      })
    )
  }
}
