export const apiEndpoints: { [key: string]: { scopes: string[]; cachable: boolean; url: string; cookieAuthenticated?: boolean } } =
  //Object.assign({}, translation_api_URLs, menu_api_URLs, profile_api_URLs, tracking_api_URLs, data_insights_api_URLs, legacy_api_URLs)

  /* export const translation_api_URLs = */ {
    translationSuggestionsUrlV3: { scopes: ['read', 'write'], cachable: true, url: '/v3/translation_api/translation_suggestions' },
    translations: { scopes: ['read', 'write'], cachable: true, url: '/v3/translation_api/translations' },
    RecipeNamesV3: { scopes: ['read', 'write'], cachable: true, url: '/v3/translation_api/recipe_names' },
    RecipeDescriptionsV3: { scopes: ['read', 'write'], cachable: true, url: '/v3/translation_api/recipe_descriptions' },
    rawIngredientNamesV3: { scopes: ['read', 'write'], cachable: true, url: '/v3/translation_api/raw_ingredient_names' },
    sectionTemplatePlaceholderNamesV3: { scopes: ['read', 'write'], cachable: true, url: '/v3/translation_api/section_template_placeholder_names' },
    catalogueNamesV3: { scopes: ['read', 'write'], cachable: true, url: '/v3/translation_api/catalogue_names' },
    trashTemplateNamesV3: { scopes: ['read', 'write'], cachable: true, url: '/v3/translation_api/trash_template_names' },
    trashTemplateDescriptionsV3: { scopes: ['read', 'write'], cachable: true, url: '/v3/translation_api/trash_template_descriptions' },
    tag_names: { scopes: ['read', 'write'], cachable: true, url: '/v3/translation_api/tag_names' },
    // }
    // export const menu_api_URLs = {
    menusUrlV3: { scopes: ['read', 'write'], cachable: true, url: '/v3/menu_api/menus' },
    menu_sections: { scopes: ['read', 'write'], cachable: true, url: '/v3/menu_api/menu_sections' },
    menuDishesUrlV3: { scopes: ['read', 'write'], cachable: true, url: '/v3/menu_api/menu_dishes' },
    menu_templates: { scopes: ['read', 'write'], cachable: true, url: '/v3/menu_api/menu_templates' },
    menu_section_templates: { scopes: ['read', 'write'], cachable: true, url: '/v3/menu_api/menu_section_templates' },
    // }
    // export const dish_api_URLs = {
    allergensUrlV3: { scopes: ['read', 'write'], cachable: true, url: '/v3/dish_api/allergens' },
    dishCatalogueAllocationUrlV3: { scopes: ['read', 'write'], cachable: true, url: '/v3/dish_api/dish_catalogue_allocation' },
    openRecipes: { scopes: ['write'], cachable: true, url: '/v3/dish_api/open_recipes' },
    recipes: { scopes: ['read', 'write'], cachable: true, url: '/v3/dish_api/recipes' },
    tags: { scopes: ['read', 'write'], cachable: true, url: '/v3/dish_api/tags' },
    foods: { scopes: ['read', 'write'], cachable: true, url: '/v3/dish_api/foods' },
    userDishFavoritesUrl: { scopes: ['read', 'write'], cachable: true, url: '/v3/dish_api/user_dish_favorites' },
    ai_recipe_foods: { scopes: ['read', 'write'], cachable: true, url: '/v3/dish_api/ai_recipe_foods' },
    fb_categories: { scopes: ['read', 'write'], cachable: true, url: '/v3/dish_api/fb_categories' },
    // }
    // export const profile_api_URLs = {
    menuPrintTemplateUrl: { scopes: ['read', 'write'], cachable: true, url: '/v3/profile_api/menu_print_templates' },
    standardCataloguesUrlV3: { scopes: ['read', 'write'], cachable: true, url: '/v3/profile_api/standard_catalogues' },
    customCataloguesUrlV3: { scopes: ['read', 'write'], cachable: true, url: '/v3/profile_api/custom_catalogues' },
    custom_catalogue_visibility: { scopes: ['read', 'write'], cachable: true, url: '/v3/profile_api/custom_catalogue_visibility' },
    standard_catalogue_visibility: { scopes: ['read', 'write'], cachable: true, url: '/v3/profile_api/standard_catalogue_visibility' },
    usersUrlV3: { scopes: ['read', 'write'], cachable: true, url: '/v3/profile_api/users' },
    subsidiariesUrlV3: { scopes: ['read', 'write'], cachable: true, url: '/v3/profile_api/subsidiaries' },
    locationsUrlV3: { scopes: ['read', 'write'], cachable: true, url: '/v3/profile_api/locations' },
    displayTemplatesUrlV3: { scopes: ['read', 'write'], cachable: true, url: '/v3/profile_api/display_templates' },
    trash_templates: { scopes: ['read', 'write'], cachable: true, url: '/v3/profile_api/trash_templates' },
    bowl_setups: { scopes: ['read', 'write'], cachable: true, url: '/v3/profile_api/bowl_setups' },
    bowls: { scopes: ['read', 'write'], cachable: true, url: '/v3/profile_api/bowls' },
    organizationsUrlV3: { scopes: ['read', 'write'], cachable: true, url: '/v3/profile_api/organizations' },
    blob_files: { scopes: ['read', 'write'], cachable: true, url: '/v3/profile_api/blob_files' },
    // }
    // export const tracking_api_URLs = {
    tracking_groups: { scopes: ['read', 'write'], cachable: true, url: '/v3/tracking_api/tracking_groups' },
    trackings: { scopes: ['read', 'write'], cachable: true, url: '/v3/tracking_api/trackings' },
    esl_trackings: { scopes: ['read', 'write'], cachable: true, url: '/v3/tracking_api/esl_trackings' },
    tracking_templates: { scopes: ['read', 'write'], cachable: true, url: '/v3/tracking_api/tracking_templates' },
    tracking_group_templates: { scopes: ['read', 'write'], cachable: true, url: '/v3/tracking_api/tracking_group_templates' },
    // }
    // export const procurement_api_URLs = {
    ingredient_list: { scopes: ['read', 'write'], cachable: true, url: '/v3/procurement_api/ingredient_list' },
    providers: { scopes: ['read', 'write'], cachable: true, url: '/v3/procurement_api/providers' },
    procurement_data: { scopes: ['read', 'write'], cachable: true, url: '/v3/procurement_api/procurement_data' },
    ai_column_matching: { scopes: ['read', 'write'], cachable: true, url: '/v3/procurement_api/ai_column_matching' },
    products: { scopes: ['read', 'write'], cachable: true, url: '/v3/procurement_api/products' },
    populate_recipe_products: { scopes: ['read', 'write'], cachable: true, url: '/v3/dish_api/dagrofa/populate_recipe_products' },
    dagrofa_product_search: { scopes: ['read', 'write'], cachable: true, url: '/v3/dish_api/dagrofa/products/search' },
    dagrofa_products: { scopes: ['read', 'write'], cachable: true, url: '/v3/dish_api/dagrofa/products' },
    verify_product_availability: { scopes: ['read', 'write'], cachable: true, url: '/v3/dish_api/dagrofa/verify_product_availability' },
    // }
    // export const shopping_api_URLS = {
    shopping_lists: { scopes: ['read', 'write'], cachable: true, url: '/v3/shopping_api/shopping_lists' },
    shopping_list_items: { scopes: ['read', 'write'], cachable: true, url: '/v3/shopping_api/shopping_list_items' },
    // }
    // export const data_insights_api_URLs = {
    selected_trash_bowls: { scopes: ['read', 'write'], cachable: true, url: '/v3/data_insights_api/selected_trash_bowls' },
    selected_bowl_setups: { scopes: ['read', 'write'], cachable: true, url: '/v3/data_insights_api/selected_bowl_setups' },
    graph_data: { scopes: ['read', 'write'], cachable: true, url: '/v3/reporting_api/graph_data' },
    insights: { scopes: ['read', 'write'], cachable: true, url: '/v3/reporting_api/insights' },
    procurement_insights: { scopes: ['read', 'write'], cachable: true, url: '/v3/reporting_api/procurement_reporting' },
    // }
    // export const legacy_api_URLs = {
    menusUrlV2: { scopes: ['read', 'write'], cachable: true, url: '/v2/menus' },
    users: { scopes: ['read', 'write'], cachable: true, url: '/users' },
    display_templates: { scopes: ['read', 'write'], cachable: true, url: '/display_templates' },
    print_templates: { scopes: ['read', 'write'], cachable: true, url: '/print_templates' },
    locations: { scopes: ['read', 'write'], cachable: true, url: '/locations' },
    location_settings: { scopes: ['read', 'write'], cachable: true, url: '/locations/settings' },
    locationmenus: { scopes: ['read', 'write'], cachable: true, url: '/locationmenus' },
    scale_dishesV2: { scopes: ['read', 'write'], cachable: true, url: '/v2/scale_dishes' },
    frontend_errors: { scopes: ['read', 'write'], cachable: true, url: '/v3/logging_api/frontend_errors' },
    // }
    // export const admin_api_URLs = {
    organizationsAdminURL: { scopes: ['read', 'write'], cachable: true, url: '/v3/admin/organizations' },
    subsidiariesAdminURL: { scopes: ['read', 'write'], cachable: true, url: '/v3/admin/subsidiaries' },
    locationsAdminUrl: { scopes: ['read', 'write'], cachable: true, url: '/v3/admin/locations' },
    usersAdminURL: { scopes: ['read', 'write'], cachable: true, url: '/v3/admin/users' },
    esl_gateways: { scopes: ['read', 'write'], cachable: true, url: '/v3/admin/esl_gateways' },
    esl_labels: { scopes: ['read', 'write'], cachable: true, url: '/v3/admin/esl_labels' },
    esl_label_info: { scopes: ['read', 'write'], cachable: true, url: '/v3/admin/esl_labels/hardware_info' },
    gatewaysAdminURL: { scopes: ['read', 'write'], cachable: true, url: '/v3/admin/gateways' },
    scalesAdminURL: { scopes: ['read', 'write'], cachable: true, url: '/v3/admin/scales' },
    subsidiaryDataUrl: { scopes: ['read', 'write'], cachable: true, url: '/v3/admin/reporting_api/reporting_data' },
    mender_status: { scopes: ['read', 'write'], cachable: true, url: '/v3/admin/mender_status' },
    admin_trackings: { scopes: ['read', 'write'], cachable: true, url: '/v3/admin/trackings' },
    admin_scale_dishes: { scopes: ['read', 'write'], cachable: true, url: '/v3/admin/scale_dishes' },
    admin_domains: { scopes: ['read', 'write'], cachable: true, url: '/v3/admin/domains' },
    admin_impersonation_token: { scopes: ['read', 'write'], cachable: true, url: '/v3/admin/impersonation_token' },
    // }
    // export const admin_legacy_api_URLs = {
    notificationUrl: { scopes: ['read', 'write'], cachable: true, url: '/admin/notification' },
    scaleSwapURL: { scopes: ['read', 'write'], cachable: true, url: '/v3/admin_api/scale_swap' },
    accessRolesURL: { scopes: ['read', 'write'], cachable: true, url: '/v3/admin/access_roles' },
    // }
    // export const unauthorized_api_URLS = {
    subsidiaries_open: { scopes: ['read', 'write'], cachable: true, url: '/v3/subsidiaries_open' },
    locations_open: { scopes: ['read', 'write'], cachable: true, url: '/v3/profile_api/locations_open' },
    insights_open: { scopes: ['read', 'write'], cachable: true, url: '/v3/reporting_api/insights_open' },
    menus_open: { scopes: ['read', 'write'], cachable: true, url: '/v3/menu_api/menus_open' },
    allergens_open: { scopes: ['write'], cachable: true, url: '/v3/dish_api/allergens_open' },
    // }
    // export const commerceAPIURLS = {
    prices: { scopes: [], cachable: true, cookieAuthenticated: true, url: '/v1/prices' },
    drop_dates: { scopes: ['read', 'write'], cachable: true, cookieAuthenticated: true, url: '/v1/drop_dates' },
    // }
    // export const statusURLS = {
    celery_task: { scopes: ['read', 'write'], cachable: true, url: '/v3/celery_task' }
  }
