export function round(num, decimalPlaces = 0) {
  if (num >= 0) {
    const p = Math.pow(10, decimalPlaces)
    const n = num * p * (1 + Number.EPSILON)
    return Math.round(n) / p
  } else {
    return null
  }
}

export function roundString(str: string, decimalPlaces?: number): number {
  if (str.length == 0) return 0
  if (decimalPlaces) return this.round(parseFloat(str?.replace(',', '.')), decimalPlaces)
  else return parseFloat(str?.replace(',', '.'))
}

export function min(numbers: number[]): number {
  const validNumbers = numbers.filter((n) => n != null || n != undefined)
  if (validNumbers.length == 0) return null
  return Math.min(...validNumbers)
}
