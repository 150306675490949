import { Overlay, OverlayRef } from '@angular/cdk/overlay'
import { Component, ElementRef, ViewChild, ViewContainerRef } from '@angular/core'
import { FormControl } from '@angular/forms'
import { UserComponent } from './user/user.component'
import { AuthorizationService, GlobalFunctionsService, IAccessRole, Organization, OrganizationsService, OverlayService, Subsidiary, SubsidiaryService, User, UserService } from 'foodop-lib'
import { Observable, Subject, catchError, debounceTime, exhaustMap, filter, merge, of, scan, startWith, switchMap, takeWhile, tap } from 'rxjs'
import { MatAutocompleteTrigger } from '@angular/material/autocomplete'

@Component({
  selector: 'users',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.css']
})
export class UsersComponent {
  @ViewChild('customerInput') customerInput: ElementRef
  @ViewChild(MatAutocompleteTrigger) autocompleteTrigger: MatAutocompleteTrigger

  public customerFilterControl: FormControl = new FormControl('')
  public customerSearchControl: FormControl = new FormControl('')
  public customerSelection: FormControl = new FormControl([])
  public loadingFilterOrganizations: boolean = false
  public loadingOrganizations: boolean = false
  public userSearchControl: FormControl = new FormControl('')
  public loadingUsers: boolean = false
  public moreUsersAvailable: boolean = false

  private _organizationsFilterOffset: number = 0
  private _loadNextFilterOrganizations$ = new Subject()
  private _filterOrganizations: Organization[] = []
  private _organizationsOffset: number = 0
  private _loadNextOrganizations$ = new Subject()
  private _organizations: Organization[] = []
  private _usersOffset: number = 0
  private _loadNextUsers$ = new Subject()
  private overlayRef: OverlayRef

  selected_subsidiaries: FormControl = new FormControl([])
  limit: number = 10

  public filterOrganizations$: Observable<Organization[]> = this._loadFilterOrganizations()
  public organizations$: Observable<Organization[]> = this._loadSearchedOrganizations()
  public users$: Observable<User[]> = this._loadUsers()
  public accessRoles$: Observable<IAccessRole[]> = this._authorizationService.loadAccessRoles()

  constructor(
    public userService: UserService,
    private _authorizationService: AuthorizationService,
    public organizationsService: OrganizationsService,
    private subsidiaryService: SubsidiaryService,
    public func: GlobalFunctionsService,
    public overlay: Overlay,
    private _viewContainerRef: ViewContainerRef,
    private _overlayService: OverlayService
  ) {}

  public get selectedSubsidiaryIds(): string[] {
    return this._selectedSubsidiaries.map((location) => location.id)
  }

  public get selections(): string {
    const selections = this.customerSelection.value.filter((selectionOption) => {
      if (selectionOption.constructor.name == 'Organization') return true
      if (selectionOption.constructor.name == 'Subsidiary') {
        return !this.customerSelection.value
          .filter((selectionOption) => selectionOption.constructor.name == 'Organization')
          .map((organization) => organization.subsidiaries.map((subsidiary) => subsidiary.id))
          .flat()
          .includes(selectionOption.id)
      }
    })
    selections.sort((a, b) => {
      if (a.constructor.name == 'Organization' && b.constructor.name != 'Organization') return -1
      if (a.constructor.name != 'Organization' && b.constructor.name == 'Organization') return 1
      return 0
    })
    return (
      selections
        .map((selection) => selection.name.value)
        .slice(0, 3)
        .join(', ') + (selections.length > 3 ? ' + ' + selections.length + ' more' : '')
    )
  }

  private get _selectedSubsidiaries(): Subsidiary[] {
    return this.customerSelection.value.filter((selectionOption: Subsidiary | Organization) => selectionOption.constructor.name == 'Subsidiary') as Subsidiary[]
  }

  public userSubsidiaryName(user: User): string {
    return this.subsidiaryService.subsidiary_with_id(user.subsidiary_id.value)?.name?.value || 'Unallocated'
  }

  public toggleSelection(option: Subsidiary | Organization): void {
    if (option.constructor.name == 'Organization') {
      if (this.optionSelected(option)) {
        this.customerSelection.setValue(
          this.customerSelection.value.filter((selected_option: Subsidiary | Organization) => (option as Organization).id != selected_option.id && !(option as Organization).subsidiaries.map((subsidiary) => subsidiary.id).includes(selected_option.id))
        )
      } else {
        this.customerSelection.setValue(this.customerSelection.value.concat([option]).concat((option as Organization).subsidiaries))
      }
    } else if (option.constructor.name == 'Subsidiary')
      if (this.optionSelected(option)) this.customerSelection.setValue(this.customerSelection.value.filter((selected_option: Subsidiary) => selected_option.id != option.id))
      else this.customerSelection.setValue(this.customerSelection.value.concat([option]))
  }

  public optionSelected(option: Subsidiary | Organization): boolean {
    return this.customerSelection.value.find((selectionOption) => selectionOption.id == option.id) != undefined
  }

  public filterSubsidiariesBeforeIndex(organizationIndex: number): number {
    return this._filterOrganizations
      .slice(0, organizationIndex + 1)
      .map((organization) => organization.subsidiaries.length)
      .reduce((a, b) => a + b, 0)
  }

  public subsidiariesBeforeIndex(organizationIndex: number): number {
    return this._organizations
      .slice(0, organizationIndex + 1)
      .map((organization) => organization.subsidiaries.length)
      .reduce((a, b) => a + b, 0)
  }

  public onFilterScroll(): void {
    this._loadNextFilterOrganizations$.next(true)
  }

  public onScroll(): void {
    this._loadNextOrganizations$.next(true)
  }

  public loadMoreUsers(): void {
    this._loadNextUsers$.next(true)
  }

  public openUser(user: User = new User()) {
    this._overlayService.openComponentInPopUp(
      this._viewContainerRef,
      UserComponent,
      {
        user: user
      },
      ['w-[50vw]', 'max-w-[80%]', 'max-h-[80%]']
    )
  }

  public onUserSubsidiaryChanges(user: User): void {
    if (!user.subsidiary_id.value) user.subsidiary_accesses.setValue([])
    else if (user.is_user_admin) {
      const subsidiary = this.subsidiaryService.subsidiary_with_id(user.subsidiary_id.value)
      user.subsidiary_accesses.setValue(
        subsidiary?.organization?.subsidiaries?.map((subsidiary) => {
          return { id: subsidiary.id }
        })
      )
    } else {
      user.subsidiary_accesses.setValue([])
    }
    user.adminSave().subscribe()
  }

  private _loadFilterOrganizations(): Observable<Organization[]> {
    const filterChanges$ = this.customerFilterControl.valueChanges.pipe(
      startWith(''),
      tap((value) => {
        if (typeof value === 'string') {
          this._filterOrganizations = []
          this._organizationsFilterOffset = 0
        }
      }),
      filter((value) => typeof value === 'string' && (value.length >= 2 || value == '')),
      tap(() => {
        this.loadingFilterOrganizations = true
      }),
      debounceTime(200)
    )

    return filterChanges$.pipe(
      switchMap(() => {
        return this._loadNextFilterOrganizations$.pipe(
          startWith(0),
          exhaustMap(() => this.organizationsService.loadOrganizations('subsidiaries', this.customerFilterControl.value.toLowerCase(), this._organizationsFilterOffset)),
          takeWhile((organizations) => organizations.length == 10, true),
          scan((allOrganizations: Organization[], newOrganizations: Organization[]) => allOrganizations.concat(newOrganizations)),
          tap((organizations) => {
            this._filterOrganizations = organizations
            this._organizationsFilterOffset += 10
            this.loadingFilterOrganizations = false
          }),
          catchError(() => [])
        )
      })
    )
  }

  private _loadSearchedOrganizations(): Observable<Organization[]> {
    const filterChanges$ = this.customerSearchControl.valueChanges.pipe(
      startWith(''),
      tap((value) => {
        if (typeof value === 'string') {
          this._organizations = []
          this._organizationsOffset = 0
        }
      }),
      filter((value) => typeof value === 'string' && (value.length >= 2 || value == '')),
      tap(() => {
        this.loadingOrganizations = true
      }),
      debounceTime(200)
    )

    return filterChanges$.pipe(
      switchMap(() => {
        return this._loadNextOrganizations$.pipe(
          startWith(0),
          exhaustMap(() => this.organizationsService.loadOrganizations('subsidiaries', this.customerSearchControl.value.toLowerCase(), this._organizationsOffset)),
          takeWhile((organizations) => organizations.length == 10, true),
          scan((allOrganizations: Organization[], newOrganizations: Organization[]) => allOrganizations.concat(newOrganizations)),
          tap((organizations) => {
            this._organizations = organizations
            this._organizationsOffset += 10
            this.loadingOrganizations = false
          }),
          catchError(() => [])
        )
      })
    )
  }

  private _loadUsers(): Observable<User[]> {
    const filterChanges$ = merge(this.userSearchControl.valueChanges, this.customerSelection.valueChanges).pipe(
      startWith(''),
      tap(() => (this._usersOffset = 0)),
      debounceTime(200)
    )

    return filterChanges$.pipe(
      switchMap(() => {
        return this._loadNextUsers$.pipe(
          startWith(0),
          tap(() => (this.loadingUsers = true)),
          exhaustMap(() => {
            if (!this._selectedSubsidiaries.length && this.userSearchControl.value.length <= 1) return of([])
            return this.userService.loadUsers(['all'], this.userSearchControl.value.toLowerCase(), this.selectedSubsidiaryIds, this._usersOffset)
          }),
          takeWhile((users) => users.length == 10, true),
          scan((allUsers: User[], newUsers: User[]) => allUsers.concat(newUsers)),
          tap(() => {
            this._usersOffset += 10
            this.loadingUsers = false
          }),
          catchError(() => [])
        )
      })
    )
  }
}
