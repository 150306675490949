import { Component, EventEmitter, Input, Output } from '@angular/core'

@Component({
  selector: 'stepper-button',
  templateUrl: './stepper-button.component.html'
})
export class StepperButtonComponent {
  @Input() decreaseDisabled: boolean
  @Input() increaseDisabled: boolean
  @Input() decreaseTooltip: string
  @Input() increaseTooltip: string
  @Input() counter: number
  @Output() decrease: EventEmitter<void> = new EventEmitter()
  @Output() increase: EventEmitter<void> = new EventEmitter()

  constructor() {}

  public onDecrease(): void {
    this.decrease.emit()
  }

  public onIncrease(): void {
    this.increase.emit()
  }
}
