import { HttpClient } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { apiEndpoints } from './api-endpoints'

@Injectable({
  providedIn: 'root'
})
export class CommerceAPIService {
  constructor(@Inject('environment') private environment, private http: HttpClient) {}

  public httpRequest(endpoint: string, method: string, body: {} = undefined, params: {} = undefined): Observable<any> {
    return this.http.request(method, this.environment.commerceApiEndpoint + apiEndpoints[endpoint].url, {
      body: body,
      params: params
    })
  }
}
