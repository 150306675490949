import { BooleanInput } from '@angular/cdk/coercion'
import { Component, EventEmitter, Inject, Input, LOCALE_ID, Output, ViewChild } from '@angular/core'
import { FormControl } from '@angular/forms'

@Component({
  selector: 'drop-down-picker',
  templateUrl: './drop-down-picker.component.html',
  styleUrls: ['./drop-down-picker.component.css']
})
export class DropDownPickerComponent {
  @Input() selection: FormControl
  @Input() disabled: boolean
  @Input() options: any[]
  @Input() multiple: BooleanInput
  @Input() title: string
  @Input() comparison_key: string
  @Input() all_option_label: string
  @Input() option_path: string[]
  @Input() nestedOptionKeys: string[]
  @Input() nestedOptionPath: string[]
  @Input() max_picks: number
  @Input() disabled_options: any[]
  @Input() value_key: string
  @Input() deselectOptionLabel: string
  @Input() defaultOptionLabel: string
  @Input() placeholderLabel: string
  @Input() showInvalidOptionLabel: BooleanInput
  @Input() noneOption: BooleanInput
  @Input() addOption: BooleanInput
  @Input() addOptionLabel: string
  @Input() editOption: BooleanInput
  @Input() deleteOption: BooleanInput
  @Input() selectionLabels: any[]
  @Output() addOptionEvent = new EventEmitter()
  @Output() changedEvent = new EventEmitter()
  @Output() editOptionEvent = new EventEmitter<any>()
  @Output() deleteOptionEvent = new EventEmitter<any>()
  @ViewChild('selectAllOption') private selectAllOption

  constructor(@Inject(LOCALE_ID) public language: string) {
    if (this.multiple && !this.nestedOptionKeys?.length && this.options == this.selection.value.length) this.selection.setValue([...this.selection.value.length, 'all'])
  }

  ngOnInit() {
    if (this.multiple && !this.nestedOptionKeys?.length && this.options.length == this.selection.value.length) this.selection.setValue([...this.selection.value, 'all'])
  }

  public get allOption(): boolean {
    return this.multiple && this.optionObjects.length > 1 && ((this.max_picks && this.max_picks >= this.optionObjects.length) || !this.max_picks)
  }
  get selected_options(): any[] {
    return this.selection.value.filter((option) => option != 'none' && option != 'all').map((selection) => (this.value_key ? this.options.find((option) => option[this.value_key] == selection) : selection))
  }

  private get _options(): any[] {
    let options = []
    if (this.noneOption) options.push('none')
    if (this.allOption) options.push('all')
    return this.optionObjects.concat(options)
  }

  private get _optionsValues(): any[] {
    return this.value_key ? this.options.map((option) => option[this.value_key]) : this.options
  }

  private get optionObjects(): any[] {
    return this.nestedOptionKeys?.length ? this.options.flatMap((option) => this.nestedOption(option)) : this._optionsValues
  }

  private get _flattenedOptions(): any[] {
    return this.nestedOptionKeys?.length ? this.options.flatMap((option) => this.nestedOption(option)) : this.options
  }

  get selection_label() {
    const customSelectionLabel = this.selectionLabels?.find((selectionLabel) => JSON.stringify(selectionLabel.value) == JSON.stringify(this.selection.value))
    if (customSelectionLabel) return customSelectionLabel.label
    if (this.selected_options.length == this.optionObjects.length && this.optionObjects.length > 1) return this.all_option_label
    if (this.selected_options.length == 0) return $localize`Ingen valgt`
    if (this.selected_options.length == 1) return this.option_name(this.selected_options[0])
    if (this.selected_options.length > 1) return this.option_name(this.selected_options[0]) + ' + ' + (this.selected_options.length - 1) + ' ' + $localize`andre`
  }

  private get _selectedOption(): string {
    return this._flattenedOptions.find((option) => (this.value_key && this.selection.value == option[this.value_key]) || this.compareFunc(this.selection.value, option))
  }

  public get selectedOptionName(): string {
    return this.option_name(this._selectedOption || this.selection.value)
  }

  public onAddOption(): void {
    this.addOptionEvent.emit()
  }

  public onEditOption(option): void {
    this.editOptionEvent.emit(option)
  }

  public onDeleteOption(option): void {
    this.deleteOptionEvent.emit(option)
  }

  public onChanges(): void {
    this.changedEvent.emit()
  }

  public nestedOption(option): any {
    return this.nestedOptionKeys?.reduce((acc, key) => {
      if (Array.isArray(acc)) return acc.map((subOption) => subOption[key])
      else if (typeof acc == 'object') return acc[key]
    }, option)
  }

  public nestedOptionName(option): string {
    if (option == undefined) return ''
    return (this.nestedOptionPath || this.option_path)?.reduce((acc, key) => acc[key] || acc, option)
  }

  option_name(option): string {
    if (option == undefined) return ''
    if (option == 'none') return this.deselectOptionLabel
    if (option == 'all') return this.defaultOptionLabel
    this.option_path.forEach((key) => {
      option = option[key] || option
    })
    return option
  }

  is_selected(option): boolean {
    return this.selected_options.some((selected_option) => this.compareFunc(selected_option, option))
  }

  is_disabled(option): boolean {
    return this.disabled_options?.some((disabled_option) => this.compareFunc(disabled_option, option))
  }

  toggleAllOptions() {
    if (this.selectAllOption.selected) this.selection.setValue(this._options)
    else this.selection.setValue([])
  }

  tossleOneOption() {
    if (this.multiple && this.selectAllOption) {
      if (this.selectAllOption.selected) this.selectAllOption.deselect()
      else if (this.optionObjects.length + (this.noneOption ? 1 : 0) == this.selection.value.length) this.selectAllOption.select()
    }
  }

  compareFunc = (optionOne, optionTwo): boolean => {
    if (this.comparison_key)
      return (optionOne == 'none' && optionTwo == 'none') || (optionOne == 'all' && optionTwo == 'all') || (optionOne && optionTwo && optionOne[this.comparison_key] && optionOne[this.comparison_key] && optionOne[this.comparison_key] == optionTwo[this.comparison_key])
    else return optionOne == optionTwo
  }

  get option_disabled(): boolean {
    return this.max_picks && this.selection.value.length >= this.max_picks
  }
}
