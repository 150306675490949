<header class="flex justify-between items-center mt-3 flex-wrap gap-2">
  <input class="flex-grow !min-w-1/3 !max-w-72" autocomplete="off" matInput placeholder="Search for label" [formControl]="ESLLabelSearchControl" />
  <custom-button text="Register ESL label" (click)="openESLLabel()" class="whitespace-nowrap"></custom-button>
</header>

<main *ngIf="ESLLabels$ | async as ESLLabels">
  <ng-container *ngIf="ESLLabels.length; else noESLLabels">
    <mat-card *ngFor="let ESLLabel of ESLLabels; let ESLLabelIndex = index" class="m-3 cursor-pointer" (click)="openESLLabel(ESLLabel)">
      <div class="flex justify-between items-center flex-wrap gap-2">
        <label class="font-bold text-xs cursor-pointer">
          <mat-icon class="font-bold !text-xs !w-3 !h-3 mx-1 text-gray-300" [ngClass]="{ '!text-green-500': ESLLabel.battery == 'GOOD', '!text-red-500': ESLLabel.battery == 'BAD' }">battery_full</mat-icon>
          <mat-icon class="font-bold !text-xs !w-3 !h-3 mx-1 text-gray-300" [ngClass]="{ '!text-green-500': ESLLabel.signal == 'EXCELLENT', '!text-yellow-500': ESLLabel.signal == 'GOOD', '!text-red-500': ESLLabel.signal == 'BAD' }">{{
            ESLLabel.signal == 'BAD' ? 'signal_wifi_off' : 'signal_wifi_4_bar'
          }}</mat-icon>
          {{ ESLLabel.labelCode.value }} | {{ ESLLabel.index.value }}</label
        >
        <location-picker [selection]="ESLLabel.locationId" (selectionChange)="ESLLabel.allocate().subscribe()" (click)="$event.stopImmediatePropagation()"></location-picker>
      </div>
    </mat-card>
  </ng-container>

  <ng-template #noESLLabels>
    <div *ngIf="(ESLLabelSearchControl.value || selectedLocationIds.length) && !loadingESLLabels" class="w-full flex flex-col justify-center items-center mt-4">
      <label>No ESL Labels found!</label>
    </div>
  </ng-template>
</main>

<div *ngIf="loadingESLLabels" class="w-full flex flex-col justify-center items-center mt-4">
  <mat-spinner [diameter]="25" color="primary"></mat-spinner>
  <label>Loading ESL Labels ...</label>
</div>

<div *ngIf="moreESLLabelsAvailable" class="flex flex-row justify-center items-center mt-4">
  <custom-button type="secondary" text="Load more ESL labels" (click)="loadMoreESLLabels()"></custom-button>
</div>
