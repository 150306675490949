import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { FormControl } from '@angular/forms'
import { MatSnackBar } from '@angular/material/snack-bar'
import { Location, LocationsService, Organization, OrganizationsService } from 'foodop-lib'
import { catchError, debounceTime, exhaustMap, filter, Observable, of, scan, startWith, Subject, switchMap, takeWhile, tap } from 'rxjs'

@Component({
  selector: 'location-picker',
  templateUrl: './location-picker.component.html'
})
export class LocationPickerComponent implements OnInit {
  @Input() public selection: FormControl
  @Output() public selectionChange = new EventEmitter()
  public organizations$: Observable<Organization[]>
  public customerSearchControl: FormControl = new FormControl('')
  public loadingOrganizations: boolean = false

  private _organizationsOffset: number = 0
  private _loadNextOrganizations$ = new Subject()
  private _organizations: Organization[] = []

  constructor(private _locationsService: LocationsService, private _organizationsService: OrganizationsService, private _snackBar: MatSnackBar) {}

  ngOnInit(): void {
    this.organizations$ = this._loadOrganizations()
  }

  public onSelectionChange(): void {
    this.selectionChange.emit()
  }

  public onScroll(): void {
    this._loadNextOrganizations$.next(true)
  }

  public locationNameForID(): string {
    const location: Location = this._locationsService.location_with_id(this.selection.value)
    if (location) return location?.subsidiary?.name?.value + ' - ' + location?.name?.value
    return 'Unallocated'
  }

  public subsidiariesBeforeIndex(organizationIndex: number): number {
    return this._organizations
      .slice(0, organizationIndex)
      .map((organization) => organization.subsidiaries.length)
      .reduce((a, b) => a + b, 0)
  }

  private _loadOrganizations(): Observable<Organization[]> {
    const searchChanges$ = this.customerSearchControl.valueChanges.pipe(
      startWith(''),
      tap((value) => {
        if (typeof value === 'string') {
          this._organizations = []
          this._organizationsOffset = 0
        }
      }),
      filter((value) => typeof value === 'string' && (value.length >= 2 || value == '')),
      debounceTime(200)
    )

    return searchChanges$.pipe(
      switchMap(() => {
        return this._loadNextOrganizations$.pipe(
          startWith(0),
          tap(() => (this.loadingOrganizations = true)),
          exhaustMap(() => this._organizationsService.loadOrganizations('all', this.customerSearchControl.value.toLowerCase(), this._organizationsOffset)),
          takeWhile((organizations) => organizations.length == 10, true),
          scan((allOrganizations: Organization[], newOrganizations: Organization[]) => allOrganizations.concat(newOrganizations)),
          tap((organizations) => {
            this._organizations = organizations
            this._organizationsOffset += 10
            this.loadingOrganizations = false
          })
        )
      }),
      catchError(() => {
        this.loadingOrganizations = false
        this._snackBar.open('An error occured while loading organizations', 'Close', { duration: 5000 })
        return of([])
      })
    )
  }
}
