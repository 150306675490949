import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { Observable, of, catchError } from 'rxjs'
import { CommerceAPIService } from '../../services/commerce-api.service'
import moment from 'moment'
import { IPackaging } from './packaging.interface'

@Injectable({
  providedIn: 'root'
})
export class PriceService {
  constructor(@Inject(LOCALE_ID) public language: string, private _commerceAPIService: CommerceAPIService) {}

  public getProductPackagings(productIds: string[], customerID: string, deliveryDate: moment.Moment = moment()): Observable<{ [sku: string]: IPackaging }> {
    if (!productIds?.length) return of({})
    return this._commerceAPIService.httpRequest('prices', 'post', { skus: productIds, deliveryDate: deliveryDate.format('YYYY-MM-DD') + 'T00:00:01+01:00', customerID }).pipe(
      catchError((error) => {
        console.error('Error fetching product:', error)
        return of({})
      })
    )
  }
}
