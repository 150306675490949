import { ITag } from '../../global.models'
import { IPackaging } from './packaging.interface'
import { IProduct } from './product.interface'

export const products: IProduct[] = [
  {
    id: '141035',
    type: 'Food',
    fb_category_id: 'Kolonial',
    product_number: '570g',
    names: {
      da: 'Tomatketchup 570g'
    },
    description: {
      da: '570g - Holland - Heinz'
    },
    nutrition: [],
    allergens: [],
    images: [{ rank: 1, uri: 'https://test-media.dgcex.dk/api/v1/product/w620q85/5790000000302/00000087157239/27818184/000000871572391.png' }],
    product_url: 'https://test.dgcex.dk/kolonial-60/dressing-og-ketchup-6018/tomatketchup-601802/tomatketchup-570g-141035',
    brand: 'Heinz',
    vendor_id: '10000129',
    vendor_product_id: '64107',
    tags: ['is_dry'],
    is_ignored: false
  },
  {
    id: '219897',
    type: 'Øko Æbler Royal Gala (84)',
    fb_category_id: '30',
    product_number: '301050',
    names: {
      da: 'Øko Æbler Royal Gala'
    },
    description: {
      da: '1 stk - Royal Gala - Prima Frugt'
    },
    nutrition: [],
    allergens: [],
    images: [{ rank: 1, uri: 'https://test-media.dgcex.dk/api/v1/product/w620q85/5790002133718/05704328608126/30044834/05704328608126z0ng.png' }],
    product_url: 'https://test.dgcex.dk/frugt-og-groent-30/frugt-3010/aebler-301050/oeko-aebler-royal-gala-84-219897',
    brand: 'Prima Frugt',
    vendor_id: '10000509',
    vendor_product_id: '219897',
    tags: ['organic', 'is_chilled'],
    is_ignored: false
  },
  {
    id: '326079',
    type: 'Æbleeddike 6% 5L',
    fb_category_id: '60',
    product_number: '603210',
    names: {
      da: 'Æbleeddike 6% 5L'
    },
    description: {
      da: 'Danmark - Lagerberg'
    },
    nutrition: [],
    allergens: [],
    images: [{ rank: 1, uri: 'https://test-media.dgcex.dk/api/v1/product/w620q85/5790000859702/05703339600761/32053914/05703339600761z1l1.png' }],
    product_url: 'https://test.dgcex.dk/kolonial-60/eddike-og-smagsforstaerkere-6032/aebleeddike-603210/aebleeddike-6-5l-326079',
    brand: 'Lagerberg',
    vendor_id: '10000092',
    vendor_product_id: '102870',
    tags: ['is_dry'],
    is_ignored: false
  },
  {
    id: '322699',
    type: 'Æblemost 4x300ml',
    fb_category_id: '80',
    product_number: '800520',
    names: {
      da: 'Æblemost 4x300ml'
    },
    description: {
      da: 'Danmark - Valsølille'
    },
    nutrition: [],
    allergens: [],
    images: [{ rank: 1, uri: 'https://test-media.dgcex.dk/api/v1/product/w620q85/5790000000180/05705010082309/32543305/05705010082309c1n1.jpg' }],
    product_url: 'https://test.dgcex.dk/drikkevarer-80/saft-og-juice-8005/most-800520/aeblemost-4x300ml-322699',
    brand: 'Valsølille',
    vendor_id: '10000196',
    vendor_product_id: '1010343',
    tags: ['is_chilled'],
    is_ignored: false
  },
  {
    id: '205571',
    type: 'Hakket oksekød 10% min 5kg',
    fb_category_id: '10',
    product_number: '104552',
    names: {
      da: 'Hakket oksekød 10% min 5kg'
    },
    description: {
      da: '5kg+ - MAP - Danmark - KødGrossisten'
    },
    nutrition: [],
    allergens: [],
    images: [{ rank: 1, uri: 'https://test-media.dgcex.dk/api/v1/product/w620q85/5790001669140/02803710000005/32269808/02803710000005z3c0.jpg' }],
    product_url: 'https://test.dgcex.dk/koed-10/oksekoed-1045/hakkekoed-104552/hakket-oksekoed-10-min-5kg-205571',
    brand: 'KødGrossisten',
    vendor_id: '10000180',
    vendor_product_id: '40069',
    tags: ['is_chilled', 'key_hole'],
    is_ignored: false
  },
  {
    id: '307663',
    type: 'Færøsk laks hel side røget 1,2kg+',
    fb_category_id: '20',
    product_number: '201020',
    names: {
      da: 'Færøsk laks hel side røget 1,2kg+'
    },
    description: {
      da: 'Tørsaltet med snor, D-trim - Færøerne - Polar Seafood'
    },
    nutrition: [],
    allergens: [],
    images: [{ rank: 1, uri: 'https://test-media.dgcex.dk/api/v1/product/w620q85/5790001265496/02701615000000/31498042/02701615000000z1c0.jpg' }],
    product_url: 'https://test.dgcex.dk/fisk-og-skaldyr-20/laks-2010/roeget-201020/faeroesk-laks-hel-side-roeget-12kg-307663',
    brand: 'Polar Seafood',
    vendor_id: '10000302',
    vendor_product_id: '4370',
    tags: ['is_chilled'],
    is_ignored: false
  },
  {
    id: '314002',
    type: 'Varmrøget laks naturel +1500g',
    fb_category_id: '20',
    product_number: '201025',
    names: {
      da: 'Varmrøget laks naturel +1500g'
    },
    description: {
      da: 'Salmo Salar SAL, Trim D - Færøerne - Insula'
    },
    nutrition: [],
    allergens: [],
    images: [{ rank: 1, uri: 'https://test-media.dgcex.dk/api/v1/product/w620q85/5790002590559/05715030511871/29771880/05715030511871z3cg.jpg' }],
    product_url: 'https://test.dgcex.dk/fisk-og-skaldyr-20/laks-2010/varmroeget-201025/varmroeget-laks-naturel-1500g-314002',
    brand: 'Insula',
    vendor_id: '10006546',
    vendor_product_id: '381302',
    tags: [],
    is_ignored: false
  },
  {
    id: '309470',
    type: 'Salt lakrids SF 12x70g',
    fb_category_id: '70',
    product_number: '701040',
    names: {
      da: 'Salt lakrids SF 12x70g'
    },
    description: {
      da: 'Danmark - Evers'
    },
    nutrition: [],
    allergens: [],
    images: [{ rank: 1, uri: 'https://test-media.dgcex.dk/api/v1/product/w620q85/5790000015993/05701049512572/31796649/05701049512572c1n1.jpg' }],
    product_url: 'https://test.dgcex.dk/konfekture-kager-og-is-70/konfekture-7010/lakrids-701040/salt-lakrids-sf-12x70g-309470',
    brand: 'Evers',
    vendor_id: '10000209',
    vendor_product_id: '51-257',
    tags: ['is_dry'],
    is_ignored: false
  },
  {
    id: '304716',
    type: 'Hakket svinekød 8-12%',
    fb_category_id: '10',
    product_number: '107572',
    names: {
      da: 'Hakket svinekød 8-12%'
    },
    description: {
      da: '3kg - Den Europæiske Union - KødGrossisten'
    },
    nutrition: [],
    allergens: [],
    images: [{ rank: 1, uri: 'https://test-media.dgcex.dk/api/v1/product/w620q85/5790001669140/02703609000003/28856017/02703609000003z3c0.jpg' }],
    product_url: 'https://test.dgcex.dk/koed-10/svinekoed-1075/hakkekoed-107572/hakket-svinekoed-8-12-30kg-304716',
    brand: 'KødGrossisten',
    vendor_id: '10000180',
    vendor_product_id: '304716',
    tags: [],
    is_ignored: false
  },
  {
    id: '304932',
    type: 'Øko hakket svinekød 15%',
    fb_category_id: '10',
    product_number: '107572',
    names: {
      da: 'Øko hakket svinekød 15% 3kg'
    },
    description: {
      da: 'Den Europæiske Union - KødGrossisten'
    },
    nutrition: [],
    allergens: [],
    images: [{ rank: 1, uri: 'https://test-media.dgcex.dk/api/v1/product/w620q85/5790001669140/02703614000005/32257447/02703614000005z3c0.jpg' }],
    product_url: 'https://test.dgcex.dk/koed-10/svinekoed-1075/hakkekoed-107572/oeko-hakket-svinekoed-15-3kg-304932',
    brand: 'KødGrossisten',
    vendor_id: '10000180',
    vendor_product_id: '304932',
    tags: ['organic', 'is_frozen'],
    is_ignored: false
  },
  {
    id: '304717',
    type: 'Hakket svine-/kalvekød max 12%',
    fb_category_id: '10',
    product_number: '107572',
    names: {
      da: 'Hakket svine-/kalvekød max 12% 3,0kg'
    },
    description: {
      da: '3kg - Den Europæiske Union - KødGrossisten'
    },
    nutrition: [],
    allergens: [],
    images: [{ rank: 1, uri: 'https://test-media.dgcex.dk/api/v1/product/w620q85/5790001669140/02703610000009/28912833/02703610000009z3c0.jpg' }],
    product_url: 'https://test.dgcex.dk/koed-10/svinekoed-1075/hakkekoed-107572/hakket-svine-kalvekoed-max-12-30kg-304717',
    brand: 'KødGrossisten',
    vendor_id: '10000180',
    vendor_product_id: '304717',
    tags: [],
    is_ignored: false
  }
]

export const productFilters: ITag[] = [
  {
    names: {
      da: 'Dyrenes beskyttelse',
      en: 'Animal Protection'
    },
    id: 'animal_protection',
    category: 'certification'
  },
  {
    names: {
      da: 'Fair Trade',
      en: 'Fair Trade'
    },
    id: 'fair_trade',
    category: 'certification'
  },
  {
    names: {
      da: 'FSC',
      en: 'FSC'
    },
    id: 'fsc',
    category: 'certification'
  },
  {
    names: {
      da: 'Glutenfri',
      en: 'Gluten free'
    },
    id: 'gluten_free',
    category: 'certification'
  },
  {
    names: {
      da: 'Halal',
      en: 'Halal'
    },
    id: 'halal',
    category: 'certification'
  },
  {
    names: {
      da: 'MSC',
      en: 'MSC'
    },
    id: 'msc',
    category: 'certification'
  },
  {
    names: {
      da: 'Nøglehulsmærket',
      en: 'Keyhole'
    },
    id: 'key_hole',
    category: 'certification'
  },
  {
    names: {
      da: 'Svanemærket',
      en: 'Swan Mark'
    },
    id: 'swan_mark',
    category: 'certification'
  },
  {
    names: {
      da: 'Vegan',
      en: 'Vegan'
    },
    id: 'vegan',
    category: 'certification'
  },
  {
    names: {
      da: 'Økologisk',
      en: 'Organic'
    },
    id: 'organic',
    category: 'certification'
  },
  {
    names: {
      da: 'Biodynamisk',
      en: 'Bio Dynamic'
    },
    id: 'bio_dynamic',
    category: 'characteristic'
  },
  {
    names: {
      da: 'Convenience',
      en: 'Convenience'
    },
    id: 'convenience',
    category: 'characteristic'
  },
  {
    names: {
      da: 'GMO fri',
      en: 'GMO Free'
    },
    id: 'gmo_free',
    category: 'characteristic'
  },
  {
    names: {
      da: 'Sous Vide',
      en: 'Sous Vide'
    },
    id: 'sous_vide',
    category: 'characteristic'
  },
  {
    names: {
      da: 'Sukkerfri',
      en: 'Sugar free'
    },
    id: 'sugar_free',
    category: 'characteristic'
  },
  {
    names: {
      da: 'To Go',
      en: 'To Go'
    },
    id: 'to_go',
    category: 'characteristic'
  },
  {
    names: {
      da: 'Upcycled',
      en: 'Upcycled'
    },
    id: 'up_cycled',
    category: 'characteristic'
  },
  {
    names: {
      da: 'Kølevare',
      en: 'Chilled'
    },
    id: 'is_chilled',
    category: 'storage'
  },
  {
    names: {
      da: 'Tørvare',
      en: 'Dry'
    },
    id: 'is_dry',
    category: 'storage'
  },
  {
    names: {
      da: 'Frysevare',
      en: 'Frozen'
    },
    id: 'is_frozen',
    category: 'storage'
  }
]

export const productPackagings: { [sku: string]: IPackaging } = {
  '141035': {
    sku: '141035',
    unit: 'fl',
    unitsPerColli: 12,
    weightDependable: false,
    allowBreakage: true,
    priceType: 'StandardPrice',
    singlePrice: 46.33,
    singlePricePerKg: 81.28,
    colliPrice: 555.96,
    colliPricePerUnit: 46.33,
    colliPriceBefore: 555.96,
    colliPricePerUnitBefore: 46.33,
    colliPriceDiscountPercent: 0,
    colliPricePerKg: 81.28,
    netWeight: 0.57
  },
  '205571': {
    sku: '205571',
    unit: 'kg',
    unitsPerColli: 1,
    weightDependable: true,
    colliWeight: 5,
    allowBreakage: true,
    priceType: 'StandardPrice',
    colliPrice: 577.95,
    colliPricePerUnit: 115.59,
    colliPricePerKg: 115.59,
    netWeight: 1
  },
  '219897': {
    sku: '219897',
    unit: 'stk',
    unitsPerColli: 84,
    weightDependable: false,
    allowBreakage: true,
    priceType: 'StandardPrice',
    singlePrice: 3.67,
    singlePricePerKg: 25.85,
    colliPrice: 308.28,
    colliPricePerUnit: 3.67,
    colliPriceBefore: 308.28,
    colliPricePerUnitBefore: 3.67,
    colliPriceDiscountPercent: 0,
    colliPricePerKg: 25.85,
    netWeight: 0.142
  },
  '304716': {
    sku: '304716',
    unit: 'kg',
    unitsPerColli: 1,
    weightDependable: true,
    colliWeight: 3,
    allowBreakage: true,
    priceType: 'StandardPrice',
    colliPrice: 204.06,
    colliPricePerUnit: 68.02,
    colliPricePerKg: 68.02,
    netWeight: 1
  },
  '304717': {
    sku: '304717',
    unit: 'kg',
    unitsPerColli: 1,
    weightDependable: true,
    colliWeight: 3,
    allowBreakage: true,
    priceType: 'StandardPrice',
    colliPrice: 232.92,
    colliPricePerUnit: 77.64,
    colliPricePerKg: 77.64,
    netWeight: 1
  },
  '304932': {
    sku: '304932',
    unit: 'stk',
    unitsPerColli: 1,
    weightDependable: false,
    allowBreakage: false,
    priceType: 'StandardPrice',
    singlePrice: 365.98,
    singlePricePerKg: 121.99,
    netWeight: 3
  },
  '307663': {
    sku: '307663',
    unit: 'kg',
    unitsPerColli: 1,
    weightDependable: true,
    colliWeight: 1.2,
    allowBreakage: true,
    priceType: 'StandardPrice',
    colliPrice: 367.28,
    colliPricePerUnit: 306.07,
    colliPricePerKg: 306.07,
    netWeight: 1
  },
  '309470': {
    sku: '309470',
    unit: 'krt',
    unitsPerColli: 1,
    weightDependable: false,
    allowBreakage: false,
    priceType: 'StandardPrice',
    singlePrice: 162.59,
    singlePricePerKg: 193.56,
    netWeight: 0.84
  },
  '314002': {
    sku: '314002',
    unit: 'kg',
    unitsPerColli: 1,
    weightDependable: true,
    colliWeight: 1.5,
    allowBreakage: true,
    priceType: 'StandardPrice',
    colliPrice: 510.8,
    colliPricePerUnit: 340.53,
    colliPricePerKg: 340.53,
    netWeight: 1
  },
  '322699': {
    sku: '322699',
    unit: 'krt',
    unitsPerColli: 1,
    weightDependable: false,
    allowBreakage: false,
    priceType: 'StandardPrice',
    singlePrice: 78.06,
    singlePricePerKg: 65.05,
    netWeight: 1.2
  },
  '326079': {
    sku: '326079',
    unit: 'stk',
    unitsPerColli: 3,
    weightDependable: false,
    allowBreakage: false,
    priceType: 'StandardPrice',
    colliPrice: 774.96,
    colliPricePerUnit: 258.32,
    colliPricePerKg: 51.66,
    netWeight: 5
  }
}
