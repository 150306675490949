import { FormBuilder, FormControl } from '@angular/forms'
import { merge, Observable, tap } from 'rxjs'
import { FoodopLibModule } from '../../foodop-lib.module'
import { GlobalFunctionsService } from '../../services/global-functions.service'
import { Allergen } from '../allergen/allergen.model'
import { Names } from '../names/names.model'
import { RawIngredientsService } from './raw-ingredients.service'
import { NutritionFact } from '../nutrition/nutrition-fact.model'
import { Tag } from '../tag/tag.model'
import { TagsService, ingredientTagSorting } from '../tag/tag.service'
import { IAllergen, IName, INutritionFact, IRawIngredient } from '../../global.models'
import { AllergensService } from '../allergen/allergens.service'
import { NutritionService } from '../nutrition/nutrition.service'
import { required_nutrition_facts, requiredAllergens } from '../../global.types'
import { round, roundString } from '../../utils/number-operators'
import { Product } from '../products/product.model'

export class RawIngredient {
  id: string
  names: Names
  ingredients: FormControl
  accessibility: FormControl
  organization_id: string
  subsidiary_id: string
  user_id: string
  co2: FormControl
  allergens: Allergen[]
  sourceIngredientId: FormControl
  sourceIngredientNames: IName
  FBCategoryID: FormControl
  nutrition_base_unit: FormControl
  nutrition_base_preparation: FormControl
  nutrition_base_value: FormControl
  nutrition: NutritionFact[]

  menu_dishes: any[]
  lock: boolean
  estimated_share: number // Estimated share from db

  default_shrinkage: number // Estimated shrinkage in % going fra uncleaned raw-ingredient to cleanded raw-ingredient
  shrinkage: FormControl = new FormControl() // Estimated shrinkage in % going fra uncleaned raw-ingredient to cleanded raw-ingredient
  preparation_factor: FormControl = new FormControl() // Estimated weight factor going from cleaned raw-ingredient to prepared ingredient
  netto_factor: FormControl = new FormControl() // Combined factor going from uncleaned raw-ingredient to prepared ingredient
  unit: FormControl

  portion_id: string

  type: string
  source: any

  loading = false
  saving = false
  populatingProduct = false
  saved_ingredient: string

  // procurement/production variables:
  provider: FormControl
  product_number: FormControl
  price: FormControl
  tags: Tag[]
  gtin: FormControl
  gtins: string[]
  in_stock: FormControl
  product: Product

  updated: string
  created: string
  search_name: string

  potential_duplicates: RawIngredient[]

  public nettoFactorChange$: Observable<number> = this._listenForNettoFactorChanges()
  public shrinkageAndPreparationFactorChange$: Observable<number> = this._listenForShrinkageAndPreparationFactorChanges()

  rawIngredientsService: RawIngredientsService
  allergensService: AllergensService
  nutritionService: NutritionService
  tagsService: TagsService
  fb: FormBuilder
  func: GlobalFunctionsService

  constructor(public raw_ingredient?: IRawIngredient) {
    this.rawIngredientsService = FoodopLibModule.injector.get(RawIngredientsService)
    this.allergensService = FoodopLibModule.injector.get(AllergensService)
    this.nutritionService = FoodopLibModule.injector.get(NutritionService)
    this.tagsService = FoodopLibModule.injector.get(TagsService)
    this.fb = FoodopLibModule.injector.get(FormBuilder)
    this.func = FoodopLibModule.injector.get(GlobalFunctionsService)

    this.id = raw_ingredient?.id
    this.names = new Names(raw_ingredient?.names)
    this.co2 = this.fb.control(raw_ingredient?.co2)
    this.unit = this.fb.control(raw_ingredient?.unit || 'kg')
    this.estimated_share = 10
    this.lock = false
    this.createAllergensFromArray(raw_ingredient?.allergens || [])

    this.nutrition_base_unit = this.fb.control('GRM')
    this.nutrition_base_preparation = this.fb.control('UNPREPARED')
    this.nutrition_base_value = this.fb.control(100)
    this.nutrition = this._createNutritionFactsFromArray(raw_ingredient?.nutrition || [])

    this.default_shrinkage = raw_ingredient?.default_shrinkage || 1
    this.shrinkage.setValue(raw_ingredient?.shrinkage?.toString() || '1', { emitEvent: false })
    this.preparation_factor.setValue(raw_ingredient?.preparation_factor?.toString() || '1', { emitEvent: false })
    this.netto_factor.setValue(raw_ingredient?.netto_factor?.toString() || '1', { emitEvent: false })

    this.provider = this.fb.control(raw_ingredient?.provider)
    this.product_number = this.fb.control(raw_ingredient?.product_number)
    this.price = this.fb.control(raw_ingredient?.price)
    this.tags = (raw_ingredient?.tags || []).map((tag) => new Tag(tag))
    this.gtin = this.fb.control(raw_ingredient?.type == 'generic' ? null : Array.isArray(raw_ingredient?.gtin) ? (raw_ingredient?.gtin.length ? raw_ingredient?.gtin[0] : null) : raw_ingredient?.gtin)
    this.gtins = raw_ingredient?.gtin
    this.type = raw_ingredient?.type
    this.source = raw_ingredient?.source
    this.sourceIngredientId = this.fb.control(raw_ingredient?.ingredient?.id)
    this.sourceIngredientNames = raw_ingredient?.ingredient?.names
    this.FBCategoryID = this.fb.control(raw_ingredient?.fb_category_id)
    this.accessibility = this.fb.control(raw_ingredient?.accessibility || 0)
    this.organization_id = raw_ingredient?.organization_id
    this.subsidiary_id = raw_ingredient?.subsidiary_id
    this.user_id = raw_ingredient?.user_id
    this.updated = raw_ingredient?.updated
    this.created = raw_ingredient?.created
    this.search_name = raw_ingredient?.search_name

    this.in_stock = this.fb.control(false)
    this.product = new Product(Object.assign(raw_ingredient?.product || {}, { ingredient_id: this.id }))

    this.saveIngredientObject()
  }

  patchValue(raw_ingredient: IRawIngredient, emitEvent?: boolean, hardReset?: boolean): void {
    if ('id' in raw_ingredient || hardReset) this.id = raw_ingredient.id
    if ('names' in raw_ingredient || hardReset) this.names.patchValue(raw_ingredient?.names)
    if ('co2' in raw_ingredient || hardReset) this.co2.setValue(raw_ingredient.co2, { emitEvent: emitEvent })
    if ('allergens' in raw_ingredient || hardReset) this.updateAllergensFromArray(raw_ingredient?.allergens)
    if ('nutrition' in raw_ingredient || hardReset) this.nutrition = this._createNutritionFactsFromArray(raw_ingredient?.nutrition)
    if ('provider' in raw_ingredient || hardReset) this.provider.setValue(raw_ingredient.provider, { emitEvent: emitEvent })
    if ('product_number' in raw_ingredient || hardReset) this.product_number.setValue(raw_ingredient.product_number, { emitEvent: emitEvent })
    if ('price' in raw_ingredient || hardReset) this.price.setValue(raw_ingredient.price, { emitEvent: emitEvent })
    if ('tags' in raw_ingredient || hardReset) this.tags = (raw_ingredient?.tags || []).map((tag) => new Tag(tag))
    if ('gtin' in raw_ingredient || hardReset) this.gtin.setValue(raw_ingredient.gtin, { emitEvent: emitEvent })
    if ('type' in raw_ingredient || hardReset) this.type = raw_ingredient.type
    if ('source' in raw_ingredient || hardReset) this.source = raw_ingredient?.source
    if ('in_stock' in raw_ingredient || hardReset) this.in_stock.setValue(raw_ingredient.in_stock, { emitEvent: emitEvent })
    if ('ingredient' in raw_ingredient || hardReset) {
      this.sourceIngredientId.setValue(raw_ingredient.ingredient?.id, { emitEvent: emitEvent })
      this.sourceIngredientNames = raw_ingredient.ingredient?.names
    }
    if ('fb_category_id' in raw_ingredient || hardReset) this.FBCategoryID.setValue(raw_ingredient.fb_category_id, { emitEvent: emitEvent })
    if ('product' in raw_ingredient) this.product = new Product(Object.assign(raw_ingredient?.product || {}, { ingredient_id: this.id }))
  }

  createAllergensFromArray(allergen_list: IAllergen[]): void {
    this.allergens = requiredAllergens.map((required_allergen) => {
      return new Allergen(allergen_list.find((allergen) => allergen.code == required_allergen.code) || required_allergen)
    })
  }

  updateAllergensFromArray(allergen_list: IAllergen[]): void {
    this.allergens.forEach((allergen) => {
      const matched_allergen = allergen_list.find((updated_allergen) => updated_allergen.code == allergen.code)
      if (matched_allergen) allergen.containment.setValue(matched_allergen.containment == 'undeclared' ? null : matched_allergen?.containment?.toLowerCase(), { emitEvent: false })
    })
  }

  public loadPotentialDuplicates(language: string): Observable<RawIngredient[]> {
    return this.rawIngredientsService.loadCustomProductsWithExactName(this.names[language].value).pipe(
      tap((products) => {
        this.potential_duplicates = products
      })
    )
  }

  // Getters
  get duplicate(): RawIngredient {
    return this.potential_duplicates?.find((raw_ingredient) => raw_ingredient.id != this.id && JSON.stringify(raw_ingredient.sortedTags.map((tag) => tag.asDict)) == JSON.stringify(this.sortedTags.map((tag) => tag.asDict)))
  }

  get changed(): boolean {
    return this.saved_ingredient != JSON.stringify(this.asDict)
  }

  get changedKeys(): string[] {
    return Object.keys(this.asDict).filter((key) => JSON.stringify(this.asDict[key]) != JSON.stringify(JSON.parse(this.saved_ingredient)[key]))
  }
  get unSavedCustom(): boolean {
    return !this.id && this.type == 'custom'
  }
  get unchangedCustom(): boolean {
    return this.changedKeys.find((changedKey) => !['id', 'type', 'ingredient'].includes(changedKey)) == undefined && !this.id && this.type == 'custom' && (!this.sourceIngredientId.value || this.sourceIngredientId.value == JSON.parse(this.saved_ingredient).id)
  }

  get filtered_nutrition(): NutritionFact[] {
    return this.nutrition.filter((nutrition_fact) => nutrition_fact.preparation.value == this.nutrition_base_preparation.value && nutrition_fact.base_unit.value == this.nutrition_base_unit.value && nutrition_fact.base_value.value == this.nutrition_base_value.value)
  }

  isNameTranslated(language: string) {
    return this.names[language].value ? true : false
  }

  public get usedTags(): Tag[] {
    return this.tags.filter((tag) => tag.id)
  }

  public get sortedTags(): Tag[] {
    return this.usedTags.sort((a, b) => {
      return ingredientTagSorting[a.id] <= ingredientTagSorting[b.id] ? -1 : 1
    })
  }

  public hasTag(tag: Tag): boolean {
    return this.tags.find((addedTag) => addedTag.id == tag.id) != undefined
  }
  public addTag(tag: Tag): void {
    this.tags.push(tag)
  }
  public removeTag(tag: Tag): void {
    const index: number = this.tags.findIndex((added_tag) => added_tag.id == tag.id)
    if (index >= 0) {
      this.tags.splice(index, 1)
    }
  }

  nutrition_fact_with_type_code_value(type_code_value: string): NutritionFact {
    return this.nutrition.find(
      (nutrition_fact) =>
        nutrition_fact.nutrition_type_code_value == type_code_value &&
        nutrition_fact.base_unit.value == this.nutrition_base_unit.value &&
        nutrition_fact.preparation.value == this.nutrition_base_preparation.value &&
        nutrition_fact.base_value.value == this.nutrition_base_value.value
    )
  }

  allergen_with_code(allergen_code: string): any {
    return this.allergens.find((allergen) => allergen.code == allergen_code)
  }

  _createNutritionFactsFromArray(nutrition_facts_list: INutritionFact[]): NutritionFact[] {
    return required_nutrition_facts.map((required_nutrition_fact) => {
      const match = nutrition_facts_list.find(
        (nutrition_fact) =>
          nutrition_fact.nutrition_type_code_value == required_nutrition_fact.nutrition_type_code_value &&
          ((required_nutrition_fact.nutrition_type_code_value == 'ENER-' && nutrition_fact.unit == 'E14') || required_nutrition_fact.nutrition_type_code_value != 'ENER-') &&
          nutrition_fact.base_unit == this.nutrition_base_unit.value &&
          (nutrition_fact.preparation || 'UNPREPARED') == this.nutrition_base_preparation.value &&
          nutrition_fact.base_value == this.nutrition_base_value.value
      )
      return new NutritionFact(match, required_nutrition_fact)
    })
  }

  // CRUD operations
  restore(): void {
    this.patchValue(JSON.parse(this.saved_ingredient), false, true)
  }

  public convertToCustom(): void {
    if (this.FBCategoryID.value == JSON.parse(this.saved_ingredient).fb_category_id) {
      this.sourceIngredientId.setValue(this.id, { emitEvent: false })
      this.sourceIngredientNames = JSON.parse(this.saved_ingredient).names
    }
    this.id = undefined
    this.type = 'custom'
  }

  public selectSourceIngredient(sourceIngredient: RawIngredient): void {
    this.sourceIngredientId.setValue(sourceIngredient.id)
    this.sourceIngredientNames = sourceIngredient.names.as_dict
    const sourceIngredientKeys = Object.fromEntries(['co2', 'allergens', 'nutrition', 'fb_category_id'].map((key) => [[key], sourceIngredient.asDict?.[key]]))
    this.patchValue(sourceIngredientKeys, false)
  }

  public removeSourceIngredient(): void {
    this.sourceIngredientId.setValue(null)
    this.sourceIngredientNames = undefined
    this.co2.setValue(null)
    const sourceIngredientKeys = Object.fromEntries(['allergens', 'nutrition', 'fb_category_id'].map((key) => [[key], JSON.parse(this.saved_ingredient)?.[key]]))
    this.patchValue(sourceIngredientKeys, false)
  }

  saveIngredientObject() {
    this.saved_ingredient = JSON.stringify(this.asDict)
  }

  save(): Observable<RawIngredient> {
    this.saving = true
    return this.rawIngredientsService.upsertRawIngredient(this)
  }

  // JSON objects
  public get asDict(): IRawIngredient {
    return {
      id: this.id,
      names: this.names.as_dict,
      co2: this.co2.value,
      allergens: this.allergens.map((allergen) => allergen.as_dict),
      nutrition: this.nutrition.map((nutrition_fact) => nutrition_fact.as_dict),
      default_shrinkage: this.default_shrinkage,
      provider: this.provider.value,
      product_number: this.product_number.value,
      price: this.price.value,
      tags: this.tags.map((tag) => tag.asDict),
      gtin: this.type == 'generic' ? this.gtins : this.gtin.value,
      type: this.type,
      source: this.source,
      ingredient: this._sourceIngredientDict,
      fb_category_id: this.FBCategoryID.value,
      accessibility: this.accessibility.value,
      organization_id: this.organization_id,
      subsidiary_id: this.subsidiary_id,
      user_id: this.user_id,
      created: this.created,
      updated: this.updated,
      search_name: this.search_name
    }
  }
  public get asRecipeIngredientDict(): IRawIngredient {
    return Object.assign(this.asDict, {
      unit: this.unit.value,
      shrinkage: this.func.roundString(this.shrinkage.value.toString()),
      preparation_factor: this.func.roundString(this.preparation_factor.value.toString()),
      netto_factor: this.func.roundString(this.netto_factor.value.toString()),
      in_stock: this.in_stock.value,
      product: this.product.id ? { id: this.product.id } : undefined
    })
  }

  public get asMenuDishIngredientDict(): IRawIngredient {
    return Object.assign(this.asRecipeIngredientDict, { product: this.product.id ? this.product.asDict : undefined })
  }

  private get _sourceIngredientDict(): any {
    if (this.sourceIngredientId.value) {
      return {
        id: this.sourceIngredientId.value,
        names: this.sourceIngredientNames,
        co2: this.co2.value
      }
    } else return undefined
  }

  private _listenForNettoFactorChanges(): Observable<number> {
    return this.netto_factor.valueChanges.pipe(
      tap(() => {
        this.preparation_factor.setValue(round(roundString(this.netto_factor.value?.length ? this.netto_factor.value : '1') / roundString(this.shrinkage.value), 2).toString(), { emitEvent: false })
      })
    )
  }

  private _listenForShrinkageAndPreparationFactorChanges(): Observable<number> {
    return merge(this.shrinkage.valueChanges, this.preparation_factor.valueChanges).pipe(
      tap(() => {
        this.netto_factor.setValue(round(roundString(this.shrinkage.value) * roundString(this.preparation_factor.value), 2).toString(), { emitEvent: false })
      })
    )
  }
}
