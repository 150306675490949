<ng-container *ngIf="organizations$ | async as organizations">
  <mat-form-field class="text-xs min-w-56 w-72 max-w-72">
    <mat-label>Lokation</mat-label>
    <mat-select #locationSelector [formControl]="selection" (selectionChange)="onSelectionChange()" (optionsScroll)="onScroll()">
      <mat-select-trigger>{{ locationNameForID() }}</mat-select-trigger>
      <mat-option class="!h-0" [value]="selection.value"></mat-option>

      <mat-option (click)="$event.stopPropagation()" class="!sticky !h-12 top-0 bg-white border-b border-solid border-gray-100 z-10">
        <input (click)="$event.stopPropagation()" (keydown)="$event.stopPropagation()" autocomplete="off" #customerSearchInput lang="en" [formControl]="customerSearchControl" type="text" matInput placeholder="Search for locations" />
      </mat-option>

      <mat-option [value]="null">-- Ingen --</mat-option>

      <ng-container *ngFor="let organization of organizations | organizationFilterPipe : customerSearchControl.value; let organizationIndex = index">
        <mat-option [value]="organization.id" class="!sticky !h-12 top-12 bg-white border-b border-solid border-gray-100" [ngStyle]="{ 'z-index': subsidiariesBeforeIndex(organizationIndex) + organizationIndex }" disabled>
          <div class="flex flex-row items-center justify-between pl-0">
            <label class="!h-12 flex flex-row items-center text-xs cursor-default text-gray-200 italic" [innerHTML]="organization.name.value | boldSearchQuery : customerSearchInput.value"></label>
          </div>
        </mat-option>
        <ng-container *ngFor="let subsidiary of organization.subsidiaries | subsidiaryFilterPipe : customerSearchControl.value; let subidiaryIndex = index">
          <mat-option [value]="subsidiary.id" class="!sticky !h-12 top-24 bg-white border-b border-solid border-gray-100" [ngStyle]="{ 'z-index': subsidiariesBeforeIndex(organizationIndex) + subidiaryIndex }" disabled>
            <div class="flex flex-row items-center justify-between pl-6">
              <label class="!h-12 flex flex-row items-center text-xs cursor-default text-gray-200 italic" [innerHTML]="subsidiary.name.value | boldSearchQuery : customerSearchInput.value"></label>
            </div>
          </mat-option>
          <mat-option *ngFor="let location of subsidiary.locations | locationFilterPipe : customerSearchControl.value" [value]="location.id" [disabled]="!location.active.value">
            <div class="flex flex-row items-center justify-between pl-8">
              <label class="!h-12 flex flex-row items-center text-xs cursor-pointer" [innerHTML]="location.name.value + (!location.active.value ? ' (inactive)' : '') | boldSearchQuery : customerSearchInput.value"></label>
            </div>
          </mat-option>
        </ng-container>
      </ng-container>
      <mat-option *ngIf="loadingOrganizations" class="!cursor-default">
        <div class="flex items-center">
          <mat-spinner class="!inline-block float-left" color="primary" diameter="19"></mat-spinner>
          <label class="text-xs leading-[19px] p-2">Søger efter kunder ...</label>
        </div>
      </mat-option>
    </mat-select>
  </mat-form-field>
</ng-container>
