import { Names } from '../names/names.model'
import { ITag } from '../../global.models'
import { Observable, catchError, concatMap, filter, first, from, map, of } from 'rxjs'
import { ETagCategory } from './tag.service'

export class Tag {
  public readonly id: string
  public readonly category: ETagCategory
  public readonly names: Names
  public readonly icon$: Observable<string>

  constructor(private _tag?: ITag) {
    this.id = _tag?.id
    this.category = _tag?.category as ETagCategory
    this.names = new Names(_tag?.names)
    this.icon$ = this._fetchIcon()
  }
  public get asDict(): ITag {
    return {
      id: this.id,
      category: this.category,
      names: this.names.as_dict
    }
  }

  public iconPath(extension: string = '.png'): string {
    return 'assets/ingredient_icons/' + this.id + extension
  }
  private _fetchIcon(): Observable<string> {
    return from(['.png', '.svg']).pipe(
      concatMap((ext) =>
        this._checkImageExists(this.iconPath(ext)).pipe(
          map(() => this.iconPath(ext)),
          catchError(() => of(null))
        )
      ),
      filter((iconPath): iconPath is string => !!iconPath),
      first()
    )
  }

  private _checkImageExists(url: string): Observable<void> {
    return new Observable<void>((observer) => {
      const img = new Image()
      img.onload = () => {
        observer.next()
        observer.complete()
      }
      img.onerror = () => {
        observer.error('Image not found')
      }
      img.src = url
    })
  }
}
