import { Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { catchError } from 'rxjs/operators'
import { IAccessRole, IDomain } from '../global.models'
import { AdminApiService } from './admin-api.service'

@Injectable({
  providedIn: 'root'
})
export class AuthorizationService {
  constructor(private _adminApiService: AdminApiService) {}

  loadAccessRoles(): Observable<IAccessRole[]> {
    return this._adminApiService.getAllAccessRoles().pipe(catchError(() => []))
  }

  loadDomains(): Observable<IDomain[]> {
    return this._adminApiService.getDomains().pipe(catchError(() => []))
  }
}
