import { Display } from '../display/display.model'
import { IDisplayDish } from '../../global.models'
import { Tracking } from '../tracking/tracking.model'
import { ESLLabel } from '../esl-models/esl-labels/esl-label.model'
import { MenuDish } from '../menu-dish/menu-dish.model'

export class DisplayDish {
  public id: string
  public display: Display

  constructor(public displayDish?: IDisplayDish, public ESLLabel?: ESLLabel, public tracking?: Tracking, public menuDish?: MenuDish) {
    this.id = displayDish?.id
    this.display = new Display(tracking.displayTemplateId, tracking.language, null, null)
  }

  public get asDict(): IDisplayDish {
    return {
      display: this.display.as_dict,
      esl_label_code: this.ESLLabel?.labelCode?.value,
      menu_dish_id: this.menuDish?.id,
      index: this.ESLLabel?.index?.value
    }
  }

  public get valid(): boolean {
    return Object.keys(this).find((key) => this[key]?.invalid == true) == undefined && this.display.valid
  }
}
