import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { Observable, of, catchError, map, tap } from 'rxjs'
import { RestApiService } from '../../services/rest-api.service'
import { MenuDish } from '../menu-dish/menu-dish.model'
import { IShoppingList } from './shopping-list.interface'
import { CommerceAPIService } from '../../services/commerce-api.service'
import { UserService } from '../user/user.service'
import { ShoppingList } from './shopping-list.model'
import moment from 'moment'
import { CacheMapService } from '../../services/caching/cache-map.service'
import { IShoppingListItem } from './shopping-list-item.interface'
import { RawIngredient } from '../raw-ingredient/raw-ingredient.model'

@Injectable({
  providedIn: 'root'
})
export class ShoppingListService {
  constructor(@Inject(LOCALE_ID) public language: string, private _userService: UserService, private restApiService: RestApiService, private _commerceAPIService: CommerceAPIService, private _cacheMapService: CacheMapService) {}

  public getShoppingListsForDateRange(startDate: moment.Moment, endDate: moment.Moment): Observable<ShoppingList[]> {
    return this.restApiService.httpRequest('shopping_lists', 'get', { start_date: startDate.format('YYYY-MM-DD'), end_date: endDate.format('YYYY-MM-DD') }).pipe(
      catchError((error) => {
        console.error('Error fetching shopping lists:', error)
        return of([])
      }),
      map((shoppingLists: IShoppingList[]) => shoppingLists.map((shoppingList) => new ShoppingList(shoppingList)).sort((a, b) => a.date.value.diff(b.date.value)))
    )
  }

  public getShoppingListsForMenuDishes(menuDishes?: MenuDish[]): Observable<ShoppingList[]> {
    return this.restApiService.httpRequest('shopping_list_items', 'get', { menu_dish_ids: menuDishes.map((menuDish) => menuDish.id).join(',') }).pipe(
      catchError((error) => {
        console.error('Error fetching shopping lists:', error)
        return of([])
      }),
      map((shoppingLists: IShoppingList[]) => shoppingLists.map((shoppingList) => new ShoppingList(shoppingList)))
    )
  }

  public getDropDates(): Observable<number[]> {
    return this._commerceAPIService.httpRequest('drop_dates', 'get', { customerID: this._userService.user?.customerID }).pipe(
      catchError((error) => {
        console.error('Error fetching drop dates:', error)
        return of([])
      })
    )
  }

  public createShoppingList(date: moment.Moment, items: { ingredient: RawIngredient; menuDish: MenuDish }[]): Observable<ShoppingList> {
    return this.restApiService
      .httpRequest('shopping_lists', 'post', {
        date: date.format('YYYY-MM-DD'),
        items: items.map((item) => {
          return {
            menu_dish_id: item.menuDish.id,
            ingredient_id: item.ingredient.id,
            product_id: item.ingredient.product.id,
            single_amount: item.ingredient.product.packaging.singleAmountSelection.value,
            package_amount: item.ingredient.product.packaging.colliAmountSelection.value
          }
        })
      })
      .pipe(
        catchError((error) => {
          console.error('Error saving shopping list:', error)
          return of({})
        }),
        tap(() => this._cacheMapService.deleteCacheEntryForURL('shopping_list')),
        map((shoppingList) => new ShoppingList(shoppingList))
      )
  }

  public upsertItemsToShoppingList(shoppingListId: string, items: IShoppingListItem[]): Observable<IShoppingListItem[]> {
    return this.restApiService.httpRequest('shopping_list_items', 'post', { shopping_list_id: shoppingListId, shopping_list_items: items }).pipe(
      catchError((error) => {
        console.error('Error upserting shopping list items:', error)
        return of({})
      }),
      tap(() => this._cacheMapService.deleteCacheEntryForURL('shopping_list'))
    )
  }

  public deleteShoppingList(shoppingListId: string): Observable<any> {
    return this.restApiService.httpRequest('shopping_lists', 'delete', undefined, undefined, shoppingListId).pipe(
      catchError((error) => {
        console.error('Error deleting shopping list:', error)
        return of(null)
      })
    )
  }
}
