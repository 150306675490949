import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { Observable, Subject, catchError, map, merge, of, scan, tap } from 'rxjs'
import { TrackingTemplate } from './tracking-template.model'
import { RestApiService } from '../../services/rest-api.service'
import { SubsidiaryService } from '../subsidiary/subsidiary.service'
import { ITrackingGroupTemplate, ITrackingTemplate } from '../../global.models'
import { TrackingGroupTemplate } from './tracking-group-template.model'

@Injectable({
  providedIn: 'root'
})
export class TrackingTemplatesService {
  availableTrackingTemplateTypes = []
  allTrackingTemplates: TrackingTemplate[] = []
  filteredTrackingTemplates: TrackingTemplate[] = []
  public allTrackingGroupTemplates: TrackingGroupTemplate[] = []
  activeTrackingTemplatesByTypeSorted: any[] = []

  private _newTrackingGroupTemplates$: Subject<any> = new Subject<any>()

  constructor(@Inject(LOCALE_ID) public language: string, private restApiService: RestApiService, private subsidiaryService: SubsidiaryService) {}

  public trackingTemplatesForMenuTemplateId(menuTemplateId: string, active?: boolean): TrackingTemplate[] {
    return this.allTrackingTemplates.filter((trackingTemplate) => trackingTemplate.menuTemplateId.value == menuTemplateId && (active == undefined || trackingTemplate.active.value == active))
  }
  public trackingTemplatesForLocation(locationId: string, type?: string): TrackingTemplate[] {
    return this.allTrackingTemplates.filter((trackingTemplate) => trackingTemplate.locationId.value == locationId && trackingTemplate.type.value == type)
  }
  public trackingTemplateWithId(trackingTemplateId: string): TrackingTemplate {
    return this.allTrackingTemplates.find((trackingTemplate) => trackingTemplate.id == trackingTemplateId)
  }
  public trackingGroupTemplateWithId(trackingGroupTemplateId: string): TrackingGroupTemplate {
    return this.allTrackingGroupTemplates.find((trackingGroupTemplate) => trackingGroupTemplate.id == trackingGroupTemplateId)
  }

  public getTrackingGroupTemplates(menuTemplateID?: string, active?: boolean, active_trackings?: boolean, output?: string): Observable<TrackingGroupTemplate[]> {
    let params = {
      fields: 'all',
      language: this.language,
      subsidiary_id: this.subsidiaryService.subsidiary.id,
      menu_template_id: menuTemplateID
    }
    if (active != undefined) params['active'] = active
    if (active_trackings != undefined) params['active_trackings'] = active_trackings

    const loadPipe$ = this.restApiService.getTrackingGroupTemplates(params).pipe(catchError(() => of([])))

    return merge(loadPipe$, this._newTrackingGroupTemplates$).pipe(
      scan((trackingGroupTemplates: any[], newTrackingGroupTemplates: any[]) => {
        const map = new Map()
        trackingGroupTemplates.forEach((trackingGroupTemplate) => map.set(trackingGroupTemplate.id, trackingGroupTemplate))
        newTrackingGroupTemplates.forEach((trackingGroupTemplate) => map.set(trackingGroupTemplate.id, { ...map.get(trackingGroupTemplate.id), ...trackingGroupTemplate }))
        return Array.from(map.values())
      }),
      tap((trackingGroupTemplates: ITrackingGroupTemplate[]) => {
        trackingGroupTemplates.forEach((trackingGroupTemplate) => {
          this.addToTrackingGroupTemplates(trackingGroupTemplate)
          trackingGroupTemplate.tracking_templates?.forEach((trackingTemplate) => this.addToTrackingTemplates(Object.assign(trackingTemplate, { tracking_group_template_id: trackingGroupTemplate.id })))
        })
        this.filteredTrackingTemplates = this.allTrackingTemplates.filter(
          (trackingTemplate) =>
            trackingGroupTemplates.map((trackingGroupTemplate) => trackingGroupTemplate.id).includes(trackingTemplate.trackingGroupTemplateId.value) &&
            (active_trackings == undefined || trackingTemplate.active.value) &&
            (menuTemplateID == undefined || trackingTemplate.menuTemplateId.value == menuTemplateID)
        )
      }),
      map((trackingGroupTemplates) => {
        return trackingGroupTemplates
          .map((trackingGroupTemplate) => this.trackingGroupTemplateWithId(trackingGroupTemplate.id))
          .filter((trackingGroupTemplate) => {
            return active == undefined && active_trackings == undefined
              ? true
              : active && active_trackings
              ? trackingGroupTemplate.active.value && trackingGroupTemplate.activeTrackingTemplates?.length > 0
              : active && !active_trackings
              ? trackingGroupTemplate.active.value
              : !trackingGroupTemplate.active.value
          })
          .sort((a, b) => (a.name.value > b.name.value ? 1 : -1))
      }),
      map((trackingGroupTemplates) => {
        if (output == 'nested') return this._generateTemplatesByActiveArray(trackingGroupTemplates)
        else return trackingGroupTemplates
      })
    )
  }

  public addToTrackingTemplates(trackingTemplate: ITrackingTemplate): void {
    if (!this.trackingTemplateWithId(trackingTemplate.id)) this.allTrackingTemplates.push(new TrackingTemplate(trackingTemplate))
    else this.trackingTemplateWithId(trackingTemplate.id).patchValues(trackingTemplate)
  }

  public addToTrackingGroupTemplates(trackingGroupTemplate: ITrackingGroupTemplate): void {
    if (!this.trackingGroupTemplateWithId(trackingGroupTemplate.id)) this.allTrackingGroupTemplates.push(new TrackingGroupTemplate(trackingGroupTemplate))
    else this.trackingGroupTemplateWithId(trackingGroupTemplate.id).patchValues(trackingGroupTemplate)
  }

  public saveTrackingTemplate(trackingTemplate: TrackingTemplate): Observable<ITrackingTemplate> {
    if (trackingTemplate.isNew) {
      return this.restApiService.createTrackingTemplate({ subsidiary_id: this.subsidiaryService.subsidiary.id }, trackingTemplate.asDict).pipe(
        catchError(() => of({})),
        tap((response) => this.addToTrackingTemplates(response))
      )
    } else
      return this.restApiService.updateTrackingTemplate({ subsidiary_id: this.subsidiaryService.subsidiary.id }, trackingTemplate.asDict).pipe(
        catchError(() => of({ inactivated: [], upserted: {} })),
        tap((response) => {
          if (response['inactivated']) this._newTrackingGroupTemplates$.next(response['inactivated'])
        }),
        map((response) => response['upserted']),
        tap((response) => this.addToTrackingTemplates(response))
      )
  }

  public saveTrackingGroupTemplate(trackingGroupTemplate: TrackingGroupTemplate, trackingTemplate?: TrackingTemplate): Observable<ITrackingGroupTemplate> {
    if (trackingGroupTemplate.isNew) {
      this.allTrackingGroupTemplates.push(trackingGroupTemplate)
      return this.restApiService.createTrackingGroupTemplate({ subsidiary_id: this.subsidiaryService.subsidiary.id }, Object.assign(trackingGroupTemplate.asDict, { tracking_templates: trackingTemplate ? [trackingTemplate.asDict] : [] })).pipe(
        tap((response) => this._newTrackingGroupTemplates$.next([response['upserted']].concat(response['inactivated']))),
        map((response) => response['upserted'])
      )
    } else
      return this.restApiService.updateTrackingGroupTemplate({ subsidiary_id: this.subsidiaryService.subsidiary.id }, Object.assign(trackingGroupTemplate.asDict, { tracking_templates: trackingTemplate ? [trackingTemplate.asDict] : [] })).pipe(
        tap((response) => this._newTrackingGroupTemplates$.next([response['upserted']].concat(response['inactivated']))),
        map((response) => response['upserted'])
      )
  }

  private _generateTemplatesByActiveArray(templates: any): any[] {
    return [true, false].map((active) => {
      return {
        label: active ? $localize`Aktive` : $localize`Inaktive`,
        templates: templates.filter((template) => template.active.value == active)
      }
    })
  }
}
