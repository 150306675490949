import { ElementRef } from '@angular/core'
import { FormControl } from '@angular/forms'

export function moveCell(event: KeyboardEvent, rowIndex: number, colIndex: number, rowCount: number, colCount: number, inputComponents: ElementRef[]): HTMLElement {
  const validKeyPresses = ['ArrowRight', 'ArrowLeft', 'ArrowUp', 'ArrowDown', 'Enter', 'Tab']
  if (!validKeyPresses.includes(event.key)) return

  let nextRowIndex: number = rowIndex
  let nextColIndex: number = colIndex
  if (event.key == 'ArrowRight' || event.key == 'Tab') {
    if (colIndex + 1 >= colCount) {
      nextColIndex = 0
      nextRowIndex = rowIndex + 1 >= rowCount ? 0 : rowIndex + 1
    } else nextColIndex += 1
  }
  if (event.key == 'ArrowLeft') {
    if (colIndex - 1 < 0) {
      nextColIndex = colCount - 1
      nextRowIndex = rowIndex - 1 < 0 ? rowCount - 1 : rowIndex - 1
    } else nextColIndex -= 1
  }
  if (event.key == 'ArrowUp') {
    if (rowIndex - 1 < 0) {
      nextRowIndex = rowCount - 1
      nextColIndex = colIndex - 1 < 0 ? colCount - 1 : colIndex - 1
    } else nextRowIndex -= 1
  }
  if (event.key == 'ArrowDown' || event.key == 'Enter') {
    if (rowIndex + 1 >= rowCount) {
      nextRowIndex = 0
      nextColIndex = colIndex + 1 >= colCount ? 0 : colIndex + 1
    } else nextRowIndex += 1
  }

  if (nextRowIndex != rowIndex || nextColIndex != colIndex) {
    const new_input_field: HTMLInputElement = inputComponents.find((inputComponent) => inputComponent.nativeElement.id == nextRowIndex + '_' + nextColIndex)?.nativeElement
    if (!new_input_field) return moveCell(event, nextRowIndex, nextColIndex, rowCount, colCount, inputComponents)
    new_input_field.focus()
    setTimeout(function () {
      new_input_field.select()
    }, 50)
    return new_input_field
  }
}

export function copyData(event: any, selectedRange: { row: number; column: number }[], formControls: FormControl[][]): string {
  event.preventDefault()
  const selectedRangeRows = [...new Set(selectedRange.map((cell) => cell.row))]
  const selectedRangeCols = [...new Set(selectedRange.map((cell) => cell.column))]
  return selectedRangeRows
    .map((row) => {
      return selectedRangeCols.map((column) => formControls[row][column].value).join('\t')
    })
    .join('\n')
}

export function pasteIntoCells(event: any, formControls: FormControl[][], selectedRange: { row: number; column: number }[]): void {
  event.preventDefault()
  const processedData: string[][] = _processClipBoardRangeData(event)
  const selectedRangeRows = new Set(selectedRange.map((cell) => cell.row)).size
  const selectedRangeCols = new Set(selectedRange.map((cell) => cell.column)).size
  const dataRows = processedData.length
  const dataCols = processedData[0].length
  const pasteRowIterations = Math.floor(selectedRangeRows / dataRows) || 1
  const pasteColIterations = Math.floor(selectedRangeCols / dataCols) || 1

  for (let rowIteration = 0; rowIteration < pasteRowIterations; rowIteration++) {
    for (let colIteration = 0; colIteration < pasteColIterations; colIteration++) {
      for (let i = 0; i < dataRows; i++) {
        for (let j = 0; j < processedData[i].length; j++) {
          formControls[i + rowIteration * dataRows][j + colIteration * dataCols].setValue(processedData[i][j])
        }
      }
    }
  }
}

function _processClipBoardRangeData(eventArgs) {
  let data
  const clData = 'clipboardData'

  // get clipboard data - from window.cliboardData for IE or from the original event's arguments.
  if (window[clData]) {
    window.event.returnValue = false
    data = window[clData].getData('text')
  } else {
    data = eventArgs[clData].getData('text/plain')
  }

  const pasteData = data.split('\n')
  for (let i = 0; i < pasteData.length; i++) {
    pasteData[i] = pasteData[i].split('\t')
    // Check if last row is a dummy row
    if (pasteData[pasteData.length - 1].length === 1 && pasteData[pasteData.length - 1][0] === '') {
      pasteData.pop()
    }
    // remove empty data
    if (pasteData.length === 1 && pasteData[0].length === 1 && (pasteData[0][0] === '' || pasteData[0][0] === '\r')) {
      pasteData.pop()
    }
  }
  return pasteData
}

export function scrollCellIntoView(selectedElement: HTMLElement, scrollContainer: ElementRef, innerColLeftOffset: number, innerColTopOffset: number): void {
  if (selectedElement) {
    let parentLevel = 0
    while (selectedElement.tagName != 'TR' && parentLevel < 10) {
      selectedElement = selectedElement.parentElement
      parentLevel++
    }

    if (selectedElement) {
      window.requestAnimationFrame(() => {
        scrollContainer.nativeElement.scrollTo({
          left: selectedElement.offsetLeft - innerColLeftOffset,
          top: selectedElement.offsetTop - innerColTopOffset,
          behavior: 'smooth'
        })
      })
    }
  }
}
