import { Injectable } from '@angular/core'
import { MsalBroadcastService, MsalInterceptorAuthRequest, MsalService } from '@azure/msal-angular'
import { EMPTY, Observable, Subscription, catchError, filter, of, switchMap, take, tap } from 'rxjs'
import { AccountInfo, AuthenticationResult, EventMessage, EventType, InteractionStatus } from '@azure/msal-browser'
import { ActivatedRoute, Router } from '@angular/router'
import { AdminApiService } from './admin-api.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import moment from 'moment'
import { DialogPopUp } from './dialog.service'
import { MatDialog } from '@angular/material/dialog'
import { UserService } from '../models/user/user.service'

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  public is_logged_in = false
  public impersonationToken: { token: string; exp: string; timeLeft?: number } = null
  private _subscriptions: Subscription[] = []
  private _isAuthenticationInProgress = false

  constructor(
    private authService: MsalService,
    private msalBroadcastService: MsalBroadcastService,
    private _route: ActivatedRoute,
    private _router: Router,
    private _adminApiService: AdminApiService,
    private _snackBar: MatSnackBar,
    private _dialog: MatDialog,
    private _userService: UserService
  ) {
    this._validateImpersonationToken()
  }

  ngOnDestroy() {
    this._subscriptions.forEach((subscription) => subscription.unsubscribe())
  }

  public get account() {
    let account: AccountInfo
    if (!!this.authService.instance.getActiveAccount()) {
      this.authService.getLogger().verbose('Interceptor - active account selected')
      account = this.authService.instance.getActiveAccount()
    } else {
      this.authService.getLogger().verbose('Interceptor - no active account, fallback to first account')
      account = this.authService.instance.getAllAccounts()[0]
    }
    return account
  }

  public subscribeToAuthService() {
    let loginSuccessSubscription: Subscription
    let acquireTokenSuccess: Subscription
    let acquireTokenFailure: Subscription

    this.checkAccount()

    this.authService.handleRedirectObservable().subscribe((response) => {
      if (response) {
        this.authService.instance.setActiveAccount(response.account)
        const urlWithoutHash = window.location.origin + window.location.pathname + window.location.search
        window.history.replaceState({}, document.title, urlWithoutHash)
        if (localStorage.getItem('redirectQueryParams')) {
          this._router.navigate([localStorage.getItem('route')], {
            relativeTo: this._route,
            queryParams: JSON.parse(localStorage.getItem('redirectQueryParams')),
            queryParamsHandling: 'merge'
          })
          localStorage.removeItem('route')
          localStorage.removeItem('redirectQueryParams')
        }
      }
    })

    loginSuccessSubscription = this.msalBroadcastService.msalSubject$.pipe(filter((msg: EventMessage) => msg.eventType === EventType.LOGIN_SUCCESS)).subscribe((result: EventMessage) => {
      this.checkAccount()
      this.authService.instance.setActiveAccount(result.payload['account'])
    })

    acquireTokenSuccess = this.msalBroadcastService.msalSubject$.pipe(filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_SUCCESS)).subscribe((result: EventMessage) => {
      if (!this.authService.instance.getActiveAccount()) this.authService.instance.setActiveAccount(result.payload['account'])
    })

    acquireTokenFailure = this.msalBroadcastService.msalSubject$.pipe(filter((msg: EventMessage) => msg.eventType === EventType.ACQUIRE_TOKEN_FAILURE)).subscribe((result: EventMessage) => {
      this._logoutActiveAccount()
    })

    this.msalBroadcastService.inProgress$.pipe(filter((status: InteractionStatus) => status === InteractionStatus.None)).subscribe((result) => {
      if (this.authService.instance.getActiveAccount()) {
        this.authService.instance
          .acquireTokenSilent({
            scopes: ['openid'],
            account: this.authService.instance.getActiveAccount()
          })
          .then((result) => {
            this.checkAccount()
          })
      }
      this.checkAccount()
    })

    this._subscriptions.push(loginSuccessSubscription)
    this._subscriptions.push(acquireTokenSuccess)
    this._subscriptions.push(acquireTokenFailure)
  }

  public checkAccount() {
    this.is_logged_in = this.authService.instance.getActiveAccount() != undefined
  }

  public login() {
    if (this._isAuthenticationInProgress) return
    try {
      this.msalBroadcastService.inProgress$
        .pipe(
          filter((status) => {
            return status === InteractionStatus.None
          })
        )
        .subscribe(() => {
          this._isAuthenticationInProgress = true
          localStorage.setItem('route', window.location.pathname.slice(3))
          localStorage.setItem('redirectQueryParams', JSON.stringify(this._route.snapshot.queryParams))
          this.authService.loginRedirect({ account: this.authService.instance.getActiveAccount(), scopes: ['openid'] })
        })
    } catch (error) {
      this._isAuthenticationInProgress = false
    } finally {
      this._isAuthenticationInProgress = false
    }
  }

  public logout() {
    this.authService.logoutRedirect({ postLogoutRedirectUri: window.location.href })
  }

  public acquireToken(authRequest, scopes: string[], account: AccountInfo): Observable<AuthenticationResult> {
    if (this._isAuthenticationInProgress) return of(null)

    // Note: For MSA accounts, include openid scope when calling acquireTokenSilent to return idToken
    return this.authService.acquireTokenSilent({ ...authRequest, scopes, account }).pipe(
      catchError(() => {
        this.authService.getLogger().error('Interceptor - acquireTokenSilent rejected with error. Invoking interaction to resolve.')
        return this.msalBroadcastService.inProgress$.pipe(
          take(1),
          switchMap((status: InteractionStatus) => {
            if (status === InteractionStatus.None) {
              return this.acquireTokenInteractively(authRequest, scopes)
            }

            return this.msalBroadcastService.inProgress$.pipe(
              filter((status: InteractionStatus) => status === InteractionStatus.None),
              take(1),
              switchMap(() => this.acquireToken(authRequest, scopes, account))
            )
          })
        )
      }),
      switchMap((result: AuthenticationResult) => {
        if (!result.accessToken) {
          this.authService.getLogger().error('Interceptor - acquireTokenSilent resolved with null access token. Known issue with B2C tenants, invoking interaction to resolve.')
          return this.msalBroadcastService.inProgress$.pipe(
            filter((status: InteractionStatus) => status === InteractionStatus.None),
            take(1),
            switchMap(() => this.acquireTokenInteractively(authRequest, scopes))
          )
        }
        return of(result)
      })
    )
  }

  public acquireTokenInteractively(authRequest: MsalInterceptorAuthRequest, scopes: string[]): Observable<AuthenticationResult> {
    if (this._isAuthenticationInProgress) return of(null)

    this.authService.getLogger().verbose('Interceptor - error acquiring token silently, acquiring by redirect')
    const redirectStartPage = window.location.href
    this.authService.acquireTokenRedirect({
      ...authRequest,
      scopes,
      redirectStartPage
    })
    return EMPTY
  }

  public impersonateUser(userId: string, extend: boolean = false): Observable<string> {
    const params = { impersonate_oid: userId }
    return this._adminApiService.httpRequest('admin_impersonation_token', 'get', null, params).pipe(
      catchError(() => {
        return of(null)
      }),
      tap((response) => {
        if (!extend) {
          this._snackBar.open($localize`Skifter bruger ...`, $localize`Luk`, { duration: 5000 })
          setTimeout(() => {
            sessionStorage.setItem('impersonation_token', JSON.stringify(response))
            window.location.reload()
          }, 1000)
        } else {
          sessionStorage.setItem('impersonation_token', JSON.stringify(response))
          this._validateImpersonationToken()
        }
      })
    )
  }

  private _logoutActiveAccount() {
    if (this.authService.instance.getAllAccounts().length > 1) {
      this.authService.instance.logoutRedirect({
        account: this.authService.instance.getActiveAccount(),
        onRedirectNavigate: () => {
          this.authService.instance.setActiveAccount(this.authService.instance.getAllAccounts()[0])
          return false
        }
      })
    } else this.authService.logoutRedirect({ postLogoutRedirectUri: window.location.href })
  }

  private _validateImpersonationToken(): void {
    let impersonationToken = JSON.parse(sessionStorage.getItem('impersonation_token'))
    if (impersonationToken) {
      impersonationToken['timeLeft'] = moment.utc(impersonationToken.exp).diff(moment(), 'seconds')
      if (impersonationToken['timeLeft'] > 0) {
        const interval = setInterval(() => {
          if (impersonationToken['timeLeft'] > 0) {
            impersonationToken['timeLeft']--
          } else {
            const dialogRef = this._extendImpersonationDialogPopUp()
            dialogRef.afterClosed().subscribe((result) => {
              if (result === 0) {
                impersonationToken = null
                this.impersonationToken = null
                sessionStorage.removeItem('impersonation_token')
                window.location.reload()
              }
              if (result === 1) {
                this.impersonateUser(this._userService.user.id.value, true).subscribe()
              }
            })
            clearInterval(interval)
          }
        }, 1000)
        this.impersonationToken = impersonationToken
      }
    }
  }

  private _extendImpersonationDialogPopUp(): any {
    return this._dialog.open(DialogPopUp, {
      data: {
        title: $localize`Fortsæt med at vise siden som ` + this._userService.user.first_name.value + ' ' + this._userService.user.last_name.value + '?',
        content: $localize`Klik fortsæt for at forlænge din session med yderligere 30 minutter.`,
        option1_message: $localize`Stop`,
        option2_message: $localize`Fortsæt`,
        option1_value: 0,
        option2_value: 1
      }
    })
  }
}
