import { Overlay, OverlayConfig, OverlayRef } from '@angular/cdk/overlay'
import { ComponentPortal, PortalInjector } from '@angular/cdk/portal'
import { ComponentRef, Inject, Injectable, Injector, LOCALE_ID, ViewContainerRef } from '@angular/core'
import { INJECTION_DATA } from './injection.service'

@Injectable({
  providedIn: 'root'
})
export class OverlayService {
  constructor(private injector: Injector, @Inject(LOCALE_ID) public language: string, public overlay: Overlay) {}

  onCloseView = (overlayRef) => {
    overlayRef.dispose()
  }

  public openComponentInPopUp(
    viewContainerRef: ViewContainerRef,
    component: any,
    data: any,
    classes: string[] | string = ['max-w-[80%]', 'max-h-[80%]', 'min-w-[500px]'],
    backdropClickCallBack: any = this.onCloseView,
    closeViewCallBack: any = this.onCloseView,
    backdropClass?: string
  ): OverlayRef {
    let config = new OverlayConfig()
    config.positionStrategy = this.overlay.position().global().centerHorizontally().centerVertically()
    config.hasBackdrop = true
    config.scrollStrategy = this.overlay.scrollStrategies.block()
    if (backdropClass) config.backdropClass = backdropClass

    let overlayRef: OverlayRef = this.overlay.create(config)
    overlayRef.addPanelClass(classes)

    const compRef: ComponentRef<any> = overlayRef.attach(new ComponentPortal(component, viewContainerRef, this._createInjector(data)))
    overlayRef.backdropClick().subscribe((value) => backdropClickCallBack(overlayRef, compRef.instance, value))
    compRef.instance.closeView.subscribe((value) => closeViewCallBack(overlayRef, compRef.instance, value))

    return overlayRef
  }

  private _createInjector(dataToPass): PortalInjector {
    const injectionTokens = new WeakMap()
    injectionTokens.set(INJECTION_DATA, dataToPass)
    return new PortalInjector(this.injector, injectionTokens)
  }
}
