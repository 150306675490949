import { FoodopLibModule } from '../../foodop-lib.module'
import { Allergen } from '../allergen/allergen.model'
import { NutritionFact } from '../nutrition/nutrition-fact.model'
import { IName } from '../../global.models'
import { AllergensService } from '../allergen/allergens.service'
import { NutritionService } from '../nutrition/nutrition.service'
import { TagsService } from '../tag/tag.service'
import { IProduct } from './product.interface'
import { Packaging } from './packaging.model'
import { Tag } from '../tag/tag.model'
import { FormControl } from '@angular/forms'

export class Product {
  public id: string
  public type: string
  public FBCategoryId: string
  public ingredientId: string
  public productNumber: string
  public names: IName
  public description: IName
  public nutrition: NutritionFact[]
  public allergens: Allergen[]
  public productUrl: string
  public brand: string
  public vendorId: string
  public vendorProductId: string
  public tags: string[]
  public packaging: Packaging = new Packaging()
  public isIgnored: FormControl
  private _images: { rank: number; uri: string }[]

  public imageUrl: string

  private _allergensService: AllergensService
  private _nutritionService: NutritionService
  private _tagsService: TagsService

  constructor(public product: IProduct) {
    this._allergensService = FoodopLibModule.injector.get(AllergensService)
    this._nutritionService = FoodopLibModule.injector.get(NutritionService)
    this._tagsService = FoodopLibModule.injector.get(TagsService)

    this.patchValues(product)
    this.imageUrl = this._images?.at(0)?.uri
  }

  public get asDict(): IProduct {
    return {
      id: this.id,
      ingredient_id: this.ingredientId,
      type: this.type,
      fb_category_id: this.FBCategoryId,
      product_number: this.productNumber,
      names: this.names,
      description: this.description,
      nutrition: this.product.nutrition?.length ? this.nutrition.map((nutrition) => nutrition.as_dict) : undefined,
      allergens: this.product.allergens?.length ? this.allergens.map((allergen) => allergen.as_dict) : undefined,
      images: this._images,
      product_url: this.productUrl,
      brand: this.brand,
      vendor_id: this.vendorId,
      vendor_product_id: this.vendorProductId,
      tags: this.tags,
      is_ignored: this.isIgnored.value
    }
  }

  public get productTags(): Tag[] {
    return this._tagsService.tags.filter((tag) => this.tags.includes(tag.id))
  }

  public patchValues(product: IProduct): void {
    this.id = product.id
    this.type = product.type
    this.FBCategoryId = product.fb_category_id
    this.ingredientId = product.ingredient_id
    this.productNumber = product.product_number
    this.names = product.names
    this.description = product.description
    this.nutrition = this._nutritionService.createNutritionFactsFromArray(product.nutrition)
    this.allergens = this._allergensService.createAllergensFromArray(product.allergens)
    this._images = product.images
    this.productUrl = product.product_url
    this.brand = product.brand
    this.vendorId = product.vendor_id
    this.vendorProductId = product.vendor_product_id
    this.tags = product.tags || []
    if (product.packaging) this.packaging.patchValues(product.packaging)
    this.isIgnored = new FormControl(product.is_ignored)
  }
}
