import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { Observable, of } from 'rxjs'
import { catchError, map, tap } from 'rxjs/operators'
import { RestApiService } from '../../services/rest-api.service'
import { ESLGateway } from './esl-gateway/esl-gateway.model'
import { IESLGateway, IESLLabel } from '../../global.models'
import { ESLLabel } from './esl-labels/esl-label.model'
import { LocationsService } from '../location/locations.service'
import { SubsidiaryService } from '../subsidiary/subsidiary.service'
import { MatSnackBar } from '@angular/material/snack-bar'

@Injectable({
  providedIn: 'root'
})
export class ESLService {
  constructor(@Inject(LOCALE_ID) public language: string, private _restApiService: RestApiService, private _subsidiaryService: SubsidiaryService, private _locationsService: LocationsService, private _snackBar: MatSnackBar) {}

  public getESLGateways(ESLGatewayCode?: string, locationIds?: string[], offset?: number): Observable<ESLGateway[]> {
    let params = {
      fields: 'location_info'
    }
    if (ESLGatewayCode) params['q'] = ESLGatewayCode
    if (locationIds?.length) params['location_ids'] = locationIds.join(',')
    if (offset != undefined) {
      params['limit'] = 10
      params['offset'] = offset
    }
    return this._restApiService.loadESLGateways(params).pipe(
      tap((ESLGateways: IESLGateway[]) => {
        ESLGateways?.filter((ESLGateway) => ESLGateway['location']).forEach((ESLGateway) => {
          this._subsidiaryService.addToSubsidiaries(Object.assign(ESLGateway['location']['subsidiary'], { id: ESLGateway['location']['subsidiary_id'] }))
          this._locationsService.addToLocations(Object.assign(ESLGateway['location'], { id: ESLGateway.location_id }))
        })
      }),
      map((ESLGateways: IESLGateway[]) => ESLGateways.map((ESLGatewayDict) => new ESLGateway(ESLGatewayDict))),
      catchError(() => {
        this._snackBar.open('An error occured while loading ESL Gateways', 'Close', { duration: 5000 })
        return of([])
      })
    )
  }

  public createGateway(ESLGateway: ESLGateway): Observable<any> {
    return this._restApiService.saveESLGateway([ESLGateway.asDict])
  }

  public getLabels(ESLLabelCode?: string, locationIds?: string[], offset?: number): Observable<ESLLabel[]> {
    let params = {
      fields: 'location_info'
    }
    if (ESLLabelCode) params['q'] = ESLLabelCode
    if (locationIds?.length) params['location_ids'] = locationIds.join(',')
    if (offset != undefined) {
      params['limit'] = 10
      params['offset'] = offset
    }
    return this._restApiService.loadESLLabels(params).pipe(
      tap((ESLLabels: IESLLabel[]) => {
        ESLLabels?.filter((ESLLabel) => ESLLabel['location']).forEach((ESLLabel) => {
          this._subsidiaryService.addToSubsidiaries(Object.assign(ESLLabel['location']['subsidiary'], { id: ESLLabel['location']['subsidiary_id'] }))
          this._locationsService.addToLocations(Object.assign(ESLLabel['location'], { id: ESLLabel.location_id }))
        })
      }),
      map((ESLLabels: IESLLabel[]) => ESLLabels.map((ESLLabelDict) => new ESLLabel(ESLLabelDict))),
      catchError(() => {
        this._snackBar.open('An error occured while loading ESL Labels', 'Close', { duration: 5000 })
        return of([])
      })
    )
  }

  public createESLLabel(ESLLabel: ESLLabel): Observable<any> {
    return this._restApiService.createESLLabel([ESLLabel.asDict])
  }

  public allocateLabel(ESLLabel: ESLLabel): Observable<any> {
    return this._restApiService.allocateESLLabel(ESLLabel.asDict)
  }

  public loadHardwareInfo(ESLLabelCode?: string, locationIDs?: string[], offset: number = 0): Observable<IESLLabel[]> {
    let params = {
      offset: offset
    }
    if (ESLLabelCode) params['q'] = ESLLabelCode
    if (locationIDs?.length) params['location_ids'] = locationIDs.join(',')
    return this._restApiService.loadESLLabelsInfo(params).pipe(
      map((response) => response.esl_labels),
      catchError(() => {
        return of([])
      })
    )
  }
}
