import { Pipe, PipeTransform } from '@angular/core'

@Pipe({ name: 'decimalSup' })
export class DecimalSupPipe implements PipeTransform {
  transform(value: number, decimals: number = 2): string {
    if (!value && value !== 0) return ''
    const [integer, decimal] = value.toFixed(decimals).split('.')
    return `${integer}<sup>${decimal}</sup>`
  }
}
