import { FormControl, Validators } from '@angular/forms'
import { FoodopLibModule } from '../../foodop-lib.module'
import { Subsidiary } from '../subsidiary/subsidiary.model'
import { SubsidiaryService } from '../subsidiary/subsidiary.service'
import { IOrganization } from '../../global.models'
import { OrganizationsService } from './organizations.service'
import { Observable, catchError, map, merge, of, switchMap, tap } from 'rxjs'
import { ProcurementMetric } from '../procurement-metrics/procurement-metrics.model'
import { defaultMetrics } from '../../global.types'
import { MatSnackBar } from '@angular/material/snack-bar'

export class Organization {
  public id: string
  public name: FormControl
  public nick_name: FormControl
  public website: FormControl
  public phone: FormControl
  public cvr: FormControl
  public address: FormControl
  public postal: FormControl
  public city: FormControl
  public country: FormControl
  public active: FormControl
  public procurementMetrics: ProcurementMetric[]

  public subsidiary_ids: string[]
  public subsidiaries_sorted: Subsidiary[]

  public saved_organization: any
  public saving: boolean = false
  public loading: boolean = false
  public details_loaded: boolean = false

  private _subsidiaryService: SubsidiaryService
  private _organizationsService: OrganizationsService
  private _snackBar: MatSnackBar

  constructor(public organization?: IOrganization) {
    this._subsidiaryService = FoodopLibModule.injector.get(SubsidiaryService)
    this._organizationsService = FoodopLibModule.injector.get(OrganizationsService)
    this._snackBar = FoodopLibModule.injector.get(MatSnackBar)

    this.id = organization?.id
    this.name = new FormControl(organization?.name, [Validators.required])
    this.nick_name = new FormControl(organization?.nick_name)
    this.website = new FormControl(organization?.website)
    this.phone = new FormControl(organization?.phone)
    this.cvr = new FormControl(organization?.cvr)
    this.address = new FormControl(organization?.address)
    this.postal = new FormControl(organization?.postal)
    this.city = new FormControl(organization?.city)
    this.country = new FormControl(organization?.country)
    this.active = new FormControl(organization?.active != undefined ? organization?.active : true)
    this.procurementMetrics = Array.from(new Map([...defaultMetrics, ...(organization?.procurement_metrics || [])].map((metric) => [metric.type, new ProcurementMetric(metric)])).values())

    this.subsidiary_ids = (organization?.subsidiaries || []).map((subsidiary) => subsidiary.id)

    this.saved_organization = JSON.stringify(this.as_dict)
  }

  public patchValues(organization: IOrganization): void {
    if (organization?.id) this.id = organization.id
    if (organization?.name) this.name.setValue(organization.name)
    if (organization?.nick_name) this.nick_name.setValue(organization.nick_name)
    if (organization?.website) this.website.setValue(organization.website)
    if (organization?.phone) this.phone.setValue(organization.phone)
    if (organization?.cvr) this.cvr.setValue(organization.cvr)
    if (organization?.address) this.address.setValue(organization.address)
    if (organization?.postal) this.postal.setValue(organization.postal)
    if (organization?.city) this.city.setValue(organization.city)
    if (organization?.country) this.country.setValue(organization.country)
    if (organization?.active != undefined) this.active.setValue(organization.active)

    if (organization?.subsidiaries) this.subsidiary_ids = (organization?.subsidiaries || []).map((subsidiary) => subsidiary.id)

    if (organization?.procurement_metrics) {
      this.procurementMetrics = Array.from(new Map([...defaultMetrics, ...(organization?.procurement_metrics || [])].map((metric) => [metric.type, new ProcurementMetric(metric)])).values())
    }

    this.saved_organization = JSON.stringify(this.as_dict)
  }

  public get valid(): Boolean {
    return Object.keys(this).find((key) => this[key]?.invalid == true) == undefined
  }

  public get changed(): Boolean {
    return this.saved_organization != JSON.stringify(this.as_dict)
  }

  public get is_new(): Boolean {
    return !this.id
  }

  public get subsidiaries(): Subsidiary[] {
    return this.subsidiary_ids.map((id) => this._subsidiaryService.subsidiary_with_id(id))
  }

  public get activeSubsidiaries(): Subsidiary[] {
    return this.subsidiary_ids.map((id) => this._subsidiaryService.subsidiary_with_id(id)).filter((subsidiary) => subsidiary.active.value)
  }

  get activeProcurementMetrics(): string[] {
    return this.procurementMetrics.filter((metric) => metric.active.value).map((metric) => metric.type)
  }

  get activeBoolProcurementMetrics(): number {
    return this.procurementMetrics.filter((metric) => ['local', 'msc'].includes(metric.type) && metric.active.value).length
  }

  get activeLevelProcurementMetrics(): number {
    return this.procurementMetrics.filter((metric) => ['animalwelfare'].includes(metric.type) && metric.active.value).length
  }

  public procurementMetricModelForType(type: string): ProcurementMetric {
    return this.procurementMetrics.find((metric) => metric.type == type)
  }

  public addSubsidiary(subsidiary: Subsidiary): void {
    if (!this.subsidiary_ids.find((subsidiary_id) => subsidiary_id == subsidiary.id)) this.subsidiary_ids.push(subsidiary.id)
  }

  public save(): Observable<Organization> {
    this.saving = true
    this._resetProcurementMetricNameTranslations()
    return this._organizationsService.saveOrganization(this).pipe(
      tap(() => {
        this.saved_organization = JSON.stringify(this.as_dict)
        this.saving = false
      }),
      map(() => {
        return this
      }),
      catchError(() => {
        this._snackBar.open($localize`Kunne ikke gemme organisationen`)
        this.saving = false
        return of(this)
      })
    )
  }

  public saveAsAdmin(): Observable<Organization> {
    this.saving = true
    this._resetProcurementMetricNameTranslations()
    return this._organizationsService.saveAdminOrganization(this).pipe(
      tap((organization) => {
        this.patchValues(organization)
        this.saved_organization = JSON.stringify(this.as_dict)
        this.saving = false
      }),
      map(() => {
        return this
      }),
      catchError(() => {
        this._snackBar.open($localize`Kunne ikke gemme organisationen`)
        this.saving = false
        return of(this)
      })
    )
  }

  public load_details(): Observable<Organization> {
    this.loading = true
    return this._organizationsService.loadOrganization(this).pipe(
      tap((organization) => {
        this.patchValues(organization)
        this.loading = false
        this.details_loaded = true
      }),
      map(() => {
        return this
      })
    )
  }

  public listenForProcurementMetricChanges(): Observable<any> {
    return merge(...this.procurementMetrics.map((metric) => metric.listenForChanges())).pipe(
      switchMap(() => {
        return this.save()
      })
    )
  }

  public get as_dict(): IOrganization {
    return {
      id: this.id,
      name: this.name.value,
      nick_name: this.nick_name.value,
      website: this.website.value,
      phone: this.phone.value,
      cvr: this.cvr.value,
      address: this.address.value,
      postal: this.postal.value,
      city: this.city.value,
      country: this.country.value,
      active: this.active.value,
      procurement_metrics: this.procurementMetrics.map((metric) => metric.as_dict)

      //subsidiaries: (this.subsidiaries || []).map((subsidiary) => subsidiary.as_dict)
    }
  }

  private _resetProcurementMetricNameTranslations(): void {
    this.procurementMetrics.filter((metric) => metric.nameChanged).forEach((metric) => metric.names.resetTranslation())
  }
}
