<div class="filter-container">
  <mat-form-field appearance="fill" class="customer-picker">
    <input
      autocomplete="off"
      #customerInput
      #autocompleteTrigger="matAutocompleteTrigger"
      lang="en"
      [formControl]="customerSearchControl"
      type="text"
      matInput
      matAutocompletePosition="below"
      [matAutocomplete]="autoCompleteName"
      #customerInputField="matAutocompleteTrigger"
      placeholder="Search for locations"
    />

    <mat-autocomplete id="customerInputTrigger" #autoCompleteName="matAutocomplete" multiple disableRipple (optionsScroll)="onScroll()">
      <ng-container *ngFor="let organization of organizations$ | async | organizationFilterPipe : customerSearchControl.value; let organizationIndex = index">
        <mat-option [value]="organization.id" class="option-level-0" [ngStyle]="{ 'z-index': subsidiariesBeforeIndex(organizationIndex) + organizationIndex }">
          <div class="option-container" (click)="$event.stopPropagation(); toggleSelection(organization)">
            <mat-checkbox color="primary" [checked]="optionSelected(organization)" (click)="$event.stopPropagation()" (change)="toggleSelection(organization)">
              <label class="check-box-label" (click)="$event.stopPropagation(); toggleSelection(organization)" [innerHTML]="organization.name.value | boldSearchQuery : customerInput.value"></label>
            </mat-checkbox>
          </div>
        </mat-option>
        <ng-container *ngFor="let subsidiary of organization.subsidiaries | subsidiaryFilterPipe : customerSearchControl.value; let subidiaryIndex = index">
          <mat-option [value]="subsidiary.id" class="option-level-1" [ngStyle]="{ 'z-index': subsidiariesBeforeIndex(organizationIndex) + subidiaryIndex }">
            <div class="sub-option-container" (click)="$event.stopPropagation(); toggleSelection(subsidiary)">
              <mat-checkbox color="primary" [checked]="optionSelected(subsidiary)" (click)="$event.stopPropagation()" (change)="toggleSelection(subsidiary)">
                <label class="check-box-label" (click)="$event.stopPropagation(); toggleSelection(subsidiary)" [innerHTML]="subsidiary.name.value | boldSearchQuery : customerInput.value"></label>
              </mat-checkbox>
            </div>
          </mat-option>
          <mat-option *ngFor="let location of subsidiary.locations | locationFilterPipe : customerSearchControl.value" [ngClass]="{ 'l-font-italic': location.gateways.length == 0 }" value="test">
            <div class="sub-sub-option-container" (click)="$event.stopPropagation(); toggleSelection(location)">
              <mat-checkbox color="primary" [checked]="optionSelected(location)" (click)="$event.stopPropagation()" (change)="toggleSelection(location)">
                <label class="check-box-label" (click)="$event.stopPropagation(); toggleSelection(location)" [innerHTML]="(location.name.value | boldSearchQuery : customerInput.value) + (location.gateways.length == 0 ? ' (no gateways allocated)' : '')"></label>
              </mat-checkbox>
            </div>
          </mat-option>
        </ng-container>
      </ng-container>
      <mat-option *ngIf="loadingOrganizations" class="o-cursor-default">
        <div class="l-display-flex l-position-center-vertically">
          <mat-spinner class="o-inline-block l-float-left" color="primary" diameter="19"></mat-spinner>
          <label class="l-font-small l-line-height-19 l-padding-micro" i18n>Søger efter kunder ...</label>
        </div>
      </mat-option>
    </mat-autocomplete>
  </mat-form-field>
</div>

<label *ngIf="selections.length" class="l-font-small l-padding-small">Valgte kunder: {{ selections }}</label>

<mat-tab-group mat-align-tabs="center" animationDuration="800ms">
  <mat-tab label="Vægte">
    <ng-template matTabContent>
      <scales-overview [customerSelection]="customerSelection"></scales-overview>
    </ng-template>
  </mat-tab>
  <mat-tab label="Gateways">
    <ng-template matTabContent>
      <gateways-overview [customerSelection]="customerSelection"></gateways-overview>
    </ng-template>
  </mat-tab>
  <mat-tab label="ESL Gateways">
    <ng-template matTabContent>
      <esl-gateways-overview [customerSelection]="customerSelection"></esl-gateways-overview>
    </ng-template>
  </mat-tab>
  <mat-tab label="ESL Labels">
    <ng-template matTabContent>
      <esl-labels-overview [customerSelection]="customerSelection"></esl-labels-overview>
    </ng-template>
  </mat-tab>
</mat-tab-group>
