import { NgModule } from '@angular/core'
import { dateToWeekNumberPipe } from '../../pipes/date-week.pipe'
import { TimeToMealTypePipe } from '../../pipes/time-meal.pipe'
import { BoldSearchQueryPipe } from '../../pipes/bold.pipe'
import { LocationFilterPipe } from '../../pipes/location-filter.pipe'
import { OrganizationFilterPipe } from '../../pipes/organization-filter.pipe'
import { SubsidiaryFilterPipe } from '../../pipes/subsidiary-filter.pipe'
import { childrenLengthFilterPipe } from '../../pipes/children-length-filter.pipe'
import { RelativeTimePipe } from '../../pipes/relative-time.pipe'
import { DecimalSupPipe } from '../../pipes/decimal-sup.pipe'

@NgModule({
  declarations: [dateToWeekNumberPipe, TimeToMealTypePipe, BoldSearchQueryPipe, LocationFilterPipe, OrganizationFilterPipe, SubsidiaryFilterPipe, childrenLengthFilterPipe, RelativeTimePipe, DecimalSupPipe],
  exports: [dateToWeekNumberPipe, TimeToMealTypePipe, BoldSearchQueryPipe, LocationFilterPipe, OrganizationFilterPipe, SubsidiaryFilterPipe, childrenLengthFilterPipe, RelativeTimePipe, DecimalSupPipe]
})
export class PipesModule {}
