import { FormControl } from '@angular/forms'
import { IPackaging } from './packaging.interface'
import { min } from '../../utils/number-operators'

export class Packaging {
  public sku: string
  public unit: string
  public unitsPerColli: number
  public colliWeight?: number
  public weightDependable: boolean
  public allowBreakage: boolean
  public priceType: string
  public singlePrice?: number
  public singlePricePerKg?: number
  public colliPrice?: number
  public colliPricePerUnit?: number
  public colliPriceBefore?: number
  public colliPricePerUnitBefore?: number
  public colliPriceDiscountPercent?: number
  public colliPricePerKg?: number
  public netWeight: number

  public singleAmountSelection: FormControl = new FormControl('')
  public colliAmountSelection: FormControl = new FormControl('')

  constructor(public packaging?: IPackaging) {
    this.patchValues(packaging)
  }

  public get totalPrice(): number {
    return (this.singleAmountSelection.value || 0) * (this.singlePrice || 0) + (this.colliAmountSelection.value || 0) * (this.colliPrice || 0)
  }

  public get minPrice(): number {
    return min([this.colliPricePerKg, this.singlePricePerKg])
  }

  public get asDict(): IPackaging {
    return {
      sku: this.sku,
      unit: this.unit,
      unitsPerColli: this.unitsPerColli,
      colliWeight: this.colliWeight,
      weightDependable: this.weightDependable,
      allowBreakage: this.allowBreakage,
      priceType: this.priceType,
      singlePrice: this.singlePrice,
      singlePricePerKg: this.singlePricePerKg,
      colliPrice: this.colliPrice,
      colliPricePerUnit: this.colliPricePerUnit,
      colliPriceBefore: this.colliPriceBefore,
      colliPricePerUnitBefore: this.colliPricePerUnitBefore,
      colliPriceDiscountPercent: this.colliPriceDiscountPercent,
      colliPricePerKg: this.colliPricePerKg,
      netWeight: this.netWeight,
      singleAmountSelection: this.singleAmountSelection.value,
      colliAmountSelection: this.colliAmountSelection.value
    }
  }

  public patchValues(packaging?: IPackaging): void {
    this.sku = packaging?.sku
    this.unit = packaging?.unit
    this.unitsPerColli = packaging?.unitsPerColli
    this.colliWeight = packaging?.colliWeight
    this.weightDependable = packaging?.weightDependable
    this.allowBreakage = packaging?.allowBreakage
    this.priceType = packaging?.priceType
    this.singlePrice = packaging?.singlePrice
    this.singlePricePerKg = packaging?.singlePricePerKg
    this.colliPrice = packaging?.colliPrice
    this.colliPricePerUnit = packaging?.colliPricePerUnit
    this.colliPriceBefore = packaging?.colliPriceBefore
    this.colliPricePerUnitBefore = packaging?.colliPricePerUnitBefore
    this.colliPriceDiscountPercent = packaging?.colliPriceDiscountPercent
    this.colliPricePerKg = packaging?.colliPricePerKg
    this.netWeight = packaging?.netWeight
    this.singleAmountSelection.setValue(packaging?.singleAmountSelection)
    this.colliAmountSelection.setValue(packaging?.colliAmountSelection)
  }
}
