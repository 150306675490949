import { FormBuilder, FormControl, Validators } from '@angular/forms'
import { FoodopLibModule } from '../../foodop-lib.module'
import { IProcurementProduct } from '../../global.models'
import { RawIngredient } from '../raw-ingredient/raw-ingredient.model'
import moment from 'moment'
import { CustomValidators, isValidSubsidiaryId } from '../../validators/number_validator'
import { Subject, debounce, debounceTime, distinctUntilChanged, filter, merge, of } from 'rxjs'
import { ProcurementService } from './procurement.service'
import { SubsidiaryService } from '../subsidiary/subsidiary.service'
import { GlobalFunctionsService } from '../../services/global-functions.service'
import { MenuTemplatesService } from '../menu-templates/menu-templates.service'

export class ProcurementProduct {
  product_id?: string
  product_name: FormControl
  date: FormControl
  amount: FormControl
  gtin?: FormControl
  ean?: FormControl
  product_number?: FormControl
  supplier_product_number?: FormControl
  organic?: FormControl
  origin_country?: FormControl
  origin_code?: FormControl
  customMetrics?: { [key: string]: { value?: FormControl; include?: FormControl; bool?: FormControl; level?: FormControl; manual?: boolean } }
  gpc_code?: FormControl
  gpc_name?: FormControl
  supplier?: FormControl
  gln?: FormControl
  manufacturer?: FormControl
  brand?: FormControl
  price?: FormControl
  total_price?: FormControl

  ingredient?: FormControl

  is_food?: FormControl
  include_in_organic?: FormControl
  mapping_status: number
  include: boolean = true

  file_id: string
  file_name: FormControl
  source?: any
  created?: moment.Moment
  updated?: moment.Moment
  user_id?: string
  subsidiary_id?: FormControl
  organization_id?: string

  mapping_source?: string
  raw_input?: any
  saved_product: string
  saved = new Subject()
  saving: boolean

  private _notOrganicValues = ['konvention', 'ukendt', 'uoplyst', 'ej', 'convention', 'ikke', 'not']
  private _organicValues = ['øko', 'org']
  private _falseValues = [false, '0', 'false', 'nej', 'no', 'not', 'n', 'falsk', 'falskt', 0]
  private _trueValues = [true, '1', 'true', 'ja', 'yes', 'y', 'sand', 'sandt', 1]
  private _includeValues = ['medtag', 'indehold', 'inkl', 'includ']
  private _excludeValues = ['undtag', 'udelad', 'eksklude', 'exclud']

  fb: FormBuilder
  procurement_service: ProcurementService
  subsidiaryService: SubsidiaryService
  menuTemplatesService: MenuTemplatesService
  func: GlobalFunctionsService

  constructor(procurement_product?: IProcurementProduct, raw_input?: any, public language?: string) {
    this.fb = FoodopLibModule.injector.get(FormBuilder)
    this.procurement_service = FoodopLibModule.injector.get(ProcurementService)
    this.subsidiaryService = FoodopLibModule.injector.get(SubsidiaryService)
    this.menuTemplatesService = FoodopLibModule.injector.get(MenuTemplatesService)
    this.func = FoodopLibModule.injector.get(GlobalFunctionsService)

    this.product_id = procurement_product?.product_id || null
    this.product_name = new FormControl(procurement_product?.product_name || null, [Validators.required])
    this.date = new FormControl(this._parseDate(procurement_product?.date), [Validators.required])
    this.amount = new FormControl(typeof procurement_product?.amount == 'string' ? parseFloat(procurement_product?.amount?.replace(',', '.').replace(/[^0-9,^\.,^\,]/g, '')) : procurement_product?.amount || null, [Validators.required, CustomValidators.isNumbers])
    this.gtin = new FormControl(procurement_product?.gtin || null, [])
    this.ean = new FormControl(procurement_product?.ean || null, [])
    this.product_number = new FormControl(procurement_product?.product_number || null, [])
    this.supplier_product_number = new FormControl(procurement_product?.supplier_product_number || null, [])
    this.organic = new FormControl(this._parseOrganicLabel(procurement_product), [CustomValidators.isBoolean])
    this.origin_country = new FormControl(procurement_product?.origin_country || null, [])
    this.origin_code = new FormControl(procurement_product?.origin_code || null, [])
    this._setCustomMetrics(procurement_product)
    this.gpc_code = new FormControl(procurement_product?.gpc_code || null, [])
    this.gpc_name = new FormControl(procurement_product?.gpc_name || null, [])
    this.supplier = new FormControl(procurement_product?.supplier || null, [])
    this.gln = new FormControl(procurement_product?.gln || null, [])
    this.manufacturer = new FormControl(procurement_product?.manufacturer || null, [])
    this.brand = new FormControl(procurement_product?.brand || null, [])
    this.price = new FormControl((typeof procurement_product?.price == 'string' ? parseFloat((procurement_product.price as string).replace(/[^0-9,^\.,^\,]/g, '')) : procurement_product?.price) || null, [CustomValidators.isNumbers])
    this.total_price = new FormControl(
      (typeof procurement_product?.total_price == 'string' ? parseFloat((procurement_product.total_price as string).replace(/[^0-9,^\.,^\,]/g, '')) : procurement_product?.total_price) ||
        (typeof procurement_product?.price == 'string' ? parseFloat((procurement_product.price as string).replace(/[^0-9,^\.,^\,]/g, '')) * this.amount.value : procurement_product?.price * this.amount.value) ||
        null,
      [CustomValidators.isNumbers]
    )

    this.ingredient = new FormControl(procurement_product?.ingredient ? new RawIngredient(procurement_product.ingredient) : null)

    this.is_food = new FormControl(procurement_product?.is_food != undefined ? procurement_product?.is_food : true)
    this.include_in_organic = new FormControl(this._parseIncludeInOrganicLabel(procurement_product))
    this.mapping_status = procurement_product?.mapping_status || null
    this.include = procurement_product?.include != undefined ? procurement_product?.include : !this.is_missing_all_required_properties

    this.file_id = procurement_product?.file_id || null
    this.file_name = new FormControl(procurement_product?.file_name || null)
    this.source = procurement_product?.source
    this.created = moment(moment.utc(procurement_product?.created).format()) || null
    this.updated = moment(moment.utc(procurement_product?.updated).format()) || null
    this.user_id = procurement_product?.user_id || null
    this.subsidiary_id = new FormControl(this._parseSubsidiaryName('subsidiary_id' in procurement_product ? procurement_product.subsidiary_id : this.subsidiaryService.subsidiary.id), [
      isValidSubsidiaryId(this.subsidiaryService.subsidiary.organization.activeSubsidiaries)
    ])
    this.organization_id = procurement_product?.organization_id || null

    this.mapping_source = procurement_product?.mapping_source || 'ai'
    if (raw_input) this.raw_input = raw_input
    this.saved_product = JSON.stringify(this.as_dict)

    this._listenForPriceChanges()

    if (this.product_id) {
      this._listenForProductChanges()
    }
  }

  private get _customMetrics(): {} {
    let customMetrics = {}
    Object.entries(this.customMetrics)
      .filter(([key, value]) => value.value.value != undefined || value.include.value != undefined || value.bool.value != undefined || value.level.value != undefined)
      .forEach(([key, value]) => {
        customMetrics[key] = {
          value: value.value.value || '',
          include: value.include.value != undefined ? value.include.value : null,
          bool: value.bool.value != undefined ? value.bool.value : null,
          level: value.level.value != undefined ? value.level.value : null,
          manual: value.manual
        }
      })
    return customMetrics
  }

  private get _configuredMetrics(): {} {
    return Object.fromEntries(new Map(this.subsidiaryService.subsidiary.organization.procurementMetrics.map((metric) => [metric.type, {}])))
  }

  private _setCustomMetrics(product: IProcurementProduct): void {
    this.customMetrics = {}
    Object.entries(Object.assign(this._configuredMetrics, product?.custom_metrics)).forEach(([key, value]) => {
      this.customMetrics[key] = {
        value: new FormControl(value.value?.toString()),
        include: new FormControl(value.include),
        bool: new FormControl(value.bool),
        level: new FormControl(value.level),
        manual: value.manual
      }
    })
  }

  private _patchCustomMetrics(product: IProcurementProduct): void {
    Object.entries(product?.custom_metrics).forEach(([key, value]) => {
      if (this.customMetrics[key]) {
        this.customMetrics[key].value.setValue(value.value?.toString(), { emitEvent: false })
        this.customMetrics[key].include.setValue(value.include, { emitEvent: false })
        this.customMetrics[key].bool.setValue(value.bool, { emitEvent: false })
        this.customMetrics[key].level.setValue(value.level, { emitEvent: false })
        this.customMetrics[key].manual = value.manual
      } else {
        this.customMetrics[key] = {
          value: new FormControl(value.value?.toString()),
          include: new FormControl(value.include),
          bool: new FormControl(value.bool),
          level: new FormControl(value.level),
          manual: value.manual
        }
      }
    })
  }

  private _parseSubsidiaryName(subsidiaryValue: string): string {
    return (
      this.subsidiaryService.subsidiary.organization.activeSubsidiaries.find((subsidiary) => subsidiary.id == subsidiaryValue)?.id ||
      this.subsidiaryService.subsidiary.organization.activeSubsidiaries
        .map((subsidiary) => {
          return {
            subsidiary: subsidiary,
            match: Math.max(this.func.stringSimilarity(subsidiary.name.value, subsidiaryValue), this.func.stringSimilarity(subsidiary.id, subsidiaryValue))
          }
        })
        .sort((a, b) => (a['match'] <= b['match'] ? 1 : -1))
        .find((subsidiary) => subsidiary['match'] >= 0.5)?.subsidiary?.id
    )
  }

  get is_missing_all_required_properties(): boolean {
    return (
      Object.keys(this)
        .filter((key) => (this[key] ? this[key].hasOwnProperty('value') : false))
        .find((key) => this[key].hasValidator(Validators.required) && this[key].valid) == undefined
    )
  }

  get valid(): boolean {
    return Object.keys(this)
      .filter((key) => (this[key] ? this[key].hasOwnProperty('value') : false))
      .every((key) => this[key].valid)
  }

  get changed(): boolean {
    return this.saved_product != JSON.stringify(this.as_dict)
  }

  get as_dict(): IProcurementProduct {
    return {
      product_id: this.product_id,
      product_name: this.product_name.value,
      date: this.date.value?.format('YYYY-MM-DD'),
      amount: parseFloat(this.amount.value),
      gtin: this.gtin.value,
      ean: this.ean.value,
      product_number: this.product_number.value,
      supplier_product_number: this.supplier_product_number.value,
      organic: this.organic.value,
      origin_country: this.origin_country.value,
      origin_code: this.origin_code.value,
      custom_metrics: this._customMetrics,
      gpc_code: this.gpc_code.value,
      gpc_name: this.gpc_name.value,
      supplier: this.supplier.value,
      gln: this.gln.value,
      manufacturer: this.manufacturer.value,
      brand: this.brand.value,
      price: parseFloat(this.price.value) || (this.amount.value && this.total_price.value ? parseFloat(this.total_price.value) / this.amount.value : null),
      ingredient: this.ingredient.value?.asDict || null,
      raw_input: this.raw_input,
      is_food: this.is_food.value,
      include_in_organic: this.include_in_organic.value,
      mapping_status: this.mapping_status,
      mapping_source: this.mapping_source,
      source: this.source,
      subsidiary_id: this.subsidiary_id.value == 'none' ? null : this.subsidiary_id.value
    }
  }

  get as_updated_dict(): IProcurementProduct {
    let updated_dict = {
      product_id: this.product_id,
      product_name: this.product_name.value,
      is_food: this.is_food.value,
      mapping_source: this.mapping_source,
      subsidiary_id: this.subsidiary_id.value == 'none' ? null : this.subsidiary_id.value
    }
    Object.keys(JSON.parse(this.saved_product)).forEach((key) => {
      if (this.as_dict[key] != JSON.parse(this.saved_product)[key]) updated_dict[key] = this.as_dict[key] != undefined ? this.as_dict[key] : null
    })
    return updated_dict
  }

  updateIngredient(new_ingredient: RawIngredient): void {
    Object.values(this.customMetrics).forEach((value) => {
      value.include.setValue(null, { emitEvent: false })
    })
    this.ingredient.setValue(new_ingredient)
  }

  public toggleBoolean(column: any, property?: string): void {
    if (column['customMetric'] && property) {
      if (property == 'bool') this.customMetrics?.[column['column']]?.include?.setValue(true, { emitEvent: false })
      this.customMetrics[column['column']].manual = true
      this.customMetrics?.[column['column']]?.[property]?.setValue(!this.customMetrics?.[column['column']]?.[property]?.value)
    } else if (column['editable']) this[column['column']].setValue(!this[column['column']].value)
  }

  toggleIsFood(value: boolean): void {
    this.is_food.setValue(value)
    if (value) this.include_in_organic.setValue(true)
    else this.include_in_organic.setValue(false)
  }

  toggleIncludeInOrganicReport(value: boolean): void {
    this.include_in_organic.setValue(value)
  }

  patchValues(updates: IProcurementProduct): void {
    if (updates.product_name) this.product_name.setValue(updates.product_name, { emitEvent: false })
    if (updates.date) this.date.setValue(this._parseDate(updates.date), { emitEvent: false })
    if (updates.amount != undefined) this.amount.setValue(updates.amount, { emitEvent: false })
    if (updates.gtin) this.gtin.setValue(updates.gtin, { emitEvent: false })
    if (updates.ean) this.ean.setValue(updates.ean, { emitEvent: false })
    if (updates.product_number) this.product_number.setValue(updates.product_number, { emitEvent: false })
    if (updates.supplier_product_number) this.supplier_product_number.setValue(updates.supplier_product_number, { emitEvent: false })
    if (updates.organic != undefined) this.organic.setValue(updates.organic, { emitEvent: false })
    if (updates.origin_country) this.origin_country.setValue(updates.origin_country, { emitEvent: false })
    if (updates.origin_code) this.origin_code.setValue(updates.origin_code, { emitEvent: false })
    if (updates.custom_metrics) this._patchCustomMetrics(updates)
    if (updates.gpc_code) this.gpc_code.setValue(updates.gpc_code, { emitEvent: false })
    if (updates.gpc_name) this.gpc_name.setValue(updates.gpc_name, { emitEvent: false })
    if (updates.supplier) this.supplier.setValue(updates.supplier, { emitEvent: false })
    if (updates.gln) this.gln.setValue(updates.gln, { emitEvent: false })
    if (updates.manufacturer) this.manufacturer.setValue(updates.manufacturer, { emitEvent: false })
    if (updates.brand) this.brand.setValue(updates.brand, { emitEvent: false })
    if (updates.price != undefined) this.price.setValue(updates.price, { emitEvent: false })
    if (updates.total_price != undefined) this.total_price.setValue(updates.total_price, { emitEvent: false })
    if (updates.ingredient != undefined) this.ingredient.setValue(new RawIngredient(updates.ingredient), { emitEvent: false })
    if (updates.ingredient == null) this.ingredient.setValue(null, { emitEvent: false })
    if (updates.is_food != undefined) this.is_food.setValue(updates.is_food, { emitEvent: false })
    if (updates.include_in_organic != undefined) this.include_in_organic.setValue(updates.include_in_organic, { emitEvent: false })
    if (updates.mapping_status != undefined) this.mapping_status = updates.mapping_status
    if (updates.include != undefined) this.include = updates.include
    if (updates.file_id) this.file_id = updates.file_id
    if (updates.file_name) this.file_name.setValue(updates.file_name)
    if (updates.source) this.source = updates.source
    if (updates.created) this.created = moment(updates.created)
    if (updates.updated) this.updated = moment(updates.updated)
    if (updates.user_id) this.user_id = updates.user_id
    if (updates.subsidiary_id) this.subsidiary_id.setValue(this._parseSubsidiaryName(updates.subsidiary_id), { emitEvent: false })
    if (updates.organization_id) this.organization_id = updates.organization_id
    if (updates.mapping_source) this.mapping_source = updates.mapping_source
    if (updates.raw_input) this.raw_input = updates.raw_input
    this.saved_product = JSON.stringify(this.as_dict)
  }

  save(): void {
    if (this.changed && this.valid) {
      this.saving = true
      const params = {
        subsidiary_id: this.subsidiaryService.subsidiary.id
      }
      this.procurement_service.updateProduct(this.as_updated_dict, params).subscribe(() => {
        this.saved_product = JSON.stringify(this.as_dict)
        this.saving = false
        this.saved.next(null)
      })
    }
  }

  _listenForProductChanges(): void {
    this._changeMappingSourceOnMappingChanges()
    this._saveOnSelectionChanges()
    this._autoSaveOnInputChanges()
  }

  private _listenForPriceChanges(): void {
    this.price.valueChanges.subscribe(() => {
      this.total_price.setValue(parseFloat(this.price.value) * parseFloat(this.amount.value) || null, { emitEvent: false })
    })
    this.total_price.valueChanges.subscribe(() => {
      this.price.setValue(parseFloat(this.total_price.value) / parseFloat(this.amount.value) || null, { emitEvent: false })
    })
  }

  _changeMappingSourceOnMappingChanges(): void {
    merge(this.ingredient.valueChanges, this.is_food.valueChanges).subscribe(() => {
      this.mapping_source = 'manual'
      if (this.is_food && this.ingredient.value == null) this.mapping_status = 0
    })
  }
  _saveOnSelectionChanges(): void {
    merge(
      ...[this.date.valueChanges, this.ingredient.valueChanges, this.is_food.valueChanges, this.organic.valueChanges, this.include_in_organic.valueChanges, this.subsidiary_id.valueChanges],
      ...Object.values(this.customMetrics).map((customMetric) => customMetric.include.valueChanges),
      ...Object.values(this.customMetrics).map((customMetric) => customMetric.bool.valueChanges),
      ...Object.values(this.customMetrics).map((customMetric) => customMetric.level.valueChanges.pipe(filter(() => customMetric.level.value != null)))
    )
      .pipe(
        filter(() => {
          return this.changed
        })
      )
      .subscribe(() => {
        this.save()
      })
  }
  _autoSaveOnInputChanges(): void {
    merge(
      this.product_name.valueChanges,
      this.amount.valueChanges,
      this.gtin.valueChanges,
      this.ean.valueChanges,
      this.product_number.valueChanges,
      this.supplier_product_number.valueChanges,
      this.origin_country.valueChanges,
      this.origin_code.valueChanges,
      this.gpc_code.valueChanges,
      this.gpc_name.valueChanges,
      this.supplier.valueChanges,
      this.gln.valueChanges,
      this.manufacturer.valueChanges,
      this.brand.valueChanges,
      this.price.valueChanges,
      this.total_price.valueChanges
    )
      .pipe(
        debounceTime(2000),
        debounce(() => {
          return this.saving ? this.saved : of(null)
        }),
        filter(() => {
          return this.changed
        })
      )
      .subscribe(() => {
        this.save()
      })
  }

  _parseDate(date: moment.Moment): moment.Moment {
    if (!date) return null
    else if (typeof date == 'string' && moment(date, ['DD-MM-YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD', 'YYYY-MM-DD'], 'da').isValid()) {
      if (moment(date, ['DD-MM-YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD', 'YYYY-MM-DD'], 'da').isBefore(moment('2000-01-01'))) return this._convertExcelDate(moment(date, ['DD-MM-YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD', 'YYYY-MM-DD'], 'da'))
      return moment(date, ['DD-MM-YYYY', 'MM-DD-YYYY', 'YYYY-MM-DD', 'YYYY-MM-DD'], 'da')
    } else if (moment(date).isValid()) {
      if (moment(date).isBefore(moment('2000-01-01'))) return this._convertExcelDate(date)
      return moment(date)
    } else return null
  }

  _convertExcelDate(excel_date: moment.Moment): moment.Moment {
    return moment(new Date((+excel_date - 25569) * 86400000))
  }

  private _parseIncludeInOrganicLabel(procurementProduct: IProcurementProduct): boolean {
    if (procurementProduct?.include_in_organic != undefined) return procurementProduct.include_in_organic
    else if (procurementProduct?.exclude_from_organic != undefined) return this._isValueFalse(procurementProduct.exclude_from_organic) || this._stringContainsValue(this._includeValues, procurementProduct.exclude_from_organic)
    else if (procurementProduct?.is_food == false) return false
    else if (procurementProduct?.organic != undefined && this._stringContainsValue(this._excludeValues, procurementProduct?.organic)) return false
    return true
  }

  _parseOrganicLabel(procurementProduct: IProcurementProduct): boolean {
    if (procurementProduct?.organic != undefined) {
      if (this._stringContainsValue(this._notOrganicValues, procurementProduct.organic) || this._isValueFalse(procurementProduct.organic)) return false
      if (this._stringContainsValue(this._organicValues, procurementProduct.organic) || this._isValueTrue(procurementProduct.organic)) return true
    }
    if (procurementProduct?.product_name != undefined) {
      if (this._stringContainsValue(this._organicValues, procurementProduct.product_name)) return true
    }
    return false
  }

  private _isValueFalse(string: string | boolean | number): boolean {
    return this._falseValues.find((falseValue) => string?.toString()?.toLowerCase() == falseValue) != undefined
  }
  private _isValueTrue(string: string | boolean | number): boolean {
    return this._trueValues.find((trueValue) => string?.toString()?.toLowerCase() == trueValue) != undefined
  }
  private _stringContainsValue(valueList: string[], string: string | boolean | number): boolean {
    return valueList.find((value) => string?.toString()?.toLowerCase()?.includes(value)) != undefined
  }
}
