import { Injectable } from '@angular/core'
import { Allergen } from './allergen.model'
import { RestApiService } from '../../services/rest-api.service'
import { Observable, tap } from 'rxjs'
import { IAllergen } from '../../global.models'

@Injectable({
  providedIn: 'root'
})
export class AllergensService {
  all_allergens: Allergen[]

  constructor(private restApiService: RestApiService) {}

  get required_allergens(): Allergen[] {
    return this.all_allergens?.filter((allergen) => allergen.required).sort((a, b) => (a.default_index > b.default_index ? 1 : -1))
  }

  allergen_with_code(code: string): Allergen {
    return this.all_allergens?.find((allergen) => allergen.code === code)
  }

  getAllergens(): Observable<IAllergen[]> {
    return this.restApiService.getAllergens().pipe(
      tap((allergens) => {
        this.all_allergens = allergens.sort((a, b) => (a.id > b.id ? 1 : -1)).map((allergen) => new Allergen(allergen))
      })
    )
  }

  getAllergensOpen(): Observable<IAllergen[]> {
    return this.restApiService.getAllergensOpen().pipe(
      tap((allergens) => {
        this.all_allergens = allergens.sort((a, b) => (a.id > b.id ? 1 : -1)).map((allergen) => new Allergen(allergen))
      })
    )
  }

  public createAllergensFromArray(allergens: IAllergen[]): Allergen[] {
    return this.required_allergens.map((requiredAllergen) => {
      return new Allergen(allergens?.find((allergen) => allergen.code == requiredAllergen.code)) || requiredAllergen
    })
  }
}
