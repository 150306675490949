<header class="flex flex-row justify-between items-center flex-wrap gap-2">
  <label class="text-xl font-bold">ESL Gateway</label>
  <nav class="flex flex-row gap-4">
    <custom-button text="Cancel" (click)="onCloseView()"></custom-button>
    <custom-button class="whitespace-nowrap" [text]="ESLGateway.isRegistered ? 'Save edits' : 'Register'" [spinner]="ESLGateway.loading" [disabled]="!ESLGateway.changed" (click)="onSaveESLGateway()"></custom-button>
  </nav>
</header>
<main class="flex flex-col gap-4 overflow-auto scrollbar-hidden">
  <ng-container *ngIf="ESLGateway.isRegistered; else newESLGateway">
    <section class="flex flex-col gap-2 rounded bg-gray-50 p-4 flex-wrap">
      <label class="text-sm font-bold" i18n>Generel</label>
      <section class="flex flex-col gap-1">
        <label class="text-sm">Gateway code: {{ ESLGateway.code.value }}</label>
        <location-picker [selection]="ESLGateway.locationId" (selectionChange)="onSaveESLGateway()"></location-picker>
      </section>
    </section>
  </ng-container>
  <ng-template #newESLGateway>
    <section class="flex flex-col gap-1">
      <label class="text-sm">Code</label>
      <mat-form-field class="outline-input-field max-w-80" appearance="outline">
        <input autocomplete="off" matInput [formControl]="ESLGateway.code" />
        <mat-error *ngIf="ESLGateway.code.hasError('required')">Gateway code has to be specified</mat-error>
      </mat-form-field>
    </section>
  </ng-template>
</main>
