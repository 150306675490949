import { Component, EventEmitter, Inject, OnInit, Output } from '@angular/core'
import { MatSnackBar } from '@angular/material/snack-bar'
import { ESLLabel, INJECTION_DATA } from 'foodop-lib'
import moment from 'moment'
import { catchError } from 'rxjs'

@Component({
  selector: 'esl-label',
  templateUrl: './esl-label.component.html',
  host: {
    class: 'relative w-full h-full bg-white l-box-shadow rounded p-4 flex flex-col gap-4'
  }
})
export class ESLLabelComponent implements OnInit {
  @Output() closeView = new EventEmitter()
  public ESLLabel: ESLLabel
  public now: moment.Moment = moment()

  constructor(@Inject(INJECTION_DATA) public initData: any, private _snackBar: MatSnackBar) {
    this.ESLLabel = initData.ESLLabel
  }

  ngOnInit(): void {
    this.ESLLabel.loadHardwareInfo().subscribe()
  }

  public onCloseView(): void {
    this.closeView.emit()
  }

  public onSaveESLLabel(): void {
    this.ESLLabel.save()
      .pipe(
        catchError(() => {
          this.ESLLabel.loading = false
          this._snackBar.open('An error occured while registering the ESL Label', 'Luk', { duration: 5000 })
          return []
        })
      )
      .subscribe()
  }

  public onAllocateESLLabel(): void {
    this.ESLLabel.allocate()
      .pipe(
        catchError(() => {
          this.ESLLabel.loading = false
          this._snackBar.open('An error occured while allocating the ESL Label', 'Luk', { duration: 5000 })
          return []
        })
      )
      .subscribe()
  }
}
