import { Component, EventEmitter, Inject, Output } from '@angular/core'
import { FormControl } from '@angular/forms'
import { AuthorizationService, IAccessRole, IDomain, INJECTION_DATA, ISubsidiary, localesList, Organization, OrganizationsService, SubsidiaryService, User, UserService } from 'foodop-lib'
import { Observable, Subject, catchError, debounceTime, exhaustMap, filter, scan, startWith, switchMap, takeWhile, tap } from 'rxjs'

@Component({
  selector: 'user',
  templateUrl: './user.component.html',
  host: {
    class: 'w-full bg-white l-box-shadow rounded p-6 flex flex-col gap-4'
  }
})
export class UserComponent {
  @Output() closeView = new EventEmitter<User>()

  public user: User
  public customerSearchControl: FormControl = new FormControl('')
  public loadingOrganizations: boolean = false

  private _organizationsOffset: number = 0
  private _loadNextOrganizations$ = new Subject()
  private _organizations: Organization[] = []

  public organizations$: Observable<Organization[]> = this._loadSearchedOrganizations()
  public accessRoles$: Observable<IAccessRole[]> = this._authorizationService.loadAccessRoles()
  public domains$: Observable<IDomain[]> = this._authorizationService.loadDomains()

  public userTitles = [
    { value: 'canteen_owner', label: 'Canteen owner' },
    { value: 'kitchen_manager', label: 'Kitchen manager' },
    { value: 'chef', label: 'Chef' },
    { value: 'administrative', label: 'Administrative employee' },
    { value: 'other', label: 'Other' }
  ]

  public locales = localesList

  public subsidiaryAccessOptions: ISubsidiary[]

  constructor(@Inject(INJECTION_DATA) public initData: any, public userService: UserService, public organizationsService: OrganizationsService, private subsidiaryService: SubsidiaryService, private _authorizationService: AuthorizationService) {
    this.user = initData.user
    this._setSubsidiaryAccessOptions()
    this._listenForSubsidiaryChanges()
  }

  public userSubsidiaryName(user: User): string {
    return this.subsidiaryService.subsidiary_with_id(user.subsidiary_id.value)?.name?.value || 'Unallocated'
  }

  public subsidiariesBeforeIndex(organizationIndex: number): number {
    return this._organizations
      .slice(0, organizationIndex + 1)
      .map((organization) => organization.subsidiaries.length)
      .reduce((a, b) => a + b, 0)
  }

  public onScroll(): void {
    this._loadNextOrganizations$.next(true)
  }

  public compareSubsidiaryAccessesID(optionOne, optionTwo): boolean {
    return optionOne && optionTwo && optionOne.id && optionOne.id && optionOne.id == optionTwo.id
  }

  public onUserSubsidiaryChanges(user: User): void {
    if (!user.subsidiary_id.value) user.subsidiary_accesses.setValue([])
    else if (user.is_user_admin) {
      const subsidiary = this.subsidiaryService.subsidiary_with_id(user.subsidiary_id.value)
      user.subsidiary_accesses.setValue(
        subsidiary?.organization?.subsidiaries?.map((subsidiary) => {
          return { id: subsidiary.id }
        })
      )
    } else {
      user.subsidiary_accesses.setValue([])
    }
  }

  public saveUser(): void {
    this.user.adminSave().subscribe(() => this.closeView.emit(this.user))
  }

  private _loadSearchedOrganizations(): Observable<Organization[]> {
    const filterChanges$ = this.customerSearchControl.valueChanges.pipe(
      startWith(''),
      tap((value) => {
        if (typeof value === 'string') {
          this._organizations = []
          this._organizationsOffset = 0
        }
      }),
      filter((value) => typeof value === 'string' && (value.length >= 2 || value == '')),
      tap(() => {
        this.loadingOrganizations = true
      }),
      debounceTime(200)
    )

    return filterChanges$.pipe(
      switchMap(() => {
        return this._loadNextOrganizations$.pipe(
          startWith(0),
          exhaustMap(() => this.organizationsService.loadOrganizations('subsidiaries,locations', this.customerSearchControl.value.toLowerCase(), this._organizationsOffset)),
          takeWhile((organizations) => organizations.length == 10, true),
          scan((allOrganizations: Organization[], newOrganizations: Organization[]) => allOrganizations.concat(newOrganizations)),
          tap((organizations) => {
            this._organizations = organizations
            this._organizationsOffset += 10
            this.loadingOrganizations = false
          }),
          catchError(() => [])
        )
      })
    )
  }

  private _listenForSubsidiaryChanges(): void {
    this.user?.subsidiary_id?.valueChanges.subscribe(() => {
      this._resetSubsidiaryAccesses()
      this._resetLocationRestrictions()
      this._setSubsidiaryAccessOptions()
    })
  }

  private _resetSubsidiaryAccesses(): void {
    this.user?.subsidiary_accesses.setValue(this.user.is_user_super_admin || this.user.is_user_admin ? this.subsidiaryAccessOptions : [this.user.subsidiary.as_dict])
  }

  private _resetLocationRestrictions(): void {
    this.user?.location_id.setValue(null)
  }
  private _setSubsidiaryAccessOptions(): void {
    this.subsidiaryAccessOptions = this.user.organization?.subsidiaries?.map((subsidiary) => subsidiary.as_dict)
  }
}
