import { Injectable } from '@angular/core'
import { RestApiService } from '../../services/rest-api.service'
import { Tag } from './tag.model'
import { Observable, of } from 'rxjs'
import { catchError, map, tap } from 'rxjs/operators'
import { ITag } from '../../global.models'
import { productFilters } from '../products/products.mock'

export enum ETagCategory {
  section_type = 'section_type',
  meat_type = 'meat_type',
  dietary = 'dietary',
  irritant = 'irritant',
  season = 'season',
  kitchen = 'kitchen',
  other = 'other',
  ingredient_tag = 'ingredient_tag',
  certification = 'certification',
  characteristic = 'characteristic',
  storage = 'storage'
}

export const categoryNames: {} = {
  section_type: $localize`Sektionstype`,
  meat_type: $localize`Kødtype`,
  dietary: $localize`Kosttype`,
  irritant: $localize`Irritant`,
  season: $localize`Sæson`,
  kitchen: $localize`Køkken`,
  other: $localize`Andet`,
  ingredient_tag: $localize`Ingrediensinformation`,
  storage: $localize`Køletype`,
  certification: $localize`Mærkninger`,
  characteristic: $localize`Egenskaber`
}

export const ingredientTagSorting = {
  oeko: 1,
  eu_organic: 2,
  keyhole: 3,
  local: 4,
  animal_welfare_1: 5,
  animal_welfare_2: 6,
  animal_welfare_3: 7,
  processed_1: 8,
  processed_2: 9,
  processed_3: 10,
  processed_4: 11,
  nutri_score_a: 12,
  nutri_score_b: 13,
  nutri_score_c: 14,
  nutri_score_d: 15,
  nutri_score_e: 16
}

@Injectable({
  providedIn: 'root'
})
export class TagsService {
  tags: Tag[] = []

  constructor(private restApiService: RestApiService) {}

  public getTags(): Observable<Tag[]> {
    return this.restApiService.getTags().pipe(
      tap((tags: ITag[]) => (this.tags = tags.map((tag) => new Tag(tag)))),
      map(() => this.tags)
    )
  }

  public getTagsForCategory(category: ETagCategory): Observable<Tag[]> {
    const params = { category }
    return this.restApiService.getTags(params).pipe(
      tap((tags: ITag[]) => tags.forEach((tag) => this._addToTags(new Tag(tag)))),
      map(() => this.tagsForCategory(category))
    )
  }

  public tagsForCategory(category: ETagCategory): Tag[] {
    return this.tags
      .filter((tag) => tag.category == category)
      .sort((a, b) => {
        return ingredientTagSorting[a.id] <= ingredientTagSorting[b.id] ? -1 : 1
      })
  }

  public getProductFilters(): Observable<{ category: string; categoryName: string; tags: Tag[] }[]> {
    return this.restApiService.httpRequest('tags', 'get', null, { category: 'product_tags' }).pipe(
      catchError((error) => {
        console.error('Error fetching product filters:', error)
        return of(productFilters)
      }),
      map(() => productFilters as any),
      map((tags) => tags.map((tag) => new Tag(tag))),
      map((tags) => {
        return Object.values(
          tags.reduce((acc, tag) => {
            if (!acc[tag.category]) {
              acc[tag.category] = {
                category: tag.category,
                categoryName: categoryNames[tag.category] || tag.category,
                tags: []
              }
            }
            acc[tag.category].tags.push(tag)
            return acc
          }, {})
        )
      })
    )
  }

  public tagWithId(tag_id: string): Tag {
    return this.tags.find((tag) => tag.id == tag_id)
  }

  private _addToTags(tag: Tag) {
    if (!this.tagWithId(tag.id)) this.tags.push(tag)
  }

  public categoryLabel(category: ETagCategory): string {
    switch (category) {
      case ETagCategory.dietary:
        return $localize`Kosttyper`
      case ETagCategory.irritant:
        return $localize`Irritanter`
      case ETagCategory.section_type:
        return $localize`Ret type`
      case ETagCategory.season:
        return $localize`Sæson`
      case ETagCategory.kitchen:
        return $localize`Køkken`
      case ETagCategory.meat_type:
        return $localize`Kødtyper`
      case ETagCategory.ingredient_tag:
        return $localize`Ingrediensinformation`
      case ETagCategory.certification:
        return $localize`Certificeringer`
      case ETagCategory.characteristic:
        return $localize`Karakteristik`
      case ETagCategory.storage:
        return $localize`Opbevaringsinformation`
    }
  }
}
