export function caseString(string: string, capitalization: 'none' | 'upperCase' | 'titleCase' | 'lowerCase'): string {
  if (!string) return ''
  switch (capitalization) {
    case 'upperCase':
      return string.toUpperCase()
    case 'titleCase':
      return capitalizeFirstLetter(string)
    case 'lowerCase':
      return string.toLowerCase()
    default:
      return string
  }
}

export function capitalizeFirstLetter(string: string): string {
  return string.charAt(0).toUpperCase() + string.slice(1)
}

export function arrayToString(
  array: string[],
  separator: string = ', ',
  last_separator: string = $localize` og `,
  firstItemCapitalization: 'none' | 'upperCase' | 'titleCase' | 'lowerCase' = 'none',
  allItemCapitalization: 'none' | 'upperCase' | 'titleCase' | 'lowerCase' = 'none',
  max?: number
): string {
  let string = ''
  array.forEach((element, index) => {
    const casedElement = caseString(element, index == 0 ? firstItemCapitalization : allItemCapitalization)
    if ((max && index < max) || max == undefined) string += casedElement
    if (index < (max != null ? Math.min(array.length - 2, max - 1) : array.length - 2)) string += separator
    if (index == (max != null ? Math.min(array.length - 2, max - 1) : array.length - 2)) string += last_separator
    if (max && index == max) string += array.length - max + $localize` flere`
  })
  return string
}
