import { Component, Input } from '@angular/core'
import { MatMenuPanel } from '@angular/material/menu'

@Component({
  selector: 'nested-stepper-button',
  templateUrl: './nested-stepper-button.component.html'
})
export class NestedStepperButtonComponent {
  @Input() decreaseDisabled: boolean
  @Input() increaseDisabled: boolean
  @Input() counter: number
  @Input() matMenuTriggerFor: MatMenuPanel<any>

  constructor() {}
}
