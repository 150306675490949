import { NgModule } from '@angular/core'
import { MatAutocompleteScrollDirective } from '../../utils/mat-component-directives/mat-autocomplete-scroll.directive'
import { MatSelectScrollDirective } from '../../utils/mat-component-directives/mat-select-scroll.directive'

@NgModule({
  imports: [],
  declarations: [MatAutocompleteScrollDirective, MatSelectScrollDirective],
  exports: [MatAutocompleteScrollDirective, MatSelectScrollDirective],
  providers: []
})
export class MatAngularExtensionsModule {}
