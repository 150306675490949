<header class="flex flex-row justify-between items-center flex-wrap gap-2 md:gap-4 mb-4">
  <label class="flex-grow text-xl font-bold">User</label>
  <nav class="flex flex-row gap-2 md:gap-4 justify-end items-center">
    <custom-button text="Gem" [disabled]="!user.valid || !user.changed" [spinner]="user.saving" (click)="saveUser()"> </custom-button>
  </nav>
</header>

<main class="flex flex-col gap-2 md:gap-4 overflow-auto scrollbar-hidden">
  <section class="flex flex-col gap-2 rounded bg-gray-50 p-4">
    <label class="text-sm font-bold">General</label>

    <section class="flex flex-col gap-1">
      <label class="text-sm">User ID</label>
      <mat-form-field class="outline-input-field min-w-56 w-80 max-w-80" appearance="outline">
        <input autocomplete="off" matInput [formControl]="user.id" />
      </mat-form-field>
    </section>

    <section class="flex flex-col gap-1">
      <label class="text-sm">Azure Object ID</label>
      <mat-form-field class="outline-input-field min-w-56 w-80 max-w-80" appearance="outline">
        <input autocomplete="off" matInput [formControl]="user.azure_user_id" />
      </mat-form-field>
    </section>

    <section class="flex flex-col gap-1">
      <label class="text-sm">First name</label>
      <mat-form-field class="outline-input-field min-w-56 w-80 max-w-80" appearance="outline">
        <input autocomplete="off" matInput [formControl]="user.first_name" />
        <mat-error *ngIf="user.first_name.hasError('required')">First name is required</mat-error>
      </mat-form-field>
    </section>

    <section class="flex flex-col gap-1">
      <label class="text-sm">Last name</label>
      <mat-form-field class="outline-input-field min-w-56 w-80 max-w-80" appearance="outline">
        <input autocomplete="off" matInput [formControl]="user.last_name" />
        <mat-error *ngIf="user.last_name.hasError('required')">Last name is required</mat-error>
      </mat-form-field>
    </section>

    <section class="flex flex-col gap-1">
      <label class="text-sm">Email</label>
      <mat-form-field class="outline-input-field min-w-56 w-80 max-w-80" appearance="outline">
        <input autocomplete="off" matInput [formControl]="user.email" />
        <mat-error *ngIf="user.email.hasError('required')">Email is required</mat-error>
      </mat-form-field>
    </section>

    <section class="flex flex-col gap-1">
      <label class="text-sm">Title</label>
      <drop-down-picker [selection]="user.title" [options]="userTitles" value_key="value" [option_path]="['label']" class="text-xs min-w-56 w-80 max-w-80"></drop-down-picker>
    </section>
  </section>

  <section class="flex flex-col gap-2 rounded bg-gray-50 p-4">
    <label class="text-sm font-bold">Access settings</label>

    <section class="flex flex-col gap-1">
      <label class="text-sm">Access role</label>
      <ng-container *ngIf="accessRoles$ | async as accessRoles">
        <drop-down-picker [selection]="user.access_role_id" [options]="accessRoles" value_key="id" [option_path]="['name']" class="text-xs min-w-56 w-80 max-w-80"></drop-down-picker>
      </ng-container>
    </section>

    <section class="flex flex-col gap-1">
      <label class="text-sm">Domain accesses</label>
      <ng-container *ngIf="domains$ | async as domains">
        <drop-down-picker [selection]="user.domains" [options]="domains" comparison_key="id" all_option_label="All domains" [multiple]="true" [option_path]="['name']" class="text-xs min-w-56 w-80 max-w-80"></drop-down-picker>
      </ng-container>
    </section>

    <section class="flex flex-col gap-1">
      <label class="text-sm">Subsidiary allocation</label>
      <subsidiary-picker [selection]="user.subsidiary_id"></subsidiary-picker>
    </section>

    <section *ngIf="user.subsidiary?.locations?.length > 1" class="flex flex-col gap-1">
      <label class="text-sm">Location limitations</label>
      <drop-down-picker [selection]="user.location_id" [options]="user.subsidiary?.locations" value_key="id" [option_path]="['name', 'value']" class="text-xs min-w-56 w-80 max-w-80"></drop-down-picker>
    </section>

    <section *ngIf="subsidiaryAccessOptions?.length > 1" class="flex flex-col gap-1">
      <label class="text-sm">Subsidiary accesses</label>
      <drop-down-picker [selection]="user.subsidiary_accesses" [options]="subsidiaryAccessOptions" comparison_key="id" all_option_label="All subsidiaries" [multiple]="true" [option_path]="['name']" class="text-xs min-w-56 w-80 max-w-80"></drop-down-picker>
    </section>
  </section>
  <section class="flex flex-col gap-2 rounded bg-gray-50 p-4">
    <label class="text-sm font-bold">Platform settings</label>

    <section class="flex flex-col gap-1">
      <label class="text-sm">Language</label>
      <drop-down-picker [selection]="user.preferred_language" [options]="locales" value_key="code" [option_path]="['label']" class="text-xs min-w-56 w-80 max-w-80"></drop-down-picker>
    </section>
  </section>
</main>
