<header class="flex justify-between items-center mt-3 flex-wrap gap-2">
  <input class="flex-grow !min-w-1/3 !max-w-72" autocomplete="off" matInput placeholder="Search for serial" [formControl]="ESLGatewaySearchControl" />
  <custom-button text="Register ESL Gateway" (click)="openESLGateway()" class="whitespace-nowrap"></custom-button>
</header>

<main *ngIf="ESLGateways$ | async as ESLGateways">
  <ng-container *ngIf="ESLGateways.length; else noESLGateways">
    <mat-card *ngFor="let ESLGateway of ESLGateways; let ESLGatewayIndex = index" class="m-3 cursor-pointer" (click)="openESLGateway(ESLGateway)">
      <div class="flex justify-between items-center flex-wrap gap-2">
        <label class="font-bold text-xs cursor-pointer"><mat-icon class="font-bold !text-xs !w-3 !h-3 mx-1">tablet_android</mat-icon>{{ ESLGateway.code.value }}</label>
        <location-picker [selection]="ESLGateway.locationId" (selectionChange)="ESLGateway.save().subscribe()" (click)="$event.stopImmediatePropagation()"></location-picker>
      </div>
    </mat-card>
  </ng-container>

  <ng-template #noESLGateways>
    <div *ngIf="(ESLGatewaySearchControl.value || selectedLocationIds.length) && !loadingESLGateways" class="w-full flex flex-col justify-center items-center mt-4">
      <label>No ESL gateways found!</label>
    </div>
  </ng-template>
</main>

<div *ngIf="loadingESLGateways" class="w-full flex flex-col justify-center items-center mt-4">
  <mat-spinner [diameter]="25" color="primary"></mat-spinner>
  <label>Loading ESL gateways ...</label>
</div>

<div *ngIf="moreESLGatewaysAvailable" class="flex flex-row justify-center items-center mt-4">
  <custom-button type="secondary" text="Load more ESL gateways" (click)="loadMoreELSGateways()"></custom-button>
</div>
