import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import { RestApiService } from '../../services/rest-api.service'
import { Observable, tap } from 'rxjs'
import { IMenuDish } from '../../global.models'
import { CacheMapService } from '../../services/caching/cache-map.service'

@Injectable({
  providedIn: 'root'
})
export class MenuDishesService {
  constructor(@Inject(LOCALE_ID) public language: string, private restApiService: RestApiService, private _cacheMapService: CacheMapService) {}

  removeDishFromMenuSection(menu_dish_id: string, menu_section_id: string, menu_id: string): Observable<any> {
    const params = {
      menu_dish_id: menu_dish_id,
      menu_section_id: menu_section_id,
      menu_id: menu_id
    }
    return this.restApiService.removeDishFromMenuSection(params)
  }

  updateMenuDish(menuDish: IMenuDish, imageFile?: File): Observable<IMenuDish> {
    const params = {
      fields: 'all',
      language: this.language
    }

    let data: IMenuDish | FormData = menuDish
    if (imageFile) {
      const formData: FormData = new FormData()
      formData.append('file', imageFile, imageFile.name)
      formData.append('json', JSON.stringify(menuDish))
      data = formData
    }

    return this.restApiService.updateMenuDish(data, params)
  }

  public requestTaskStatusForMenuDish(taskID: string): Observable<any> {
    return this.restApiService.httpRequest('celery_task', 'get', undefined, undefined, '/' + taskID).pipe(tap(() => this._cacheMapService.deleteCacheEntryForURL('celery_task')))
  }
}
