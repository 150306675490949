import { Component, Input } from '@angular/core'

@Component({
  selector: 'custom-button',
  templateUrl: './custom-button.component.html',
  styleUrls: ['./custom-button.component.css']
})
export class CustomButtonComponent {
  @Input() type: 'primary' | 'secondary' | 'inverted' = 'primary'
  @Input() size: 'regular' | 'small' = 'regular'
  @Input() text: string = ''
  @Input() disabled: boolean = false
  @Input() icon: string | null = null
  @Input() spinner: boolean = false

  constructor() {}
}
