<mat-button-toggle-group class="!whitespace-nowrap !w-fit !overflow-[unset] cursor-pointer text-black">
  <mat-button-toggle
    class="rounded last-of-type:rounded-tl-none last-of-type:rounded-bl-none [&_.mat-button-toggle-label-content]:size-[30px] [&_.mat-button-toggle-label-content]:!p-0 [&_.mat-button-toggle-label-content]:text-lg [&_.mat-button-toggle-label-content]:z-10"
    ><button [matMenuTriggerFor]="matMenuTriggerFor" [disabled]="decreaseDisabled" class="w-full disabled:text-gray-300">-</button></mat-button-toggle
  >
  <mat-button-toggle
    *ngIf="counter != undefined"
    class="[&_.mat-button-toggle-label-content]:size-[30px] [&_.mat-button-toggle-label-content]:!p-0 [&_.mat-button-toggle-label-content]:text-base [&_.mat-button-toggle-label-content]:leading-[1.8rem] [&_.mat-button-toggle-label-content]:block [&_.mat-button-toggle-label-content]:w-full"
    >{{ counter }}</mat-button-toggle
  >
  <mat-button-toggle
    class="rounded last-of-type:rounded-tl-none last-of-type:rounded-bl-none [&_.mat-button-toggle-label-content]:size-[30px] [&_.mat-button-toggle-label-content]:!p-0 [&_.mat-button-toggle-label-content]:text-lg [&_.mat-button-toggle-label-content]:z-10"
    ><button [matMenuTriggerFor]="matMenuTriggerFor" [disabled]="increaseDisabled" class="w-full disabled:text-gray-300">+</button></mat-button-toggle
  >
</mat-button-toggle-group>
