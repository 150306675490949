import { Injectable } from '@angular/core'
import { merge, Observable, of, Subject } from 'rxjs'
import { catchError, map, scan, tap } from 'rxjs/operators'
import { SubsidiaryService } from '../subsidiary/subsidiary.service'
import { User } from './user.model'
import { IOrganization, IUser } from '../../global.models'
import { RestApiService } from '../../services/rest-api.service'
import { OrganizationsService } from '../organization/organizations.service'
import { LocationsService } from '../location/locations.service'
import { MatSnackBar } from '@angular/material/snack-bar'
import { AdminApiService } from '../../services/admin-api.service'

@Injectable({
  providedIn: 'root'
})
export class UserService {
  user: User

  private _newUser$: Subject<any> = new Subject<any>()

  constructor(
    private restApiService: RestApiService,
    private adminApiService: AdminApiService,
    private subsidiaryService: SubsidiaryService,
    private organizationService: OrganizationsService,
    private locationsService: LocationsService,
    private _snackBar: MatSnackBar
  ) {}

  loadUser(): Observable<IUser> {
    const params = {
      fields: 'all'
    }
    return this.restApiService.getProfileV3(params).pipe(
      tap((user: IUser) => {
        if (user) {
          this.user = new User(user)
          user.subsidiary_accesses.forEach((subsidiary) => this.subsidiaryService.addToSubsidiaries(subsidiary))
        }
      })
    )
  }

  saveProfile(profile: IUser): Observable<IUser> {
    return this.restApiService.saveProfile(profile)
  }

  loadUsers(fields?: string[], search_string?: string, subsidiaryIds?: string[], offset?: number): Observable<User[]> {
    let params = {}
    if (fields) params['fields'] = fields.join(',')
    if (search_string) params['search_string'] = search_string
    if (subsidiaryIds?.length) params['subsidiary_ids'] = subsidiaryIds.join(',')
    if (offset != undefined) {
      params['limit'] = 10
      params['offset'] = offset
    }

    return this.adminApiService.httpRequest('usersAdminURL', 'get', undefined, params).pipe(
      catchError(() => of({ users: [], organizations: [] })),
      tap((response: { users: IUser[]; organizations: IOrganization[] }) => this._storeOrganizations(response?.organizations)),
      map((response) => response.users),
      map((users: IUser[]) => users.map((user) => new User(user)))
    )
  }

  private _storeOrganizations(organizations: IOrganization[]): void {
    organizations?.forEach((organization) => {
      this.organizationService.addToOrganizations(organization)
      organization.subsidiaries?.forEach((subsidiary) => {
        this.subsidiaryService.addToSubsidiaries(Object.assign(subsidiary, { organization_id: organization.id }))
        subsidiary.locations?.forEach((location) => this.locationsService.addToLocations(Object.assign(location, { subsidiary_id: subsidiary.id })))
      })
    })
  }

  saveUser(user: User): Observable<IUser> {
    if (user.is_new)
      return this.adminApiService.createUser(user.as_dict).pipe(
        map((savedUser: IUser[]) => savedUser[0]),
        tap((savedUser) => user.id.setValue(savedUser.id)),
        tap(() => this._newUser$.next(user.as_dict)),
        catchError(() => {
          this._snackBar.open('An error occured while creating the user', 'Close', { duration: 5000 })
          return of(null)
        })
      )
    else
      return this.adminApiService.updateUser(user.as_dict).pipe(
        map((savedUser: IUser[]) => savedUser[0]),
        catchError(() => {
          this._snackBar.open('An error occured while saving the user', 'Close', { duration: 5000 })
          return of(null)
        })
      )
  }

  changeSubsidiary(subsidary_id: string): Observable<any> {
    this.user.subsidiary_id.setValue(subsidary_id)
    return this.adminApiService.updateUser(this.user.as_dict)
  }
}
