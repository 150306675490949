import { Component, Input, OnInit, ViewContainerRef } from '@angular/core'
import { FormControl } from '@angular/forms'
import { ESLGateway, ESLService, Location, Organization, OverlayService, Subsidiary } from 'foodop-lib'
import { exhaustMap, merge, Observable, of, scan, startWith, Subject, switchMap, takeWhile, tap } from 'rxjs'
import { ESLGatewayComponent } from './esl-gateway/esl-gateway.component'

@Component({
  selector: 'esl-gateways-overview',
  templateUrl: './esl-gateways-overview.component.html'
})
export class ESLGatewaysOverviewComponent implements OnInit {
  @Input() customerSelection: FormControl

  public ESLGateways$: Observable<ESLGateway[]>
  public ESLGatewaySearchControl: FormControl = new FormControl('')
  public loadingESLGateways: boolean = false
  public moreESLGatewaysAvailable: boolean = false

  private _gatewaysOffset: number = 0
  private _loadNextESLGateways$ = new Subject()

  constructor(private _ESLService: ESLService, private _overlayService: OverlayService, private _viewContainerRef: ViewContainerRef) {}

  ngOnInit(): void {
    this.ESLGateways$ = this._loadESLGateways()
  }

  public get selectedLocationIds(): string[] {
    return this._selectedLocations.map((location) => location.id)
  }

  private get _selectedLocations(): Location[] {
    return this.customerSelection.value.filter((selectionOption: Location | Subsidiary | Organization) => selectionOption.constructor.name == 'Location') as Location[]
  }

  public openESLGateway(gateway?: ESLGateway): void {
    let onCloseView = (overlayRef) => {
      overlayRef.dispose()
    }

    this._overlayService.openComponentInPopUp(
      this._viewContainerRef,
      ESLGatewayComponent,
      {
        ESLGateway: gateway || new ESLGateway({})
      },
      ['max-w-[80%]', 'max-h-[80%]', 'md:min-w-[500px]'],
      onCloseView,
      onCloseView
    )
  }

  public loadMoreELSGateways(): void {
    this._loadNextESLGateways$.next(true)
  }

  private _loadESLGateways(): Observable<ESLGateway[]> {
    const filterChanges$ = merge(this.ESLGatewaySearchControl.valueChanges, this.customerSelection.valueChanges).pipe(
      startWith(''),
      tap(() => (this._gatewaysOffset = 0))
    )

    return filterChanges$.pipe(
      switchMap(() => {
        return this._loadNextESLGateways$.pipe(
          startWith(0),
          tap(() => (this.loadingESLGateways = true)),
          exhaustMap(() => {
            if (!this._selectedLocations.length && this.ESLGatewaySearchControl.value.length <= 1) return of([])
            return this._ESLService.getESLGateways(this.ESLGatewaySearchControl.value, this.selectedLocationIds, this._gatewaysOffset)
          }),
          takeWhile((gateways) => {
            this.moreESLGatewaysAvailable = gateways.length == 10
            return gateways.length == 10, true
          }),
          scan((allESLGateways: ESLGateway[], newESLGateways: ESLGateway[]) => allESLGateways.concat(newESLGateways)),
          tap(() => {
            this._gatewaysOffset += 10
            this.loadingESLGateways = false
          })
        )
      })
    )
  }
}
