import { Injectable } from '@angular/core'
import { Resolve } from '@angular/router'
import { Observable, switchMap } from 'rxjs'
import { AllergensService } from '../models/allergen/allergens.service'
import { IAllergen } from '../global.models'
import { UserResolver } from './user.resolver'

@Injectable({
  providedIn: 'root'
})
export class RecipeResolver implements Resolve<any> {
  constructor(private _userResolver: UserResolver, private _allergensService: AllergensService) {}

  resolve(): Observable<any> {
    return this._userResolver.resolve().pipe(switchMap(() => this._loadAllergens()))
  }

  private _loadAllergens(): Observable<IAllergen[]> {
    return this._allergensService.getAllergensOpen()
  }
}
