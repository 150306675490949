import { Inject, Injectable } from '@angular/core'
import { HttpClient, HttpHeaders } from '@angular/common/http'
import { Observable, of } from 'rxjs'
import { catchError, map, tap } from 'rxjs/operators'
import { INotificationPOSTRequest, httpOptions } from '../global.types'
import { CacheMapService } from './caching/cache-map.service'
import {
  IAccessRole,
  IBowl,
  IBowlSetup,
  IDishCatalogue,
  IDisplayTemplate,
  IGateway,
  ILocation,
  IMenu,
  IMenuDish,
  IOrganization,
  IMenuPrintTemplate,
  IProcurementDataFile,
  IProcurementProduct,
  IRawIngredient,
  IRecipe,
  IScale,
  IMenuSectionTemplate,
  IMenuTemplate,
  ISubsidiary,
  ITag,
  ITrashTemplate,
  IUser,
  ITrackingTemplate,
  ITrackingGroupTemplate,
  ITracking,
  ITrackingGroup,
  IName,
  IESLGateway,
  IESLLabel
} from '../global.models'
import { MatSnackBar } from '@angular/material/snack-bar'
import { apiEndpoints } from './api-endpoints'
@Injectable({
  providedIn: 'root'
})
export class RestApiService {
  constructor(private http: HttpClient, private cacheMapService: CacheMapService, @Inject('environment') private environment, private snackBar: MatSnackBar) {}

  public httpRequest(endpoint: string, method: string, body: {} = undefined, params: {} = undefined, urlPath: string = ''): Observable<any> {
    httpOptions['params'] = params
    return this.http.request(method, this.environment.apiEndpoint + apiEndpoints[endpoint].url + urlPath, { body: body, params: params })
  }

  loadMenuByID(params): Observable<IMenu> {
    httpOptions['params'] = params
    return this.http.get<IMenu>(this.environment.apiEndpoint + apiEndpoints.menusUrlV2.url, httpOptions).pipe(catchError(this.handleError<IMenu>('loadMenuByID')))
  }

  /***********************************************************/
  /*                        Insights                         */
  /***********************************************************/

  loadInsights(params: any, body: any): Observable<any> {
    httpOptions['params'] = params
    return this.http.post<any>(this.environment.apiEndpoint + apiEndpoints.insights.url, body, httpOptions).pipe(catchError(this.handleError<any>('loadInsights')))
  }

  loadProcurementInsights(params: any, body: any): Observable<any> {
    httpOptions['params'] = params
    return this.http.post<any>(this.environment.apiEndpoint + apiEndpoints.procurement_insights.url, body, httpOptions).pipe(catchError(this.handleError<any>('loadProcurementInsights')))
  }

  loadInsightsOpen(params: any, body: any): Observable<any> {
    httpOptions['params'] = params
    return this.http.post<any>(this.environment.apiEndpoint + apiEndpoints.insights_open.url, body, httpOptions).pipe(catchError(this.handleError<any>('loadInsights')))
  }

  loadPresentationDataForScaleDish(params: any): Observable<any> {
    httpOptions['params'] = params
    return this.http.get<any>(this.environment.apiEndpoint + apiEndpoints.graph_data.url, httpOptions).pipe(catchError(this.handleError<any>('loadPresentationDataForScaleDish')))
  }

  updateInsightsIncluded(params: any, query_filters: any): Observable<any> {
    httpOptions['params'] = params
    return this.http.put<any>(this.environment.apiEndpoint + apiEndpoints.insights.url, query_filters, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('insight')),
      catchError(this.handleError<any>('updateInsightsIncluded'))
    )
  }

  updateScaleDish(scale_dish, params) {
    httpOptions['params'] = params
    return this.http.put(this.environment.apiEndpoint + apiEndpoints.scale_dishesV2.url, scale_dish, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('scale_dish')),
      catchError(this.handleError('updateScaleDish'))
    )
  }

  /***********************************************************/
  /*                    Organization                         */
  /***********************************************************/
  loadOrganization(params): Observable<IOrganization> {
    httpOptions['params'] = params
    return this.http.get<IOrganization>(this.environment.apiEndpoint + apiEndpoints.organizationsUrlV3.url, httpOptions).pipe(
      map((results) => results[0]),
      catchError(this.handleError<IOrganization>('loadOrganization'))
    )
  }

  getAllOrganizations(params): Observable<IOrganization[]> {
    httpOptions['params'] = params
    return this.http.get<IOrganization[]>(this.environment.apiEndpoint + apiEndpoints.organizationsAdminURL.url, httpOptions).pipe(catchError(this.handleError<IOrganization[]>('getAllOrganizations')))
  }

  updateOrganization(organization: IOrganization): Observable<null> {
    return this.http.put<any>(this.environment.apiEndpoint + apiEndpoints.organizationsUrlV3.url, organization, httpOptions).pipe(tap(() => this.cacheMapService.deleteCacheEntryForURL('organization')))
  }

  /***********************************************************/
  /*                      Subsidiary                         */
  /***********************************************************/

  getSubsidiary(params): Observable<ISubsidiary> {
    httpOptions['params'] = params
    return this.http.get<ISubsidiary>(this.environment.apiEndpoint + apiEndpoints.subsidiariesUrlV3.url, httpOptions).pipe(
      map((results) => results[0]),
      catchError(this.showErrorSnackBar()),
      catchError(this.handleError<ISubsidiary>('getSubsidiary'))
    )
  }

  createSubsidiary(subsidiary: ISubsidiary): Observable<ISubsidiary> {
    const url = this.environment.apiEndpoint + apiEndpoints.subsidiariesUrlV3.url
    httpOptions['params'] = {}
    return this.http.post<any>(url, subsidiary).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('subsidiary')),
      map((results) => results[0]),
      catchError(this.handleError<any>('createSubsidiary'))
    )
  }

  updateSubsidiary(subsidiary: ISubsidiary): Observable<ISubsidiary> {
    const url = this.environment.apiEndpoint + apiEndpoints.subsidiariesUrlV3.url
    httpOptions['params'] = {}
    return this.http.put<any>(url, subsidiary, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('subsidiary')),
      map((results) => results[0]),
      catchError(this.handleError<any>('updateSubsidiary'))
    )
  }

  patchSubsidiary(subsidiary: ISubsidiary, params): Observable<string> {
    const url = this.environment.apiEndpoint + apiEndpoints.subsidiariesUrlV3.url
    httpOptions['params'] = params
    return this.http.patch<any>(url, subsidiary, httpOptions).pipe(tap(() => this.cacheMapService.deleteCacheEntryForURL('subsidiary')))
  }

  /***********************************************************/
  /*                       Locations                         */
  /***********************************************************/
  loadLocation(params): Observable<ILocation> {
    httpOptions['params'] = params
    return this.http.get<ILocation>(this.environment.apiEndpoint + apiEndpoints.locationsUrlV3.url, httpOptions).pipe(
      map((results) => results[0]),
      catchError(this.handleError<ILocation>('loadLocation'))
    )
  }

  loadLocations(params): Observable<ILocation[]> {
    httpOptions['params'] = params
    return this.http.get<ILocation[]>(this.environment.apiEndpoint + apiEndpoints.locationsUrlV3.url, httpOptions).pipe(catchError(this.handleError<ILocation[]>('loadLocations')))
  }

  /***********************************************************/
  /*                         Users                           */
  /***********************************************************/

  getProfileV3(params): Observable<IUser> {
    httpOptions['params'] = params
    return this.http.get<IUser>(this.environment.apiEndpoint + apiEndpoints.usersUrlV3.url, httpOptions).pipe(
      map((results) => results[0]),
      catchError(this.handleError<IUser>('getProfileV3'))
    )
  }

  saveProfile(profile: IUser): Observable<IUser> {
    return this.http.put<IUser>(this.environment.apiEndpoint + apiEndpoints.usersUrlV3.url, profile, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('user')),
      catchError(() => of(null))
    )
  }

  changeSubsidiaryForUser(params): Observable<IUser> {
    httpOptions['params'] = params
    return this.http.put<IUser>(this.environment.apiEndpoint + apiEndpoints.usersUrlV3.url, null, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('user')),
      catchError(this.handleError<IUser>('changeSubsidiaryForUser'))
    )
  }

  /***********************************************************/
  /*                     Menu Dishes                         */
  /***********************************************************/

  updateMenuDish(data: IMenuDish | FormData, params): Observable<IMenuDish> {
    const httpOptions = {
      headers: new HttpHeaders(),
      params: params
    }
    return this.http.put<IMenuDish>(this.environment.apiEndpoint + apiEndpoints.menuDishesUrlV3.url, data, httpOptions).pipe(
      tap(() => {
        this.cacheMapService.deleteCacheEntryForURL('dish'), this.cacheMapService.deleteCacheEntryForURL('recipe'), this.cacheMapService.deleteCacheEntryForURL('menu')
      }),
      catchError(this.handleError<any>('updateMenuDish'))
    )
  }

  removeDishFromMenuSection(params): Observable<any> {
    httpOptions['params'] = params
    return this.http.delete<any>(this.environment.apiEndpoint + apiEndpoints.menuDishesUrlV3.url, httpOptions).pipe(
      tap(() => {
        this.cacheMapService.deleteCacheEntryForURL('dish')
        this.cacheMapService.deleteCacheEntryForURL('section')
        this.cacheMapService.deleteCacheEntryForURL('menu')
        this.cacheMapService.deleteCacheEntryForURL('recipe')
      }),
      catchError(this.handleError<any>('removeDishFromMenuSection'))
    )
  }

  /***********************************************************/
  /*                      Raw Dishes                         */
  /***********************************************************/
  getRecipes(params): Observable<IRecipe[] | { recipe_id: string; archived: boolean }> {
    httpOptions['params'] = params
    return this.http.get<IRecipe[] | { recipe_id: string; archived: boolean }>(this.environment.apiEndpoint + apiEndpoints.recipes.url, httpOptions)
  }

  getRecipe(id: string, params): Observable<IRecipe> {
    httpOptions['params'] = params
    return this.http.get<IRecipe>(this.environment.apiEndpoint + apiEndpoints.openRecipes.url + '/' + id, httpOptions).pipe(
      map((results) => {
        return results?.[0]?.['recipes']?.[0]
      }),
      catchError(() => of(null))
    )
  }

  updateRecipe(data: IRecipe | FormData, params): Observable<IRecipe> {
    const httpOptions = {
      headers: new HttpHeaders(),
      params: params
    }
    return this.http.put<IRecipe>(this.environment.apiEndpoint + apiEndpoints.recipes.url, data, httpOptions).pipe(
      tap(() => {
        this.cacheMapService.deleteCacheEntryForURL('dish'), this.cacheMapService.deleteCacheEntryForURL('recipe'), this.cacheMapService.deleteCacheEntryForURL('menu')
      }),
      catchError(this.handleError<any>('updateRecipe'))
    )
  }

  createRecipe(data: IRecipe | FormData, params): Observable<IRecipe> {
    const httpOptions = {
      headers: new HttpHeaders(),
      params: params
    }
    return this.http.post<IRecipe>(this.environment.apiEndpoint + apiEndpoints.recipes.url, data, httpOptions).pipe(
      tap(() => {
        this.cacheMapService.deleteCacheEntryForURL('dish'), this.cacheMapService.deleteCacheEntryForURL('recipe'), this.cacheMapService.deleteCacheEntryForURL('menu')
      }),
      catchError(this.handleError<any>('createRecipe'))
    )
  }

  hideRecipe(recipeID: string): Observable<any> {
    httpOptions['params'] = {}
    return this.http.delete<IRecipe>(this.environment.apiEndpoint + apiEndpoints.recipes.url + '/' + recipeID, httpOptions).pipe(
      tap(() => {
        this.cacheMapService.deleteCacheEntryForURL('dish'), this.cacheMapService.deleteCacheEntryForURL('recipe'), this.cacheMapService.deleteCacheEntryForURL('menu')
      })
    )
  }

  // My raw dish favorites:
  addDishToMyDishes(recipe_id: string): Observable<IRecipe> {
    return this.http.put<IRecipe>(this.environment.apiEndpoint + apiEndpoints.userDishFavoritesUrl.url + '/' + recipe_id, null, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('user_dish')),
      catchError(this.handleError<IRecipe>('addDishToMyDishes'))
    )
  }

  removeDishFromMyDishes(recipe_id: string): Observable<any> {
    return this.http.delete(this.environment.apiEndpoint + apiEndpoints.userDishFavoritesUrl.url + '/' + recipe_id, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('user_dish')),
      catchError(this.handleError('removeDishFromMyDishes'))
    )
  }

  /***********************************************************/
  /*                   Raw ingredients                       */
  /***********************************************************/

  getRawIngredients(params): Observable<{ ingredients: IRawIngredient[]; products: IRawIngredient[] } | IRawIngredient[]> {
    httpOptions['params'] = params
    return this.http.get<{ ingredients: IRawIngredient[]; products: IRawIngredient[] } | IRawIngredient[]>(this.environment.apiEndpoint + apiEndpoints.foods.url, httpOptions)
  }

  createRawIngredient(params, raw_ingredient): Observable<any> {
    httpOptions['params'] = params
    return this.http.post<any>(this.environment.apiEndpoint + apiEndpoints.foods.url, raw_ingredient, httpOptions).pipe(tap(() => this.cacheMapService.deleteCacheEntryForURL('foods')))
  }

  getAllergens(): Observable<any> {
    return this.http.get<any>(this.environment.apiEndpoint + apiEndpoints.allergensUrlV3.url, httpOptions).pipe(catchError(this.handleError<any>('getAllergens')))
  }

  getIngredientSuggestions(params): Observable<IRawIngredient[]> {
    httpOptions['params'] = params
    return this.http.get<IRawIngredient[]>(this.environment.apiEndpoint + apiEndpoints.ai_recipe_foods.url, httpOptions).pipe(catchError(this.handleError<IRawIngredient[]>('getIngredientSuggestions')))
  }

  getIngredientInfo(ingredients: IRawIngredient[], params): Observable<IRawIngredient[]> {
    httpOptions['params'] = params
    return this.http.post<IRawIngredient[]>(this.environment.apiEndpoint + apiEndpoints.ai_recipe_foods.url, ingredients, httpOptions).pipe(catchError(this.handleError<IRawIngredient[]>('getIngredientInfo')))
  }

  getAllergensOpen(): Observable<any> {
    return this.http.get<any>(this.environment.apiEndpoint + apiEndpoints.allergens_open.url, httpOptions).pipe(catchError(this.handleError<any>('getAllergens')))
  }

  loadFBCategories(): Observable<{ id: string; names: IName }[]> {
    httpOptions['params'] = {}
    return this.http.get<{ id: string; names: IName }[]>(this.environment.apiEndpoint + apiEndpoints.fb_categories.url, httpOptions)
  }

  /***********************************************************/
  /*                    Dish catalogues                      */
  /***********************************************************/

  getStandardDishCatalogues(params): Observable<IDishCatalogue[]> {
    httpOptions['params'] = params
    return this.http.get<IDishCatalogue[]>(this.environment.apiEndpoint + apiEndpoints.standardCataloguesUrlV3.url, httpOptions).pipe(catchError(this.handleError<IDishCatalogue[]>('getStandardDishCatalogues')))
  }

  getCustomDishCatalogues(params): Observable<IDishCatalogue[]> {
    httpOptions['params'] = params
    return this.http.get<IDishCatalogue[]>(this.environment.apiEndpoint + apiEndpoints.customCataloguesUrlV3.url, httpOptions).pipe(catchError(this.handleError<IDishCatalogue[]>('getCustomDishCatalogues')))
  }

  createDishCatalogue(dish_catalogue: IDishCatalogue, params): Observable<IDishCatalogue> {
    httpOptions['params'] = params
    return this.http.post<IDishCatalogue>(this.environment.apiEndpoint + apiEndpoints.customCataloguesUrlV3.url, dish_catalogue, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('catalogue')),
      catchError(this.handleError<IDishCatalogue>('createDishCatalogue'))
    )
  }

  updateDishCatalogue(dish_catalogue: IDishCatalogue, params): Observable<IDishCatalogue> {
    httpOptions['params'] = params
    return this.http.put<IDishCatalogue>(this.environment.apiEndpoint + apiEndpoints.customCataloguesUrlV3.url, dish_catalogue, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('catalogue')),
      catchError(this.handleError<IDishCatalogue>('updateDishCatalogue'))
    )
  }

  updateStandardDishCatalogueVisibility(params): Observable<any> {
    httpOptions['params'] = params
    return this.http.put<any>(this.environment.apiEndpoint + apiEndpoints.standard_catalogue_visibility.url, null, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('catalogue')),
      catchError(this.handleError<any>('updateStandardDishCatalogueVisibility'))
    )
  }

  updateCustomDishCatalogueVisibility(params): Observable<any> {
    httpOptions['params'] = params
    return this.http.put<any>(this.environment.apiEndpoint + apiEndpoints.custom_catalogue_visibility.url, null, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('catalogue')),
      catchError(this.handleError<any>('updateCustomDishCatalogueVisibility'))
    )
  }

  updateDishCatalogueAllocations(recipe_id: string, catalogue_ids: string[], params): Observable<any> {
    httpOptions['params'] = params
    return this.http.put<any>(this.environment.apiEndpoint + apiEndpoints.dishCatalogueAllocationUrlV3.url + '/' + recipe_id, catalogue_ids, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('catalogue')),
      catchError(this.handleError<any>('updateDishCatalogueAllocations'))
    )
  }

  /***********************************************************/
  /*                   Menu templates                     */
  /***********************************************************/

  getMenuTemplate(params): Observable<IMenuTemplate> {
    httpOptions['params'] = params
    return this.http.get<IMenuTemplate>(this.environment.apiEndpoint + apiEndpoints.menu_templates.url, httpOptions).pipe(map((results) => results[0]))
  }

  getMenuTemplates(params): Observable<IMenuTemplate[]> {
    httpOptions['params'] = params
    return this.http.get<IMenuTemplate[]>(this.environment.apiEndpoint + apiEndpoints.menu_templates.url, httpOptions)
  }

  createMenuTemplate(params: any, template: IMenuTemplate): Observable<IMenuTemplate> {
    httpOptions['params'] = params
    return this.http.post<IMenuTemplate>(this.environment.apiEndpoint + apiEndpoints.menu_templates.url, template, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('menu_template')),
      catchError(this.handleError<IMenuTemplate>('createMenuTemplate'))
    )
  }

  updateMenuTemplate(params: any, template: IMenuTemplate): Observable<IMenuTemplate> {
    httpOptions['params'] = params
    return this.http.put<IMenuTemplate>(this.environment.apiEndpoint + apiEndpoints.menu_templates.url, template, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('menu_template')),
      catchError(this.handleError<IMenuTemplate>('updateMenuTemplate'))
    )
  }

  updateMenuSectionTemplate(params: any, template: IMenuSectionTemplate): Observable<IMenuSectionTemplate> {
    httpOptions['params'] = params
    return this.http.put<IMenuSectionTemplate>(this.environment.apiEndpoint + apiEndpoints.menu_section_templates.url, template, httpOptions).pipe(
      map((results) => results[0]),
      tap(() => this.cacheMapService.deleteCacheEntryForURL('menu_section_template')),
      catchError(this.handleError<IMenuSectionTemplate>('updateMenuSectionTemplate'))
    )
  }

  /***********************************************************/
  /*                   Menu templates                     */
  /***********************************************************/

  createTrackingTemplate(params: any, template: ITrackingTemplate): Observable<ITrackingTemplate> {
    httpOptions['params'] = params
    return this.http.post<ITrackingTemplate>(this.environment.apiEndpoint + apiEndpoints.tracking_templates.url, template, httpOptions).pipe(
      tap(() => {
        this.cacheMapService.deleteCacheEntryForURL('tracking_template'), this.cacheMapService.deleteCacheEntryForURL('tracking_group_template')
      })
    )
  }

  updateTrackingTemplate(params: any, template: ITrackingTemplate): Observable<ITrackingTemplate> {
    httpOptions['params'] = params
    return this.http.put<ITrackingTemplate>(this.environment.apiEndpoint + apiEndpoints.tracking_templates.url, template, httpOptions).pipe(
      tap(() => {
        this.cacheMapService.deleteCacheEntryForURL('tracking_template'), this.cacheMapService.deleteCacheEntryForURL('tracking_group_template')
      })
    )
  }

  getTrackingGroupTemplates(params): Observable<ITrackingGroupTemplate[]> {
    httpOptions['params'] = params
    return this.http.get<ITrackingGroupTemplate[]>(this.environment.apiEndpoint + apiEndpoints.tracking_group_templates.url, httpOptions)
  }
  createTrackingGroupTemplate(params: any, template: ITrackingGroupTemplate): Observable<ITrackingGroupTemplate> {
    httpOptions['params'] = params
    return this.http.post<ITrackingGroupTemplate>(this.environment.apiEndpoint + apiEndpoints.tracking_group_templates.url, template, httpOptions).pipe(tap(() => this.cacheMapService.deleteCacheEntryForURL('tracking_group_template')))
  }

  updateTrackingGroupTemplate(params: any, template: ITrackingGroupTemplate): Observable<ITrackingGroupTemplate> {
    httpOptions['params'] = params
    return this.http.put<ITrackingGroupTemplate>(this.environment.apiEndpoint + apiEndpoints.tracking_group_templates.url, template, httpOptions).pipe(tap(() => this.cacheMapService.deleteCacheEntryForURL('tracking_group_template')))
  }

  /***********************************************************/
  /*                     Display templates                     */
  /***********************************************************/
  loadDisplayTemplates(): Observable<IDisplayTemplate[]> {
    httpOptions['params'] = {}
    return this.http.get<IDisplayTemplate[]>(this.environment.apiEndpoint + apiEndpoints.displayTemplatesUrlV3.url, httpOptions)
  }
  loadDisplayTemplate(params): Observable<IDisplayTemplate> {
    httpOptions['params'] = params
    return this.http.get<IDisplayTemplate>(this.environment.apiEndpoint + apiEndpoints.displayTemplatesUrlV3.url, httpOptions).pipe(
      map((results) => results[0]),
      catchError(this.handleError<IDisplayTemplate>('loadDisplayTemplate'))
    )
  }

  updateDisplayTemplate(display_template, params: any): Observable<any> {
    httpOptions['params'] = params
    return this.http.put<any>(this.environment.apiEndpoint + apiEndpoints.displayTemplatesUrlV3.url, display_template, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('display_template')),
      catchError(this.handleError<any>('updateDisplayTemplate'))
    )
  }

  createDisplayTemplate(display_template, params: any): Observable<any> {
    httpOptions['params'] = params
    return this.http.post<any>(this.environment.apiEndpoint + apiEndpoints.displayTemplatesUrlV3.url, display_template, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('display_template')),
      catchError(this.handleError<any>('createDisplayTemplate'))
    )
  }

  deleteDisplayTemplate(params): Observable<any> {
    httpOptions['params'] = params
    return this.http.delete(this.environment.apiEndpoint + apiEndpoints.displayTemplatesUrlV3.url, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('display_template')),
      catchError(this.handleError<any>('deleteDisplayTemplate'))
    )
  }
  /***********************************************************/
  /*                  Menu Print Template                    */
  /***********************************************************/

  loadMenuPrintTemplates(params): Observable<IMenuPrintTemplate[]> {
    httpOptions['params'] = params
    return this.http.get<IMenuPrintTemplate[]>(this.environment.apiEndpoint + apiEndpoints.menuPrintTemplateUrl.url, httpOptions).pipe(catchError(this.handleError<IMenuPrintTemplate[]>('loadMenuPrintTemplates')))
  }

  createMenuPrintTemplate(menuPrintTemplate: IMenuPrintTemplate, params: any): Observable<IMenuPrintTemplate> {
    httpOptions['params'] = params
    return this.http.post(this.environment.apiEndpoint + apiEndpoints.menuPrintTemplateUrl.url, menuPrintTemplate, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('menu_print_template')),
      catchError(this.handleError<any>('createMenuPrintTemplate'))
    )
  }

  updateMenuPrintTemplate(menuPrintTemplate: IMenuPrintTemplate): Observable<IMenuPrintTemplate> {
    httpOptions['params'] = {}
    return this.http.put(this.environment.apiEndpoint + apiEndpoints.menuPrintTemplateUrl.url, menuPrintTemplate, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('menu_print_template')),
      catchError(this.handleError<any>('createMenuPrintTemplate'))
    )
  }

  deleteMenuPrintTemplate(params): Observable<any> {
    httpOptions['params'] = params
    return this.http.delete(this.environment.apiEndpoint + apiEndpoints.menuPrintTemplateUrl.url, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('menu_print_template')),
      catchError(this.handleError<any>('deleteMenuPrintTemplate'))
    )
  }

  /***********************************************************/
  /*                      BLOB Files                         */
  /***********************************************************/

  getBlobFiles(params): Observable<any[]> {
    httpOptions['params'] = params
    return this.http.get<any[]>(this.environment.apiEndpoint + apiEndpoints.blob_files.url, httpOptions).pipe(catchError(this.handleError<any[]>('getImage')))
  }

  uploadBlobFile(formData: FormData, params): Observable<any> {
    const http_options = {
      params: params
    }
    return this.http.post<any>(this.environment.apiEndpoint + apiEndpoints.blob_files.url, formData, http_options).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('blob_files')),
      catchError(this.handleError<any>('uploadImage'))
    )
  }

  deleteBlobFile(params): Observable<string> {
    const http_options = {
      params: params
    }
    return this.http.delete<string>(this.environment.apiEndpoint + apiEndpoints.blob_files.url, http_options).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('blob_files')),
      catchError(this.handleError<string>('deleteBlobFile'))
    )
  }

  loadFileFromUrl(url: string): Observable<any> {
    return this.http.get(url, { responseType: 'blob' }).pipe(catchError(this.handleError<any>('loadFileFromUrl')))
  }
  /***********************************************************/
  /*                         Menus                           */
  /***********************************************************/

  getMenus(params): Observable<IMenu[]> {
    httpOptions['params'] = params
    return this.http.get<IMenu[]>(this.environment.apiEndpoint + apiEndpoints.menusUrlV3.url, httpOptions)
  }

  getMenusOpen(params): Observable<IMenu[]> {
    httpOptions['params'] = params
    return this.http.get<IMenu[]>(this.environment.apiEndpoint + apiEndpoints.menus_open.url, httpOptions).pipe(catchError(this.handleError<IMenu[]>('getMenus')))
  }

  createMenuWithSectionAndDish(params, menu_dict): Observable<any> {
    httpOptions['params'] = params
    return this.http.post<any>(this.environment.apiEndpoint + apiEndpoints.menusUrlV3.url, menu_dict, httpOptions).pipe(
      tap(() => {
        this.cacheMapService.deleteCacheEntryForURL('dish')
        this.cacheMapService.deleteCacheEntryForURL('section')
        this.cacheMapService.deleteCacheEntryForURL('menu')
        this.cacheMapService.deleteCacheEntryForURL('recipe')
      }),
      catchError(this.handleError<any>('createMenuWithSectionAndDish'))
    )
  }

  updateMenu(params, menu: IMenu): Observable<any> {
    httpOptions['params'] = params
    return this.http.put<any>(this.environment.apiEndpoint + apiEndpoints.menusUrlV3.url, menu, httpOptions).pipe(
      tap(() => {
        this.cacheMapService.deleteCacheEntryForURL('dish')
        this.cacheMapService.deleteCacheEntryForURL('section')
        this.cacheMapService.deleteCacheEntryForURL('menu')
        this.cacheMapService.deleteCacheEntryForURL('recipe')
      }),
      catchError(this.handleError<any>('updateMenu'))
    )
  }

  createMenu(params, menu: IMenu): Observable<any> {
    httpOptions['params'] = params
    return this.http.post<any>(this.environment.apiEndpoint + apiEndpoints.menusUrlV3.url, menu, httpOptions).pipe(
      tap(() => {
        this.cacheMapService.deleteCacheEntryForURL('dish')
        this.cacheMapService.deleteCacheEntryForURL('section')
        this.cacheMapService.deleteCacheEntryForURL('menu')
        this.cacheMapService.deleteCacheEntryForURL('recipe')
      }),
      catchError(this.handleError<any>('createMenu'))
    )
  }

  deleteMenu(params): Observable<any> {
    httpOptions['params'] = params
    return this.http.delete<any>(this.environment.apiEndpoint + apiEndpoints.menusUrlV3.url, httpOptions).pipe(
      tap(() => {
        this.cacheMapService.deleteCacheEntryForURL('menu')
      })
    )
  }
  /***********************************************************/
  /*                     Menu sections                       */
  /***********************************************************/

  deleteMenuSection(params): Observable<any> {
    httpOptions['params'] = params
    return this.http.delete<any>(this.environment.apiEndpoint + apiEndpoints.menu_sections.url, httpOptions).pipe(
      tap(() => {
        this.cacheMapService.deleteCacheEntryForURL('section')
        this.cacheMapService.deleteCacheEntryForURL('menu')
      })
    )
  }

  /***********************************************************/
  /*                     Translation                         */
  /***********************************************************/

  getTranslationSuggestions(translations, params): Observable<any> {
    httpOptions['params'] = params
    return this.http.post<any>(this.environment.apiEndpoint + apiEndpoints.translationSuggestionsUrlV3.url, translations, httpOptions).pipe(catchError(this.handleError<any>('getTranslationSuggestions')))
  }

  saveTranslations(body, params): Observable<any> {
    httpOptions['params'] = params
    return this.http.post<any>(this.environment.apiEndpoint + apiEndpoints.translations.url, body, httpOptions).pipe(catchError(this.handleError<any>('saveTranslations')))
  }

  /***********************************************************/
  /*                   Trash templates                       */
  /***********************************************************/

  loadTrashTemplates(params): Observable<ITrashTemplate[]> {
    httpOptions['params'] = params
    return this.http.get<ITrashTemplate[]>(this.environment.apiEndpoint + apiEndpoints.trash_templates.url, httpOptions).pipe()
  }

  createTrashTemplate(trash_template: ITrashTemplate, params: any): Observable<any> {
    httpOptions['params'] = params
    return this.http.post(this.environment.apiEndpoint + apiEndpoints.trash_templates.url, trash_template, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('trash_template')),
      catchError(this.handleError<any>('createTrashTemplate'))
    )
  }

  updateTrashTemplate(trash_template: ITrashTemplate, params: any): Observable<any> {
    httpOptions['params'] = params
    return this.http.put(this.environment.apiEndpoint + apiEndpoints.trash_templates.url, trash_template, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('trash_template')),
      catchError(this.handleError<any>('createTrashTemplate'))
    )
  }

  /***********************************************************/
  /*                       Trackings                          */
  /***********************************************************/

  getAdminTrackings(params: object): Observable<ITracking[] | number> {
    httpOptions['params'] = params
    return this.http.get<ITracking[] | number>(this.environment.apiEndpoint + apiEndpoints.admin_trackings.url, httpOptions).pipe(catchError(this.handleError<ITracking[] | number>('getTrackings')))
  }

  loadTrackings(params): Observable<ITracking[]> {
    httpOptions['params'] = params
    return this.http.get<ITracking[]>(this.environment.apiEndpoint + apiEndpoints.trackings.url, httpOptions)
  }

  createTracking(tracking: ITracking, params): Observable<any> {
    httpOptions['params'] = params
    return this.http.post(this.environment.apiEndpoint + apiEndpoints.trackings.url, tracking, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('tracking')),
      catchError(this.handleError<any>('createTracking'))
    )
  }

  updateTracking(tracking: ITracking, params): Observable<any> {
    httpOptions['params'] = params
    return this.http.put(this.environment.apiEndpoint + apiEndpoints.trackings.url, tracking, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('tracking')),
      catchError(this.handleError<any>('updateTracking'))
    )
  }

  deleteTracking(params) {
    httpOptions['params'] = params
    return this.http.delete(this.environment.apiEndpoint + apiEndpoints.trackings.url, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('tracking')),
      catchError(this.handleError<any>('deleteTracking', true))
    )
  }

  loadESLTrackings(params): Observable<ITracking[]> {
    httpOptions['params'] = params
    return this.http.get<ITracking[]>(this.environment.apiEndpoint + apiEndpoints.esl_trackings.url, httpOptions)
  }

  createESLTracking(tracking: ITracking): Observable<ITracking> {
    return this.http.post(this.environment.apiEndpoint + apiEndpoints.esl_trackings.url, tracking).pipe(tap(() => this.cacheMapService.deleteCacheEntryForURL('esl_trackings')))
  }

  updateESLTracking(tracking: ITracking): Observable<any> {
    return this.http.put(this.environment.apiEndpoint + apiEndpoints.esl_trackings.url, tracking).pipe(tap(() => this.cacheMapService.deleteCacheEntryForURL('esl_trackings')))
  }

  deleteESLTracking(trackingId: string) {
    return this.http.delete(this.environment.apiEndpoint + apiEndpoints.esl_trackings.url + '/' + trackingId).pipe(tap(() => this.cacheMapService.deleteCacheEntryForURL('esl_trackings')))
  }

  loadTrackingGroups(params): Observable<ITrackingGroup[]> {
    httpOptions['params'] = params
    return this.http.get<ITrackingGroup[]>(this.environment.apiEndpoint + apiEndpoints.tracking_groups.url, httpOptions).pipe(catchError(this.handleError<ITrackingGroup[]>('loadTrackingGroups')))
  }

  updateTrackingGroups(trackingGroups: ITrackingGroup[]): Observable<ITrackingGroup[]> {
    httpOptions['params'] = {}
    return this.http.put<ITrackingGroup[]>(this.environment.apiEndpoint + apiEndpoints.tracking_groups.url, trackingGroups, httpOptions).pipe(tap(() => this.cacheMapService.deleteCacheEntryForURL('tracking_group')))
  }

  /***********************************************************/
  /*                         Tags                            */
  /***********************************************************/

  getTags(params?): Observable<ITag[]> {
    httpOptions['params'] = params
    return this.http.get<ITag[]>(this.environment.apiEndpoint + apiEndpoints.tags.url, httpOptions)
  }

  /***********************************************************/
  /*                         Procurement                            */
  /***********************************************************/

  getProcurementData(params): Observable<any> {
    httpOptions['params'] = params
    return this.http.get<any>(this.environment.apiEndpoint + apiEndpoints.procurement_data.url, httpOptions).pipe(catchError(this.handleError<any>('getProcurementData')))
  }

  matchProcurementFileColumns(headers, params): Observable<any> {
    httpOptions['params'] = params
    return this.http.post(this.environment.apiEndpoint + apiEndpoints.ai_column_matching.url, headers, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('ai_column_matching')),
      catchError(this.handleError<any>('matchProcurementFileColumns'))
    )
  }

  createProcurementData(procurement_file: IProcurementDataFile, params): Observable<any> {
    httpOptions['params'] = params
    return this.http.post(this.environment.apiEndpoint + apiEndpoints.procurement_data.url, procurement_file, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('procurement_data')),
      catchError(this.handleError<any>('createProcurementData'))
    )
  }

  updateProcurementData(procurement_product: IProcurementProduct, params): Observable<IProcurementProduct[]> {
    httpOptions['params'] = params
    return this.http.put(this.environment.apiEndpoint + apiEndpoints.procurement_data.url, procurement_product, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('procurement_data')),
      catchError(this.handleError<any>('updateProcurementData'))
    )
  }

  deleteProcurementData(params) {
    httpOptions['params'] = params
    return this.http.delete(this.environment.apiEndpoint + apiEndpoints.procurement_data.url, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('procurement_data')),
      catchError(this.showErrorSnackBar()),
      catchError(this.handleError<any>('deleteProcurementData'))
    )
  }

  /***********************************************************/
  /*                     Bowl setups                         */
  /***********************************************************/

  getBowlSetupsForSubsidiary(params): Observable<any[]> {
    httpOptions['params'] = params
    return this.http.get<any[]>(this.environment.apiEndpoint + apiEndpoints.bowl_setups.url, httpOptions).pipe(catchError(this.handleError<any[]>('getBowlSetupsForSubsidiary')))
  }

  createBowlSetup(bowl_setup: IBowlSetup, params: any): Observable<any> {
    httpOptions['params'] = params
    return this.http.post(this.environment.apiEndpoint + apiEndpoints.bowl_setups.url, bowl_setup, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('bowl_setup')),
      catchError(this.handleError<any>('createBowlSetup'))
    )
  }

  updateBowlSetup(bowl_setup: IBowlSetup, params: any): Observable<any> {
    httpOptions['params'] = params
    return this.http.put(this.environment.apiEndpoint + apiEndpoints.bowl_setups.url, bowl_setup, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('bowl_setup')),
      catchError(this.handleError<any>('updateBowlSetup'))
    )
  }

  deleteBowlSetup(params) {
    httpOptions['params'] = params
    return this.http.delete(this.environment.apiEndpoint + apiEndpoints.bowl_setups.url, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('bowl_setup')),
      catchError(this.handleError<any>('deleteBowlSetup'))
    )
  }

  /***********************************************************/
  /*                   Selected Bowls                        */
  /***********************************************************/
  updateSelectedBowlSetups(params): Observable<any> {
    httpOptions['params'] = params
    return this.http.put<any>(this.environment.apiEndpoint + apiEndpoints.selected_bowl_setups.url, null, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('bowl_setup')),
      catchError(this.handleError<any>('updateSelectedBowlSetups'))
    )
  }
  updateSelectedTrashBowls(params): Observable<any> {
    httpOptions['params'] = params
    return this.http.put<any>(this.environment.apiEndpoint + apiEndpoints.selected_trash_bowls.url, null, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('trash_bowl')),
      catchError(this.handleError<any>('updateSelectedTrashBowls'))
    )
  }
  /***********************************************************/
  /*                         Bowl                            */
  /***********************************************************/

  getBowlsForSubsidiary(params): Observable<IBowl[]> {
    httpOptions['params'] = params
    return this.http.get<IBowl[]>(this.environment.apiEndpoint + apiEndpoints.bowls.url, httpOptions).pipe(catchError(this.handleError<IBowl[]>('getBowlsForSubsidiary')))
  }

  createBowl(bowl: IBowl, params: any): Observable<any> {
    httpOptions['params'] = params
    return this.http.post(this.environment.apiEndpoint + apiEndpoints.bowls.url, bowl, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('bowl')),
      catchError(this.handleError<any>('createBowl'))
    )
  }

  updateBowl(bowl: IBowl, params: any): Observable<any> {
    httpOptions['params'] = params
    return this.http.put(this.environment.apiEndpoint + apiEndpoints.bowls.url, bowl, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('bowl')),
      catchError(this.handleError<any>('updateBowl'))
    )
  }

  deleteBowl(params) {
    httpOptions['params'] = params
    return this.http.delete(this.environment.apiEndpoint + apiEndpoints.bowls.url, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('bowl')),
      catchError(this.handleError<any>('deleteBowl'))
    )
  }

  /***********************************************************/
  /*                        ESL Gateways                     */
  /***********************************************************/
  loadESLGateways(params): Observable<IESLGateway[]> {
    httpOptions['params'] = params
    return this.http.get<IESLGateway[]>(this.environment.apiEndpoint + apiEndpoints.esl_gateways.url, httpOptions)
  }

  saveESLGateway(ESLGateways: IESLGateway[]): Observable<any> {
    httpOptions['params'] = {}
    return this.http.put<any>(this.environment.apiEndpoint + apiEndpoints.esl_gateways.url, ESLGateways, httpOptions)
  }

  /***********************************************************/
  /*                        ESL Label                     */
  /***********************************************************/
  loadESLLabels(params): Observable<IESLLabel[]> {
    httpOptions['params'] = params
    return this.http.get<IESLLabel[]>(this.environment.apiEndpoint + apiEndpoints.esl_labels.url, httpOptions)
  }
  loadESLLabelsInfo(params): Observable<{ esl_labels: IESLLabel[]; page: number; total: number }> {
    httpOptions['params'] = params
    return this.http.get<{ esl_labels: IESLLabel[]; page: number; total: number }>(this.environment.apiEndpoint + apiEndpoints.esl_label_info.url, httpOptions).pipe(tap(() => this.cacheMapService.deleteCacheEntryForURL(apiEndpoints.esl_label_info.url)))
  }

  createESLLabel(ESLLabels: IESLLabel[]): Observable<any> {
    httpOptions['params'] = {}
    return this.http.post<any>(this.environment.apiEndpoint + apiEndpoints.esl_labels.url, ESLLabels, httpOptions)
  }

  allocateESLLabel(ESLLabel: IESLLabel): Observable<any> {
    httpOptions['params'] = {}
    return this.http.put<any>(this.environment.apiEndpoint + apiEndpoints.esl_labels.url, ESLLabel, httpOptions)
  }

  /***********************************************************/
  /*                        Gateways                         */
  /***********************************************************/
  loadGateways(params): Observable<object> {
    httpOptions['params'] = params
    return this.http.get<object>(this.environment.apiEndpoint + apiEndpoints.gatewaysAdminURL.url, httpOptions).pipe(catchError(this.handleError<object>('loadGateways')))
  }

  saveGateway(scale: IGateway): Observable<IGateway> {
    httpOptions['params'] = {}
    return this.http.put<IGateway>(this.environment.apiEndpoint + apiEndpoints.gatewaysAdminURL.url, scale, httpOptions).pipe(catchError(this.handleError<IGateway>('saveGateway')))
  }

  requestMenderInformation(gateway_id: string): Observable<any> {
    httpOptions['params'] = { gateway_id }
    return this.http.get<IGateway>(this.environment.apiEndpoint + apiEndpoints.mender_status.url, httpOptions).pipe(
      map((results) => results[0]),
      catchError(this.handleError<IGateway>('requestMenderInformation'))
    )
  }

  /***********************************************************/
  /*                          Scales                         */
  /***********************************************************/
  loadScales(params): Observable<object> {
    httpOptions['params'] = params
    return this.http.get<object>(this.environment.apiEndpoint + apiEndpoints.scalesAdminURL.url, httpOptions).pipe(catchError(this.handleError<object>('loadScales')))
  }
  loadScale(params): Observable<IScale> {
    httpOptions['params'] = params
    return this.http.get<IScale>(this.environment.apiEndpoint + apiEndpoints.scalesAdminURL.url, httpOptions).pipe(
      map((result) => result['scales'][0]),
      catchError(this.handleError<IScale>('loadScale'))
    )
  }

  saveScale(scale: IScale): Observable<IScale> {
    httpOptions['params'] = {}
    return this.http.put<IScale>(this.environment.apiEndpoint + apiEndpoints.scalesAdminURL.url, scale, httpOptions).pipe(
      map((results) => results[0]),
      catchError(this.handleError<IScale>('saveScale'))
    )
  }

  swapScales(params): Observable<any> {
    httpOptions['params'] = params
    return this.http.put<any>(this.environment.apiEndpoint + apiEndpoints.scaleSwapURL.url, null, httpOptions).pipe(catchError(this.handleError<any>('swapScales')))
  }

  /***********************************************************/
  /*                     Scale Dishes                        */
  /***********************************************************/

  getSubsidiaryOpen(params): Observable<ISubsidiary> {
    httpOptions['params'] = params
    return this.http.get<ISubsidiary>(this.environment.apiEndpoint + apiEndpoints.subsidiaries_open.url, httpOptions).pipe(
      map((subsidiaries) => subsidiaries[0]),
      catchError(this.handleError<ISubsidiary>('getSubsidiaryOpen'))
    )
  }

  getLocationOpen(params): Observable<ILocation> {
    httpOptions['params'] = params
    return this.http.get<ILocation>(this.environment.apiEndpoint + apiEndpoints.locations_open.url, httpOptions).pipe(
      map((locations) => locations[0]),
      catchError(this.handleError<ILocation>('getLocationOpen'))
    )
  }

  /***********************************************************/
  /*                     Scale Dishes                        */
  /***********************************************************/

  getScaleDishes(params): Observable<any[]> {
    httpOptions['params'] = params
    return this.http.get<any[]>(this.environment.apiEndpoint + apiEndpoints.admin_scale_dishes.url, httpOptions).pipe(catchError(this.handleError<any[]>('getScaleDishes')))
  }

  /***********************************************************/
  /*                  Data download                          */
  /***********************************************************/
  getSubsidiaryData(params): Observable<any[]> {
    httpOptions['params'] = params
    return this.http.get<any[]>(this.environment.apiEndpoint + apiEndpoints.subsidiaryDataUrl.url, httpOptions).pipe(catchError(this.handleError<any[]>('getSubsidiaryData')))
  }

  /***********************************************************/
  /*            Websocket notifications                      */
  /***********************************************************/

  postNotification(data: INotificationPOSTRequest) {
    return this.http.post(this.environment.apiEndpoint + apiEndpoints.notificationUrl.url, data, httpOptions)
  }
  /***********************************************************/
  /*            API Error Handling                           */
  /***********************************************************/

  logError(error: any) {
    return this.http.post(this.environment.apiEndpoint + apiEndpoints.frontend_errors.url, error, httpOptions)
  }

  errors = {}

  handleError<T>(operation = 'operation', return_error?: boolean) {
    return (error: any): Observable<T> => {
      // TODO: better job of transforming error for user consumption
      if (this.errors[operation]) this.errors[operation] += 1
      else this.errors[operation] = 1

      console.log(`${operation} failed: ${error.message} ${this.errors[operation]}`)
      console.error(error) // log to console instead

      // Let the app keep running by returning an empty result.
      return of(return_error ? (error as T) : null)
    }
  }

  showErrorSnackBar<T>(result?: T) {
    return (error: any): Observable<T> => {
      if (error?.error?.Code && error?.error?.Message) {
        this.snackBar.open(error.error.Code + '\n' + error.error.Message, null, {
          horizontalPosition: 'center',
          panelClass: ['snackbar']
        })
      } else {
        this.snackBar.open(error.name + ': ' + error.statusText)
      }
      return of(result as T)
    }
  }
}
