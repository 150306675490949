import { FoodopLibModule } from '../../foodop-lib.module'
import { IShoppingList } from './shopping-list.interface'
import { FormControl } from '@angular/forms'
import moment from 'moment'
import { ShoppingListService } from './shopping-list.service'
import { MenuDish } from '../menu-dish/menu-dish.model'
import { RawIngredient } from '../raw-ingredient/raw-ingredient.model'
import { map, Observable, tap } from 'rxjs'
import { IShoppingListItem } from './shopping-list-item.interface'

export class ShoppingList {
  public id: string
  public subsidiary_id: string
  public date: FormControl
  public addedToCart: boolean
  public items: { menu_dish_id: string; ingredient_id: string; product_id: string; single_amount: number; package_amount: number }[]

  private _shoppingListService: ShoppingListService

  constructor(public shoppingList: IShoppingList) {
    this._shoppingListService = FoodopLibModule.injector.get(ShoppingListService)
    this.patchValues(shoppingList)
  }

  public get asDict(): IShoppingList {
    return {
      id: this.id,
      subsidiary_id: this.subsidiary_id,
      date: this.date.value.format('YYYY-MM-DD'),
      in_cart: this.addedToCart,
      shopping_list_items: this.items
    }
  }

  public get dropDate(): moment.Moment {
    return moment(this.date.value)
  }

  public addItemsToCart(items: { menuDish: MenuDish; ingredient: RawIngredient }[]): Observable<IShoppingListItem[]> {
    return this._shoppingListService
      .upsertItemsToShoppingList(
        this.id,
        items.map((item) => {
          return {
            menu_dish_id: item.menuDish.id,
            ingredient_id: item.ingredient.id,
            product_id: item.ingredient.product.id,
            single_amount: item.ingredient.product.packaging.singleAmountSelection.value,
            package_amount: item.ingredient.product.packaging.colliAmountSelection.value
          }
        })
      )
      .pipe(
        tap((upsertedItems) => {
          upsertedItems.forEach((upsertedItem) => {
            if (!this.items.find((item) => item.menu_dish_id === upsertedItem.menu_dish_id && item.ingredient_id === upsertedItem.ingredient_id)) {
              this.items.push(upsertedItem)
            }
          })
        })
      )
  }

  public delete(): Observable<boolean> {
    return this._shoppingListService.deleteShoppingList(this.id).pipe(map(() => true))
  }

  public patchValues(shoppingList: IShoppingList): void {
    this.id = shoppingList.id
    this.subsidiary_id = shoppingList.subsidiary_id
    this.date = new FormControl(moment(shoppingList.date))
    this.addedToCart = shoppingList.in_cart
    this.items = (shoppingList.shopping_list_items || []).map((item) => ({
      menu_dish_id: item.menu_dish_id,
      ingredient_id: item.ingredient_id,
      product_id: item.product_id,
      single_amount: item.single_amount,
      package_amount: item.package_amount
    }))
  }
}
