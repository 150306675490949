import { Inject, Injectable, LOCALE_ID } from '@angular/core'
import moment from 'moment'
import { RestApiService } from '../../services/rest-api.service'
import { catchError, map, tap } from 'rxjs/operators'
import { Observable, of } from 'rxjs'
import { Menu } from './menu.model'
import { IMenu } from '../../global.models'
import { GlobalFunctionsService } from '../../services/global-functions.service'
import { SubsidiaryService } from '../subsidiary/subsidiary.service'
import { UserService } from '../user/user.service'

@Injectable({
  providedIn: 'root'
})
export class MenusService {
  menus: Menu[] = []

  constructor(@Inject(LOCALE_ID) public language: string, private restApiService: RestApiService, private func: GlobalFunctionsService, private subsidiaryService: SubsidiaryService, private _userService: UserService) {}

  public menuForDateAndMenuTemplateId(date: moment.Moment, menuTemplateId: string): Menu {
    const menu = this.menus.find((menu) => date && menuTemplateId && moment(menu.date.value).format('YYYY-MM-DD') == moment(date).format('YYYY-MM-DD') && menu.menuTemplateId == menuTemplateId)
    return menu
  }

  public menuWithId(menu_id: string): Menu {
    return this.menus.find((menu) => menu.id == menu_id && menu_id)
  }

  public getMenusForDates(start_date: moment.Moment, end_date: moment.Moment, menuTemplateIds: string[]): Observable<IMenu[]> {
    const params = {
      fields: 'menu_sections,menu_dishes,recipes,portions',
      date_start: moment(start_date).format('YYYY-MM-DD') + ' 00:00:00',
      date_end: moment(end_date).format('YYYY-MM-DD') + ' 23:59:59',
      language: this.language,
      active: true
    }
    if (menuTemplateIds) params['menu_template_ids'] = menuTemplateIds.join(',')
    return this.restApiService.getMenus(params).pipe(
      tap((menus: IMenu[]) => {
        menus?.forEach((menu) => this._addToMenus(menu))
        this._createEmptyMenusForMissingDates(menuTemplateIds, start_date, end_date)
      }),
      catchError(() => of([]))
    )
  }

  public getMenusForDatesOpen(start_date: moment.Moment, end_date: moment.Moment, menuTemplateId: string): Observable<IMenu> {
    const params = {
      fields: 'menu_sections,menu_dishes,recipes,portions,serving_template,section_templates',
      date_start: moment(start_date).format('YYYY-MM-DD') + ' 00:00:00',
      date_end: moment(end_date).format('YYYY-MM-DD') + ' 23:59:59',
      language: this.language,
      active: true
    }
    if (menuTemplateId) params['menu_template_id'] = menuTemplateId
    return this.restApiService.getMenusOpen(params).pipe(map((menus) => menus[0]))
  }

  public getMenuByID(menu_id: string): Observable<IMenu> {
    const params = {
      fields: 'all',
      menu_id: menu_id,
      active: true
    }
    return this.restApiService.getMenus(params).pipe(map((menus) => menus[0]))
  }

  private _addToMenus(menu: IMenu): void {
    const existing_menu: Menu = this.menuWithId(menu?.id) || this.menuForDateAndMenuTemplateId(menu?.date, menu?.menu_template_id)
    if (!existing_menu) this.menus.push(new Menu(menu))
    else existing_menu.patchValue(menu)
  }

  private _createEmptyMenusForMissingDates(menuTemplateIds: string[], start_date: moment.Moment, end_date: moment.Moment): void {
    menuTemplateIds.forEach((menuTemplateId) => {
      this.func.getDateRange(start_date, end_date, 'days').map((date) => {
        if (!this.menuForDateAndMenuTemplateId(date, menuTemplateId)) this._addToMenus({ date: date, menu_template_id: menuTemplateId })
      })
    })
  }

  public upsertMenu(menu: Menu, subsidiary_id?: string, original_menu_id?: string, original_menu_section_ids?: string[]): Observable<any> {
    let params = {
      language: this.language,
      subsidiary_id: subsidiary_id || this.subsidiaryService.subsidiary.id,
      populate_recipe_dagrofa_products: this._userService.user?.isDagrofaUser ? true : false
    }
    if (original_menu_id) params['menu_id'] = original_menu_id
    if (original_menu_section_ids) params['menu_section_ids'] = original_menu_section_ids.join()
    if (menu.id) {
      return this.restApiService.updateMenu(params, menu.upload_dict).pipe(
        tap((response) => {
          menu.patchValue(response.menu)
        })
      )
    } else {
      return this.restApiService.createMenu(params, menu.upload_dict).pipe(
        tap((response) => {
          menu.patchValue(response.menu)
        })
      )
    }
  }

  public deleteMenu(menu_id: string): Observable<any> {
    const params = {
      menu_id: menu_id
    }
    return this.restApiService.deleteMenu(params)
  }
}
