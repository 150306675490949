<header class="flex flex-row justify-between items-center flex-wrap gap-2">
  <label class="text-xl font-bold">ESL Label</label>
  <nav class="flex flex-row gap-4">
    <custom-button text="Cancel" (click)="onCloseView()"></custom-button>
    <custom-button class="whitespace-nowrap" [text]="ESLLabel.isRegistered ? 'Save edits' : 'Register'" [spinner]="ESLLabel.loading" [disabled]="!ESLLabel.changed" (click)="onSaveESLLabel()"></custom-button>
  </nav>
</header>
<main class="flex flex-col gap-4 overflow-auto scrollbar-hidden">
  <ng-container *ngIf="ESLLabel.isRegistered; else newESLLabel">
    <section class="flex flex-col gap-2 rounded bg-gray-50 p-4 flex-wrap">
      <label class="text-sm font-bold">Generel</label>
      <section class="flex flex-col gap-1">
        <label class="text-sm">Label code: {{ ESLLabel.labelCode.value }}</label>
        <label class="text-sm">Index: {{ ESLLabel.index.value }}</label>
        <location-picker [selection]="ESLLabel.locationId" (selectionChange)="onAllocateESLLabel()"></location-picker>
      </section>
    </section>
    <section class="flex flex-col gap-2 rounded bg-gray-50 p-4">
      <section class="flex flex-row justify-between gap-2">
        <label class="text-sm font-bold">Status</label>
        <custom-button size="small" [spinner]="ESLLabel.loadingHardwareInfo" [disabled]="ESLLabel.loadingHardwareInfo" text="Request status" (click)="ESLLabel.loadHardwareInfo().subscribe()"></custom-button>
      </section>
      <section class="flex flex-col gap-1">
        <label class="text-sm">
          Signal:
          <mat-icon
            *ngIf="ESLLabel.signal; else unKnownStatus"
            class="font-bold !text-xs !w-3 !h-3 !leading-3 mx-1 text-gray-300"
            [ngClass]="{ '!text-green-500': ESLLabel.signal == 'EXCELLENT', '!text-yellow-500': ESLLabel.signal == 'GOOD', '!text-red-500': ESLLabel.signal == 'BAD' }"
            >{{ ESLLabel.signal == 'BAD' ? 'signal_wifi_off' : 'signal_wifi_4_bar' }}</mat-icon
          >{{ ESLLabel.signal }}
        </label>
        <label class="text-sm">
          Battery:
          <mat-icon *ngIf="ESLLabel.battery; else unKnownStatus" class="font-bold !text-xs !w-3 !h-3 !leading-3 mx-1 text-gray-300" [ngClass]="{ '!text-green-500': ESLLabel.battery == 'GOOD', '!text-red-500': ESLLabel.battery == 'BAD' }">battery_full</mat-icon>
          {{ ESLLabel.battery }}
        </label>
        <label class="text-sm"
          >Status:
          <mat-icon
            *ngIf="ESLLabel.status; else unKnownStatus"
            class="font-bold !text-xs !w-3 !h-3 !leading-3 mx-1 text-gray-300"
            [ngClass]="{ '!text-green-500': ESLLabel.status == 'SUCCESS', '!text-yellow-500': ESLLabel.status == 'PROCESSING', '!text-red-500': ESLLabel.status == 'TIMEOUT' }"
            >{{ ESLLabel.status == 'UNASSIGNED' ? 'link_off' : ESLLabel.status == 'SUCCESS' ? 'check_circle' : ESLLabel.status == 'PROCESSING' ? 'timelapse' : 'timer_off' }}</mat-icon
          >
          {{ ESLLabel.status }}
        </label>

        <label class="text-sm"
          >Last connection time:
          <mat-icon
            *ngIf="ESLLabel.lastConnectionTime; else unKnownStatus"
            class="font-bold !text-xs !w-3 !h-3 !leading-3 mx-1 text-gray-300"
            [ngClass]="{ '!text-green-500': now.diff(ESLLabel.lastConnectionTime, 'minutes') < 30, '!text-yellow-500': now.diff(ESLLabel.lastConnectionTime, 'minutes') < 60, '!text-red-500': now.diff(ESLLabel.lastConnectionTime, 'minutes') > 60 }"
            >access_time</mat-icon
          >
          {{ ESLLabel.lastConnectionTime?.format('DD. MMM HH:mm') }} ({{ ESLLabel.lastConnectionTime?.fromNow() }})
        </label>

        <label class="text-sm"
          >Last response time:
          <mat-icon
            *ngIf="ESLLabel.lastResponseTime; else unKnownStatus"
            class="font-bold !text-xs !w-3 !h-3 !leading-3 mx-1 text-gray-300"
            [ngClass]="{ '!text-green-500': now.diff(ESLLabel.lastResponseTime, 'minutes') < 30, '!text-yellow-500': now.diff(ESLLabel.lastResponseTime, 'minutes') < 60, '!text-red-500': now.diff(ESLLabel.lastResponseTime, 'minutes') > 60 }"
            >access_time</mat-icon
          >
          {{ ESLLabel.lastResponseTime?.format('DD. MMM HH:mm') }} ({{ ESLLabel.lastResponseTime?.fromNow() }})
        </label>
      </section>
    </section>

    <section class="flex flex-col gap-2 rounded bg-gray-50 p-4">
      <section class="flex flex-row justify-between gap-2">
        <label class="text-sm font-bold">Additional info</label>
        <custom-button size="small" [spinner]="ESLLabel.loadingHardwareInfo" [disabled]="ESLLabel.loadingHardwareInfo" text="Request info" (click)="ESLLabel.loadHardwareInfo().subscribe()"></custom-button>
      </section>
      <section class="flex flex-col gap-1">
        <label class="text-sm">
          Model: <span *ngIf="ESLLabel.type; else unKnownStatus">{{ ESLLabel.type }}</span>
        </label>
        <label class="text-sm">
          Display width: <span *ngIf="ESLLabel.displayWidth; else unKnownStatus">{{ ESLLabel.displayWidth }} px</span>
        </label>
        <label class="text-sm">
          Display height: <span *ngIf="ESLLabel.displayHeight; else unKnownStatus">{{ ESLLabel.displayHeight }} px</span>
        </label>
        <label class="text-sm">
          Gateway: <span *ngIf="ESLLabel.gatewayCode; else unKnownStatus">{{ ESLLabel.gatewayCode }}</span>
        </label>
      </section>
    </section>
  </ng-container>

  <ng-template #unKnownStatus>
    <label class="text-sm italic">unknown</label>
  </ng-template>

  <ng-template #newESLLabel>
    <section class="flex flex-col gap-1">
      <label class="text-sm">Label code</label>
      <mat-form-field class="outline-input-field max-w-80" appearance="outline">
        <input autocomplete="off" matInput [formControl]="ESLLabel.labelCode" />
        <mat-error *ngIf="ESLLabel.labelCode.hasError('required')">Label code has to be specified</mat-error>
      </mat-form-field>
    </section>
  </ng-template>
</main>
