import { Injectable } from '@angular/core'
import { Resolve } from '@angular/router'
import { combineLatest, Observable, switchMap } from 'rxjs'
import { MenuTemplatesService } from '../models/menu-templates/menu-templates.service'
import { SubsidiaryService } from '../models/subsidiary/subsidiary.service'
import { AllergensService } from '../models/allergen/allergens.service'
import { TagsService } from '../models/tag/tag.service'
import { Tag } from '../models/tag/tag.model'
import { IAllergen } from '../global.models'
import { UserResolver } from './user.resolver'

@Injectable({
  providedIn: 'root'
})
export class MenuPlanningResolver implements Resolve<any> {
  constructor(private _userResolver: UserResolver, private _menuTemplatesService: MenuTemplatesService, private _allergensService: AllergensService, private _tagsService: TagsService, private _subsidiaryService: SubsidiaryService) {}

  resolve(): Observable<any> {
    return this._userResolver.resolve().pipe(switchMap(() => this._loadData()))
  }

  private _loadData(): Observable<any> {
    return combineLatest(this._loadMenuTemplates(), this._loadAllergens(), this._loadTags())
  }

  private _loadMenuTemplates(): Observable<any[]> {
    return this._menuTemplatesService.getMenuTemplates(this._subsidiaryService.subsidiary.id)
  }

  private _loadAllergens(): Observable<IAllergen[]> {
    return this._allergensService.getAllergens()
  }

  private _loadTags(): Observable<Tag[]> {
    return this._tagsService.getTags()
  }
}
