<mat-button-toggle-group class="!whitespace-nowrap !w-fit !overflow-[unset] cursor-pointer text-black">
  <mat-button-toggle
    class="rounded last-of-type:rounded-tl-none last-of-type:rounded-bl-none [&_.mat-button-toggle-label-content]:size-[30px] [&_.mat-button-toggle-label-content]:!p-0 [&_.mat-button-toggle-label-content]:text-lg [&_.mat-button-toggle-label-content]:z-10"
    ><button (click)="onDecrease(); $event.stopPropagation()" [disabled]="decreaseDisabled" [matTooltip]="decreaseTooltip" matTooltipClass="text-center" class="w-full disabled:text-gray-300">-</button></mat-button-toggle
  >
  <mat-button-toggle *ngIf="counter != undefined" class="[&_.mat-button-toggle-label-content]:size-[30px] [&_.mat-button-toggle-label-content]:!p-0 [&_.mat-button-toggle-label-content]:text-base [&_.mat-button-toggle-label-content]:leading-[1.8rem]">{{
    counter
  }}</mat-button-toggle>
  <mat-button-toggle
    class="rounded last-of-type:rounded-tl-none last-of-type:rounded-bl-none [&_.mat-button-toggle-label-content]:size-[30px] [&_.mat-button-toggle-label-content]:!p-0 [&_.mat-button-toggle-label-content]:text-lg [&_.mat-button-toggle-label-content]:z-10"
    ><button (click)="onIncrease(); $event.stopPropagation()" [disabled]="increaseDisabled" [matTooltip]="increaseTooltip" matTooltipClass="text-center" class="w-full disabled:text-gray-300">+</button></mat-button-toggle
  >
</mat-button-toggle-group>
