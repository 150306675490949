import { FormControl } from '@angular/forms'
import { IProcurementMetrics } from '../../global.models'
import { Names } from '../names/names.model'
import { delay, merge, Observable, Subject } from 'rxjs'

export class ProcurementMetric {
  public id: string
  public type: 'local' | 'msc' | 'animalwelfare'
  public names: Names
  public true: FormControl
  public high: FormControl
  public medium: FormControl
  public low: FormControl
  public excluded: FormControl
  public excludedFBCategorySelection: FormControl
  public excludedTagsSelection: FormControl
  public active: FormControl
  public nameChanges$ = new Subject()

  private _savedProcurementMetric: any

  constructor(public procurementMetrics?: IProcurementMetrics) {
    this.id = procurementMetrics?.id
    this.type = procurementMetrics?.type
    this.names = new Names(procurementMetrics.names)
    this.true = new FormControl(procurementMetrics?.true || [])
    this.high = new FormControl(procurementMetrics?.high || [])
    this.medium = new FormControl(procurementMetrics?.medium || [])
    this.low = new FormControl(procurementMetrics?.low || [])
    this.excluded = new FormControl(procurementMetrics?.excluded || [])
    this.excludedFBCategorySelection = new FormControl(procurementMetrics?.excluded_fb_categories || [])
    this.excludedTagsSelection = new FormControl(procurementMetrics?.excluded_tags || [])
    this.active = new FormControl(procurementMetrics?.active || false)

    this._savedProcurementMetric = JSON.stringify(this.as_dict)
  }

  public get isValid(): boolean {
    return this.names[this.names.language].value.trim() !== '' && this.changed
  }

  public get nameChanged(): boolean {
    return JSON.stringify(this.names.as_dict) != JSON.stringify(JSON.parse(this._savedProcurementMetric).names)
  }

  public get changed(): boolean {
    return this._savedProcurementMetric != JSON.stringify(this.as_dict)
  }

  public get excludedFBCategories(): string[] {
    return this.excludedFBCategorySelection.value.filter((value) => value != 'all')
  }

  public get excludedTags(): string[] {
    return this.excludedTagsSelection.value.filter((value) => value != 'all')
  }

  public get as_dict(): IProcurementMetrics {
    return {
      id: this.id,
      type: this.type,
      names: this.names.as_dict,
      true: this.true.value,
      high: this.high.value,
      medium: this.medium.value,
      low: this.low.value,
      excluded: this.excluded.value,
      excluded_fb_categories: this.excludedFBCategories,
      excluded_tags: this.excludedTags,
      active: this.active.value
    }
  }

  public checkConflict(value: string): string | null {
    if (this.excluded.value.includes(value)) return $localize`Værdien er allerede ekskluderet.`
    else if (this.true.value.includes(value) || this.high.value.includes(value) || this.medium.value.includes(value) || this.low.value.includes(value)) return $localize`Værdien er allerede inkluderet.`
    else return null
  }

  public addValueToField(value: string, field: string): void {
    this[field].setValue([...this[field].value, value])
  }

  public removeValue(index: number, field: string): void {
    this[field].setValue(this[field].value.filter((_, i) => i !== index))
  }

  public removeFBchip(index: number): void {
    this.excludedFBCategorySelection.value.splice(index, 1)
    this.excludedFBCategorySelection.setValue([...this.excludedFBCategorySelection.value])
  }

  public removeTagschip(index: number): void {
    this.excludedTagsSelection.value.splice(index, 1)
    this.excludedTagsSelection.setValue([...this.excludedTagsSelection.value])
  }

  public listenForChanges(): Observable<any> {
    return merge(
      this.true.valueChanges,
      this.high.valueChanges,
      this.medium.valueChanges,
      this.low.valueChanges,
      this.excluded.valueChanges,
      this.excludedFBCategorySelection.valueChanges,
      this.excludedTagsSelection.valueChanges,
      this.active.valueChanges,
      this.nameChanges$
    ).pipe(delay(500))
  }
}
