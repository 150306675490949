import { Inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable, of } from 'rxjs'
import { catchError, map, tap } from 'rxjs/operators'
import { httpOptions } from '../global.types'
import { CacheMapService } from './caching/cache-map.service'
import { IAccessRole, IDomain, IOrganization, ISubsidiary, IUser } from '../global.models'
import { apiEndpoints } from './api-endpoints'

@Injectable({
  providedIn: 'root'
})
export class AdminApiService {
  constructor(private http: HttpClient, private cacheMapService: CacheMapService, @Inject('environment') private environment) {}

  public httpRequest(endpoint: string, method: string, body: {} = undefined, params: {} = undefined): Observable<any> {
    httpOptions['params'] = params
    return this.http.request(method, this.environment.apiEndpoint + apiEndpoints[endpoint].url, { body: body, params: params })
  }

  createAdminSubsidiary(subsidiary: ISubsidiary): Observable<ISubsidiary> {
    return this.http.post<any>(this.environment.apiEndpoint + apiEndpoints.subsidiariesAdminURL.url, subsidiary, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('subsidiary')),
      map((results) => results[0]),
      catchError(() => of({}))
    )
  }

  updateAdminSubsidiary(subsidiary: ISubsidiary): Observable<ISubsidiary> {
    return this.http.put<any>(this.environment.apiEndpoint + apiEndpoints.subsidiariesAdminURL.url, subsidiary, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('subsidiary')),
      map((results) => results[0]),
      catchError(() => of({}))
    )
  }

  getAllAccessRoles(): Observable<IAccessRole[]> {
    return this.http.get<IAccessRole[]>(this.environment.apiEndpoint + apiEndpoints.accessRolesURL.url)
  }

  getDomains(): Observable<IDomain[]> {
    return this.http.get<IDomain[]>(this.environment.apiEndpoint + apiEndpoints.admin_domains.url)
  }

  createUser(profile: IUser): Observable<IUser[]> {
    return this.http.post<IUser[]>(this.environment.apiEndpoint + apiEndpoints.usersAdminURL.url, profile, httpOptions)
  }

  updateUser(profile: IUser): Observable<IUser[]> {
    return this.http.put<IUser[]>(this.environment.apiEndpoint + apiEndpoints.usersAdminURL.url, profile, httpOptions)
  }

  updateAdminOrganization(organization: IOrganization): Observable<IOrganization> {
    return this.http.put<any>(this.environment.apiEndpoint + apiEndpoints.organizationsAdminURL.url, organization, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('organization')),
      map((results) => results[0])
    )
  }
  createAdminOrganization(organization: IOrganization): Observable<IOrganization> {
    return this.http.post<any>(this.environment.apiEndpoint + apiEndpoints.organizationsAdminURL.url, organization, httpOptions).pipe(
      tap(() => this.cacheMapService.deleteCacheEntryForURL('organization')),
      map((results) => results[0])
    )
  }
}
