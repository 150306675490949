import { Injectable } from '@angular/core'
import { Observable, of, map, catchError } from 'rxjs'
import { RestApiService } from '../../services/rest-api.service'
import { Product } from './product.model'
import { IProduct } from './product.interface'
import { Recipe } from '../recipe/recipe.model'
import { IRecipe } from '../../global.models'

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  constructor(private restApiService: RestApiService) {}

  public populateIngredientProducts(recipe: Recipe): Observable<IRecipe> {
    return this.restApiService.httpRequest('populate_recipe_products', 'post', recipe.as_dict).pipe(
      catchError((error) => {
        console.error('Error fetching product:', error)
        return of({})
      })
    )
  }

  public searchProducts(query: string = undefined, ingredientId: string, tagIds: string[] = [], page: number = 0, size: number = 12): Observable<Product[]> {
    let request$ = this.restApiService.httpRequest('dagrofa_products', 'get', null, { ingredient_id: ingredientId, tag_ids: tagIds.join(','), page, size })
    if (query?.length) request$ = this.restApiService.httpRequest('dagrofa_product_search', 'get', null, { q: query, tag_ids: tagIds.join(','), page, size })

    return request$.pipe(
      map((response: { pagination: { page: number; size: number }; products: IProduct[]; total: number }) => response.products.map((product) => new Product(product))),
      catchError((error) => {
        console.error('Error fetching products:', error)
        return of([])
      })
    )
  }

  public verifyProductAvailablity(productIds: string[]): Observable<{ [productId: string]: boolean }> {
    return this.restApiService.httpRequest('verify_product_availability', 'post', { product_ids: productIds }).pipe(
      catchError((error) => {
        console.error('Error verifying product availability:', error)
        return of({})
      })
    )
  }
}
