import { FormControl } from '@angular/forms'
import { FoodopLibModule } from '../../../foodop-lib.module'
import { IESLLabel } from '../../../global.models'
import { ESLService } from '../esl.service'
import { Observable, tap } from 'rxjs'
import { Display } from '../../display/display.model'
import moment from 'moment'

export class ESLLabel {
  public labelCode: FormControl
  public index: FormControl
  public locationId: FormControl
  public status: 'UNASSIGNED' | 'PROCESSING' | 'SUCCESS' | 'TIMEOUT'
  public battery: 'BAD' | 'GOOD'
  public signal: 'BAD' | 'GOOD' | 'EXCELLENT'
  public lastResponseTime: moment.Moment
  public lastConnectionTime: moment.Moment
  public type: string
  public gatewayCode: string
  public displayHeight: number
  public displayWidth: number

  public loading: boolean = false
  public loadingHardwareInfo: boolean = false
  public isRegistered: boolean = false

  public width: number = 400
  public height: number = 300
  public aspectRatio: number = this.width / this.height
  public DOMSizeFactor: number
  public defaultDisplay: Display

  private _savedESLLabel: string

  private _ESLService: ESLService

  constructor(ESLLabel: IESLLabel) {
    this._ESLService = FoodopLibModule.injector.get(ESLService)

    this.labelCode = new FormControl(ESLLabel?.label_code)
    this.index = new FormControl(ESLLabel?.index)
    this.locationId = new FormControl(ESLLabel?.location_id)
    if (this.labelCode.value) this.isRegistered = true

    this._savedESLLabel = JSON.stringify(this.asDict)
  }

  public get changed(): boolean {
    return this._savedESLLabel !== JSON.stringify(this.asDict)
  }

  public get asDict(): IESLLabel {
    return {
      label_code: this.labelCode.value,
      index: this.index.value || undefined,
      location_id: this.locationId.value
    }
  }

  public save(): Observable<any> {
    this.loading = true
    return this._ESLService.createESLLabel(this).pipe(
      tap(() => {
        this._savedESLLabel = JSON.stringify(this.asDict)
        this.loading = false
        this.isRegistered = true
      })
    )
  }

  public allocate(): Observable<any> {
    return this._ESLService.allocateLabel(this)
  }

  public loadHardwareInfo(): Observable<any> {
    this.loadingHardwareInfo = true
    return this._ESLService.loadHardwareInfo(this.labelCode.value).pipe(
      tap((ESLLabels: IESLLabel[]) => {
        if (ESLLabels?.length) this.patchStatus(ESLLabels[0])
        this.loadingHardwareInfo = false
      })
    )
  }

  public setDOMSizeFactor(): void {
    const checkIfScaleDisplayIsRendered = setInterval(() => {
      const ESLLabelHTMLElement = document.getElementById(this.labelCode.value)
      if (ESLLabelHTMLElement) {
        clearInterval(checkIfScaleDisplayIsRendered)
        this.DOMSizeFactor = parseFloat(getComputedStyle(ESLLabelHTMLElement).width) / this.width
      }
    }, 50)
  }

  public patchStatus(ESLStatus: any): void {
    this.signal = ESLStatus?.label?.signal
    this.battery = ESLStatus?.label?.battery
    this.status = ESLStatus?.label?.update_status
    this.lastResponseTime = moment(ESLStatus?.label?.last_response_time)
    this.lastConnectionTime = moment(ESLStatus?.label?.gateway?.last_connection_date)
    this.type = ESLStatus?.label?.type
    this.gatewayCode = ESLStatus?.label?.gateway?.name
    this.displayHeight = ESLStatus?.properties?.display_height
    this.displayWidth = ESLStatus?.properties?.display_width
  }
}
