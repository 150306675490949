import { FormBuilder, FormControl } from '@angular/forms'
import { FoodopLibModule } from '../../foodop-lib.module'
import { IProcurementDataFile, IProcurementProduct } from '../../global.models'
import { ProcurementProduct } from './procurement-product.model'
import { SubsidiaryService } from '../subsidiary/subsidiary.service'
import { ProcurementService } from './procurement.service'
import { Observable, forkJoin, map, of, switchMap, tap } from 'rxjs'
import moment from 'moment'

export class ProcurementDataFile {
  file_id: string
  file_name: string
  source?: FormControl
  created?: moment.Moment
  updated?: moment.Moment
  user_id?: string
  subsidiary_id?: string
  organization_id?: string

  products: ProcurementProduct[]

  columns_in_use: string[]

  fb: FormBuilder
  procurementService: ProcurementService
  subsidiaryService: SubsidiaryService

  constructor(procurement_data_file?: IProcurementDataFile, public language?: string) {
    this.fb = FoodopLibModule.injector.get(FormBuilder)
    this.procurementService = FoodopLibModule.injector.get(ProcurementService)
    this.subsidiaryService = FoodopLibModule.injector.get(SubsidiaryService)

    this.file_id = procurement_data_file?.file_id || null
    this.file_name = procurement_data_file?.file_name || null
    this.source = new FormControl(procurement_data_file?.source || null)
    this.created = moment(moment.utc(procurement_data_file?.created).format()) || null
    this.updated = moment(moment.utc(procurement_data_file?.updated).format()) || null
    this.user_id = procurement_data_file?.user_id || null
    this.subsidiary_id = procurement_data_file?.subsidiary_id || null
    this.organization_id = procurement_data_file?.organization_id || null

    this.products = procurement_data_file?.products?.map((product) => new ProcurementProduct(product, null, language)) || []
  }

  save(language: string): Observable<ProcurementDataFile> {
    const params = {
      subsidiary_id: this.subsidiaryService.subsidiary.id
    }
    let products_to_upload: IProcurementProduct[] = JSON.parse(JSON.stringify(this.products.filter((product) => product.include).map((product) => product.as_dict)))

    let initial_file_chunk: IProcurementDataFile = this.as_dict(language)
    initial_file_chunk['products'] = products_to_upload.splice(0, 500)

    return this.procurementService.uploadDataFile(initial_file_chunk, params).pipe(
      tap((file) => {
        this.file_id = file.file_id
        this.file_name = file.file_name
      }),
      switchMap(() => {
        if (products_to_upload.length > 0) return forkJoin(this._save_chunks(products_to_upload, language, params))
        else return of([])
      }),
      map(() => this)
    )
  }

  _save_chunks(products_to_upload: IProcurementProduct[], language: string, params: object): Observable<ProcurementDataFile>[] {
    let chunk_observables = []
    while (products_to_upload.length > 0) {
      let file_chunk: IProcurementDataFile = this.as_dict(language)
      file_chunk['products'] = products_to_upload.splice(0, 500)
      chunk_observables.push(this.procurementService.uploadDataFile(file_chunk, params))
    }
    return chunk_observables
  }

  as_dict(language: string): IProcurementDataFile {
    return {
      file_id: this.file_id,
      file_name: this.file_name,
      products: this.products.filter((product) => product.include).map((product) => product.as_dict)
    }
  }
}
