import { Component, Input, OnInit, ViewContainerRef } from '@angular/core'
import { FormControl } from '@angular/forms'
import { ESLLabel, ESLService, Location, Organization, OverlayService, Subsidiary } from 'foodop-lib'
import { catchError, concatMap, exhaustMap, map, merge, mergeMap, Observable, of, scan, startWith, Subject, switchMap, takeWhile, tap } from 'rxjs'
import { ESLLabelComponent } from './esl-label/esl-label.component'

@Component({
  selector: 'esl-labels-overview',
  templateUrl: './esl-labels-overview.component.html'
})
export class ESLLabelsOverviewComponent implements OnInit {
  @Input() customerSelection: FormControl

  public ESLLabels$: Observable<ESLLabel[]>
  public ESLLabelSearchControl: FormControl = new FormControl('')
  public loadingESLLabels: boolean = false
  public moreESLLabelsAvailable: boolean = false

  private _ESLLabelsOffset: number = 0
  private _loadNextESLLabels$ = new Subject()

  constructor(private _overlayService: OverlayService, private ESLService: ESLService, private _viewContainerRef: ViewContainerRef) {}

  ngOnInit(): void {
    this.ESLLabels$ = this._loadESLLabels()
  }

  public get selectedLocationIds(): string[] {
    return this._selectedLocations.map((location) => location.id)
  }
  private get _selectedLocations(): Location[] {
    return this.customerSelection.value.filter((selectionOption: Location | Subsidiary | Organization) => selectionOption.constructor.name == 'Location') as Location[]
  }

  public openESLLabel(label?: ESLLabel): void {
    let onCloseView = (overlayRef) => {
      overlayRef.dispose()
    }

    this._overlayService.openComponentInPopUp(
      this._viewContainerRef,
      ESLLabelComponent,
      {
        ESLLabel: label || new ESLLabel({})
      },
      ['max-w-[80%]', 'max-h-[80%]', 'md:min-w-[500px]'],
      onCloseView,
      onCloseView
    )
  }

  public loadMoreESLLabels(): void {
    this._loadNextESLLabels$.next(true)
  }

  private _loadESLLabels(): Observable<ESLLabel[]> {
    const filterChanges$ = merge(this.ESLLabelSearchControl.valueChanges, this.customerSelection.valueChanges).pipe(
      startWith(''),
      tap(() => (this._ESLLabelsOffset = 0))
    )

    return filterChanges$.pipe(
      switchMap(() => {
        return this._loadNextESLLabels$.pipe(
          startWith(0),
          tap(() => (this.loadingESLLabels = true)),
          exhaustMap(() => {
            if (!this._selectedLocations.length && this.ESLLabelSearchControl.value.length <= 1) return of([])
            return this.ESLService.getLabels(this.ESLLabelSearchControl.value, this.selectedLocationIds, this._ESLLabelsOffset)
          }),
          takeWhile((ESLLabels) => {
            this.moreESLLabelsAvailable = ESLLabels.length == 10
            return ESLLabels.length == 10, true
          }),
          scan((allESLLabels: ESLLabel[], newESLLabels: ESLLabel[]) => allESLLabels.concat(newESLLabels)),
          tap(() => {
            this._ESLLabelsOffset += 10
            this.loadingESLLabels = false
          }),
          this._loadHardwareInfo()
        )
      })
    )
  }

  private _loadHardwareInfo = () => {
    return (source$: Observable<ESLLabel[]>) =>
      source$.pipe(
        mergeMap((allESLLabels) => {
          return of(allESLLabels).pipe(
            concatMap(() => {
              if (allESLLabels.length === 0) return of([])
              return this.ESLService.loadHardwareInfo(this.ESLLabelSearchControl.value, this.selectedLocationIds, this._ESLLabelsOffset - 10).pipe(
                tap((ESLLabels) => {
                  ESLLabels.forEach((ESLLabelStatus) => {
                    const updatedESLLabel = allESLLabels.find((label) => label.labelCode.value == ESLLabelStatus.label_code)
                    if (updatedESLLabel) updatedESLLabel.patchStatus(ESLLabelStatus)
                  })
                }),
                catchError(() => of([])),
                map(() => allESLLabels)
              )
            })
          )
        })
      )
  }
}
